import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import Bookmark from "./bookmark";

const Breadcrumb = (props) => {
  const breadcrumb = props;

  const getParent = () => {
    if (breadcrumb.parent != 'false') {
      if(breadcrumb.specialParent){
        return breadcrumb.specialParent.map((v,i)=>(
          <li className="breadcrumb-item" key={i}>
            <Link to={v.link}>{v.label}</Link>
          </li>
        ))
      }
      if (breadcrumb.parentLink) {
        return (
          <li className="breadcrumb-item">
            <Link to={breadcrumb.parentLink}>{breadcrumb.parent}</Link>
          </li>
        );
      }
      return <li className="breadcrumb-item">{breadcrumb.parent}</li>;
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-10">
              <div className="page-header-left">
                <h3>{breadcrumb.title}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <Home />
                    </Link>
                  </li>
                  {getParent()}
                  <li className="breadcrumb-item active">{breadcrumb.title}</li>
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            {/* <Bookmark /> */}
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
