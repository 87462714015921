import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

const EditMasterResource = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [masterResource, setmasterResource] = useState(null);

  const getMasterResource = (id) => {
    var url = new URL(`${API_BASE}masterResources/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setmasterResource(res.data);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  useEffect(() => {
    getMasterResource(match.params.id);
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      if (data.ResourceCategories == "select") {
        delete data["ResourceCategories"];
      }
      data = { ...data, _id: match.params.id };
      setEditing(true);
      fetch(`${API_BASE}masterResources`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setError(res.msg);
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/master-resources`);
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  if (loading || !masterResource) {
    return (
      <Fragment>
        <Breadcrumb
          parent="Master Resource"
          parentLink={`${process.env.PUBLIC_URL}/master-resources`}
          title="Edit Master Resource"
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent="Master Resource"
          parentLink={`${process.env.PUBLIC_URL}/master-resources`}
          title="Edit Master Resource"
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/master-resources`}>
                Back to Master Resources
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Breadcrumb
        parent="Master Resource"
        parentLink={`${process.env.PUBLIC_URL}/master-resources`}
        title="Edit Master Resource"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        defaultValue={masterResource.Name}
                        placeholder="Name"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Name && "Name is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Code</label>
                      <input
                        className="form-control"
                        name="Code"
                        type="text"
                        defaultValue={masterResource.Code}
                        placeholder="Code"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Code && "Code is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Description</label>
                      <textarea
                        className="form-control"
                        name="Description"
                        placeholder="Description"
                        defaultValue={masterResource.Description}
                        ref={register({ required: true })}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Description && "Description is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Meta</label>
                      <textarea
                        className="form-control"
                        name="Meta"
                        placeholder="Meta"
                        defaultValue={masterResource.Meta}
                        ref={register({ required: true })}
                        rows="10"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Meta && "Meta is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  <br />
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        Would you like to update all the referenced library
                        resources
                      </label>
                      <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                        <div className="radio radio-primary">
                          <input
                            id="radioinline1"
                            type="radio"
                            ref={register({ required: true })}
                            name="Sync"
                            value="YES"
                          />
                          <label className="mb-0" htmlFor="radioinline1">
                            Yes
                          </label>
                        </div>
                        <div className="radio radio-primary">
                          <input
                            id="radioinline2"
                            type="radio"
                            ref={register({ required: true })}
                            name="Sync"
                            value="NO"
                            defaultChecked
                          />
                          <label className="mb-0" htmlFor="radioinline2">
                            No
                          </label>
                        </div>
                      </div>
                      <span style={{ color: "red" }}>
                        {errors.Meta && "Meta is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={editing}
                    type="submit"
                  >
                    {editing ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditMasterResource;
