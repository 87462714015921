import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { FILE_BASE } from "../../constant/api";
import { UncontrolledTooltip } from "reactstrap";
import { Button } from "reactstrap";
var moment = require("moment");

class ViewLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      library: null,
      errMsg: null,
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
    };
  }

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            library: res.data,
          });
        } else {
          this.setState({ loading: false, errMsg: res.msg });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLibrary(this.props.match.params.id);
  }

  keyFormatter = (key) => {
    const parts = key.split("-");
    return parts[0]+"-****-****-" + parts[parts.length - 1];
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "View Library"
                : "Info"
            }
            parentLink={`${process.env.PUBLIC_URL}/libraries/${this.props.location.search}`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (this.state.errMsg) {
      return (
        <Fragment>
          <Breadcrumb
            title={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "View Library"
                : "Info"
            }
            parentLink={`${process.env.PUBLIC_URL}/libraries/${this.props.location.search}`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>{this.state.errMsg}</p>
              <hr />
              <p className="mb-0">
                <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                  Back to Libraries
                </Link>
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Breadcrumb
          title={
            this.state.loggedInUser.Role.Name == "Master Admin" ||
            this.state.loggedInUser.Role.Name == "Organization Admin"
              ? "View Library"
              : "Info"
          }
          parentLink={`${process.env.PUBLIC_URL}/libraries/${this.props.location.search}`}
          parent={
            this.state.loggedInUser.Role.Name == "Master Admin" ||
            this.state.loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "false"
          }
        />
        <Button
          style={{ float: "right", marginTop: -60, marginRight: 16 }}
          color="primary"
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/library/edit/${this.props.match.params.id}`
            )
          }
        >
          <i className="fa fa-pencil"></i> EDIT
        </Button>
        <div className="container-fluid">
          <div className="card p-4">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    <div className="blog-details">
                      <h4>{this.state.library.UniversityName}</h4>
                    </div>
                  </div>
                  <section className="comment-box" style={{ paddingTop: 20 }}>
                  <hr />
                  <div className="row">
                      <div className="col-md-2">
                        <h5>Library URL</h5>
                      </div>
                      <div className="col-md-4">
                        <p>
                          {this.state.library.RootURL
                            ? this.state.library.RootURL
                            : ""}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Organization</h5>
                      </div>
                      <div className="col-md-4">
                        <p>
                          {this.state.library.Organization
                            ? this.state.library.Organization.Name
                            : ""}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Contact Name</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.library.Name}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Email</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.library.Email}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Phone</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.library.Phone}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Address</h5>
                      </div>
                      <div className="col-md-12">
                        <p style={{ color: "#1ea6ec" }}>
                          {this.state.library.Address}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>About Us</h5>
                      </div>
                      <div
                        className="col-md-12"
                        dangerouslySetInnerHTML={{
                          __html: this.state.library.About,
                        }}
                      ></div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Disclaimer</h5>
                      </div>
                      <div
                        className="col-md-12"
                        dangerouslySetInnerHTML={{
                          __html: this.state.library.Disclaimer,
                        }}
                      ></div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>User Manual</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.UserManual ? (
                          <div>
                            {/* <iframe
                              src={`${this.state.library.UserManual}`}
                            ></iframe> */}
                            <a
                              href={`${this.state.library.UserManual}`}
                              target="_blank"
                            >
                              <code>Open in new tab</code>
                            </a>
                          </div>
                        ) : (
                          <code>User Manual not uploded</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>Main Logo</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.MainLogo ? (
                          <div>
                            <img
                              src={`${this.state.library.MainLogo}`}
                              style={{ width: "200px" }}
                            />
                            <a
                              href={`${this.state.library.MainLogo}`}
                              target="_blank"
                            >
                              <code>Open in new tab</code>
                            </a>
                          </div>
                        ) : (
                          <code>Main Logo not uploded</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>Footer Logo</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.FooterLogo ? (
                          <div>
                            <img
                              src={`${this.state.library.FooterLogo}`}
                              style={{ width: "200px" }}
                            />
                            <a
                              href={`${this.state.library.FooterLogo}`}
                              target="_blank"
                            >
                              <code>Open in new tab</code>
                            </a>
                          </div>
                        ) : (
                          <code>Footer Logo not uploded</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>Favicon</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.Favicon ? (
                          <div>
                            <img
                              src={`${this.state.library.Favicon}`}
                              style={{ width: "200px" }}
                            />
                            <a
                              href={`${this.state.library.Favicon}`}
                              target="_blank"
                            >
                              <code>Open in new tab</code>
                            </a>
                          </div>
                        ) : (
                          <code>Favicon not uploded</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>Banner</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.Banner ? (
                          this.state.library.Banner.length > 0 ? (
                            <div className="row">
                              {this.state.library.Banner.map((v, i) => {
                                return (
                                  <div className="col-md-6" key={i}>
                                    <img
                                      src={`${v}`}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <code>Banner not uploded</code>
                          )
                        ) : (
                          <code>Banner not uploded</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h5>License</h5>
                      </div>
                      <div className="col-md-12">
                        {this.state.library.License ? (
                          <Fragment>
                            <ul style={{ listStyle: "none" }}>
                              <li>
                                <b>Key: </b>{" "}
                                <code>
                                  {this.keyFormatter(
                                    this.state.library.License.Key
                                  )}
                                </code>
                              </li>
                              <li>
                                <b>Start: </b>{" "}
                                <code>
                                  {moment(
                                    this.state.library.License.Start
                                  ).format("D MMM YYYY")}
                                </code>
                              </li>
                              <li>
                                <b>End: </b>{" "}
                                <code>
                                  {moment(
                                    this.state.library.License.End
                                  ).format("D MMM YYYY")}
                                </code>
                              </li>
                            </ul>
                          </Fragment>
                        ) : (
                          <code>No active license</code>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="blog-box blog-details">
                      <div className="blog-details">
                        <ul className="blog-social">
                          <li
                            className="digits"
                            style={{
                              border: "none",
                              color: "#007bff",
                              paddingRight: 0,
                              cursor: "pointer",
                            }}
                            id="createdOn"
                          >
                            <i className="fa fa-clock-o"></i>
                            {moment(this.state.library.CreatedOn).format(
                              "D MMM YYYY"
                            )}
                          </li>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdOn"
                          >
                            Created On
                          </UncontrolledTooltip>
                          <li
                            id="createdBy"
                            className="digits"
                            style={{
                              marginRight: 40,
                              color: "#007bff",
                              cursor: "pointer",
                            }}
                          >
                            <i className="icofont icofont-user"></i>
                            {this.state.library.CreatedBy.UserName}
                          </li>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdBy"
                          >
                            Created By
                          </UncontrolledTooltip>
                          {this.state.library.UpdatedOn && (
                            <Fragment>
                              <li
                                id="updatedOn"
                                className="digits"
                                style={{
                                  borderLeft: "1px solid #777777",
                                  color: "#ff9f40",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-clock-o"></i>
                                {moment(this.state.library.UpdatedOn).format(
                                  "D MMM YYYY"
                                )}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedOn"
                              >
                                Updated On
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                          {this.state.library.UpdatedBy && (
                            <Fragment>
                              <li
                                id="updatedBy"
                                className="digits"
                                style={{ color: "#ff9f40", cursor: "pointer" }}
                              >
                                <i className="icofont icofont-user"></i>
                                {this.state.library.UpdatedBy.UserName}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedBy"
                              >
                                Updated By
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          {(this.state.loggedInUser.Role.Name == "Master Admin" ||
            this.state.loggedInUser.Role.Name == "Organization Admin") && (
            <section className="comment-box">
              <Link
                className="mb-5"
                to={`${process.env.PUBLIC_URL}/libraries/${this.props.location.search}`}
              >
                <i className="fa fa-arrow-circle-left"></i> Back to Libraries
              </Link>
            </section>
          )}
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ViewLibrary;
