import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../constant/api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

class LicenseComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      licenses: [],
      loggedInUser: {
        Role: {
          IsAdmin: false,
        },
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
      filter: {
        key: "",
        library: "",
        loading: false,
      },
    };
  }

  getLicenses = () => {
    var url = new URL(`${API_BASE}license`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            licenses: res.data,
          });
        }
      })
      .catch((err) => {
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLicenses();
  }

  editHandler = (license) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/license/edit/${license._id}`
    );
  };

  viewHandler = (license) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/license/view/${license._id}`
    );
  };

  deleteHandler = (license) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteLicense(license._id)}
        >
          You will not be able to recover this License!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteLicense = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}license/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getLicenses();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    this.setState({ filter });
  };

  filterHandler = () => {
    var url = new URL(`${API_BASE}license`),
      params = {};
    if (this.state.filter.key) params["key"] = this.state.filter.key;
    if (this.state.filter.library)
      params["library"] = this.state.filter.library;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.filterInputHandler("loading", true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        if (res.success) {
          this.setState({
            loading: false,
            licenses: res.data,
          });
        }
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb title="License" parent="false" />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb title="License" parent="false" />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Button
                    style={{ float: "right", marginTop: -90 }}
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/license/create`
                      )
                    }
                  >
                    <i className="fa fa-plus"></i> ADD
                  </Button>
                  <div className="clearfix"></div>
                  <div className="row mt-2 pl-5 pr-5">
                    <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by Key"
                          onChange={(e) =>
                            this.filterInputHandler("key", e.target.value)
                          }
                          value={this.state.filter.key}
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by Library"
                          onChange={(e) =>
                            this.filterInputHandler("library", e.target.value)
                          }
                          value={this.state.filter.library}
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Button
                        style={{ float: "right" }}
                        color="secondary"
                        disabled={this.state.filter.loading}
                        onClick={this.filterHandler}
                      >
                        {this.state.filter.loading ? (
                          <i className="fa fa-cog fa-spin"></i>
                        ) : (
                          <i className="fa fa-filter"></i>
                        )}{" "}
                        FILTER
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                <p>
                    <strong>
                      Total records: {this.state.licenses.length}
                    </strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.licenses}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LicenseComponent;
