import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success("Successfully Deleted !");
  };

  renderEditable = (cellInfo) => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const data = [...this.state.myData];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ myData: data });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.myData[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  editRec = (index) => {
    const data = [...this.state.myData];
    const { _id } = data[index];
    this.props.editActionLoad(_id);
  };

  render() {
    const {
      pageSize,
      myClass,
      pagination,
      viewHandler,
      editHandler,
      deleteHandler,
    } = this.props;
    const { myData } = this.state;
    const columnNames = ["Name", "DisplayName"];
    const columns = [];
    for (var key in myData[0]) {
      let editable = this.renderEditable;
      editable = null;
      if (columnNames.includes(key)) {
        let header = this.Capitalize(key.toString());
        if (key == "_id") header = "#ID";
        let accessor = key;
        columns[columnNames.indexOf(key)] = {
          Header: (
            <div style={{ textAlign: "left" }}>
              <b>{header}</b>
            </div>
          ),
          accessor: accessor,
          Cell: editable,
          style: {
            textAlign: "left",
          },
        };
      }
    }

    columns.push({
      Header: <b>Action</b>,
      id: "delete",
      // eslint-disable-next-line
      accessor: (str) => "delete",
      Cell: (row) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => viewHandler(row.original)}
            title="View"
          >
            <i
              className="fa fa-eye"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "#4466f2",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => editHandler(row.original)}
            title="Edit"
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            ></i>
          </span>

          <span
            onClick={() => deleteHandler(row.original)}
            style={{ cursor: "pointer" }}
            title="Delete"
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
        </div>
      ),
      // eslint-disable-next-line
      style: {
        textAlign: "center",
      },
      sortable: false,
    });

    return (
      <Fragment>
        <ReactTable
          data={myData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Datatable;
