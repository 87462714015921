import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import CKEditors from "react-ckeditor-component";
import { FILE_BASE } from "../../constant/api";

const EditLibraries = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [library, setLibrary] = useState(null);
  const [disclaimer, setDisclaimer] = useState("");
  const [about, setAbout] = useState("");
  const [UserManual, setUserManual] = useState(null);
  const [MainLogo, setMainLogo] = useState(null);
  const [FooterLogo, setFooterLogo] = useState(null);
  const [Favicon, setFavicon] = useState(null);
  const [Banner, setBanner] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });

  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setLibrary(res.data);
          setDisclaimer(res.data.Disclaimer);
          setAbout(res.data.About);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      });
  };
  const getOrganizations = () => {
    var url = new URL(`${API_BASE}organizations`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setOrganizations(data);
        } else {
        }
      });
  };

  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    if (
      JSON.parse(localStorage.getItem("user")).Role &&
      JSON.parse(localStorage.getItem("user")).Role.Name == "Organization Admin"
    ) {
      setOrganizations([
        {
          value: JSON.parse(localStorage.getItem("user")).Organization._id,
          label: JSON.parse(localStorage.getItem("user")).Organization.Name,
        },
      ]);
    } else if (
      JSON.parse(localStorage.getItem("user")).Role &&
      JSON.parse(localStorage.getItem("user")).Role.Name == "Library Admin"
    ) {
      setOrganizations([
        {
          value: JSON.parse(localStorage.getItem("user")).Library.Organization
            ._id,
          label: JSON.parse(localStorage.getItem("user")).Library.Organization
            .Name,
        },
      ]);
    } else {
      getOrganizations();
    }
    getLibrary(match.params.id);
    return () => {};
  }, []);

  const disclaimerChange = (evt) => {
    var newContent = evt.editor.getData();
    setDisclaimer(newContent);
  };
  const aboutChange = (evt) => {
    var newContent = evt.editor.getData();
    setAbout(newContent);
  };

  const onSubmit = (data) => {
    if (data !== "") {
      data = {
        ...data,
        _id: match.params.id,
        Disclaimer: disclaimer,
        About: about,
      };
      setEditing(true);
      var formData = new FormData();
      for (var d in data) {
        formData.append(d, data[d]);
      }
      if (UserManual)
        formData.append("UserManual", UserManual, UserManual.name);
      if (MainLogo) formData.append("MainLogo", MainLogo, MainLogo.name);
      if (FooterLogo)
        formData.append("FooterLogo", FooterLogo, FooterLogo.name);
      if (Favicon) formData.append("Favicon", Favicon, Favicon.name);
      if (Banner) formData.append("Banner", Banner, Banner.name);
      fetch(`${API_BASE}libraries`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setEditing(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            if (
              loggedInUser.Role.Name == "Master Admin" ||
              loggedInUser.Role.Name == "Organization Admin"
            ) {
              history.push(`${process.env.PUBLIC_URL}/libraries`);
            } else {
              history.push(
                `${process.env.PUBLIC_URL}/library/view/${match.params.id}`
              );
            }
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "Info"
          }
          parentLink={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? `${process.env.PUBLIC_URL}/libraries`
              : `${process.env.PUBLIC_URL}/library/view/${match.params.id}`
          }
          title={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Edit Library"
              : "Edit Info"
          }
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                Back to Libraries
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !organizations || !library) {
    return (
      <Fragment>
        <Breadcrumb
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "Info"
          }
          parentLink={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? `${process.env.PUBLIC_URL}/libraries`
              : `${process.env.PUBLIC_URL}/library/view/${match.params.id}`
          }
          title={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Edit Library"
              : "Edit Info"
          }
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Breadcrumb
        parent={
          loggedInUser.Role.Name == "Master Admin" ||
          loggedInUser.Role.Name == "Organization Admin"
            ? "Libraries"
            : "Info"
        }
        parentLink={
          loggedInUser.Role.Name == "Master Admin" ||
          loggedInUser.Role.Name == "Organization Admin"
            ? `${process.env.PUBLIC_URL}/libraries`
            : `${process.env.PUBLIC_URL}/library/view/${match.params.id}`
        }
        title={
          loggedInUser.Role.Name == "Master Admin" ||
          loggedInUser.Role.Name == "Organization Admin"
            ? "Edit Library"
            : "Edit Info"
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Library Name</label>
                      <input
                        className="form-control"
                        name="UniversityName"
                        type="text"
                        placeholder="University Name"
                        ref={register({ required: true })}
                        defaultValue={library.UniversityName}
                      />
                      <span>
                        {errors.UniversityName && "University Name is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Library URL</label>
                      <input
                        className="form-control"
                        name="RootURL"
                        type="text"
                        placeholder="Library URL"
                        ref={register({ required: false })}
                        defaultValue={library.RootURL}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Organization</label>
                      {organizations && (
                        <select
                          className="form-control"
                          name="Organization"
                          ref={register({ required: true })}
                          defaultValue={
                            loggedInUser.Organization
                              ? loggedInUser.Organization._id
                              : library.Organization._id
                          }
                          readOnly={
                            loggedInUser &&
                            (loggedInUser.Organization || loggedInUser.Library)
                          }
                        >
                          <option value="disable" disabled>
                            Select
                          </option>
                          {organizations.map((v, k) => (
                            <option key={k} value={v.value}>
                              {v.label}
                            </option>
                          ))}
                        </select>
                      )}
                      <span style={{ color: "red" }}>
                        {errors.Organization && "Organization is required"}
                      </span>
                    </div>
                  </div>
                  <div className="form-row mb-5">
                    <div className="col-md-12 mb-3">
                      <h6>User Manual</h6>
                      <div className="row">
                        <div className="col-md-8">
                          {library.UserManual ? (
                            <div>
                              {/* <iframe
                                src={`${library.UserManual}`}
                              ></iframe> */}
                              <a href={`${library.UserManual}`} target="_blank">
                                <code>Open in new tab</code>
                              </a>
                            </div>
                          ) : (
                            <code>User Manual not uploded</code>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div>
                            <input
                              type="file"
                              ref={register({})}
                              name="UserManual"
                              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onChange={(e) => setUserManual(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">About Us</label>
                      <CKEditors
                        activeclassName="p10"
                        content={about}
                        events={{
                          change: aboutChange.bind(this),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Disclaimer</label>
                      <CKEditors
                        activeclassName="p10"
                        content={disclaimer}
                        events={{
                          change: disclaimerChange.bind(this),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <h6>Contact Information</h6>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Contact Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        placeholder="Name"
                        ref={register({})}
                        defaultValue={library.Name}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Email</label>
                      <input
                        className="form-control"
                        name="Email"
                        type="email"
                        placeholder="Email"
                        ref={register({})}
                        defaultValue={library.Email}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Phone</label>
                      <input
                        className="form-control"
                        name="Phone"
                        type="text"
                        placeholder="Phone"
                        ref={register({})}
                        defaultValue={library.Phone}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Address</label>
                      <textarea
                        className="form-control"
                        name="Address"
                        placeholder="Address"
                        ref={register({})}
                        defaultValue={library.Address}
                      ></textarea>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <h6>Layout Option</h6>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Color</label>
                      <input
                        className="form-control"
                        name="Color"
                        type="text"
                        placeholder="Color"
                        ref={register({})}
                        defaultValue={library.Color}
                      />
                    </div>
                  </div>
                  <div className="form-row mb-5">
                    <div className="col-md-12 mb-3">
                      <h6>Banner</h6>
                      <div className="row">
                        <div className="col-md-8">
                          {library.Banner ? (
                            library.Banner.length > 0 ? (
                              <div className="row">
                                {library.Banner.map((v, i) => {
                                  return (
                                    <div className="col-md-6" key={i}>
                                      <img
                                        src={`${v}`}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <code>Banner not uploded</code>
                            )
                          ) : (
                            <code>Banner not uploded</code>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div>
                            <input
                              type="file"
                              ref={register({})}
                              name="Banner"
                              accept="image/jpeg,image/png"
                              onChange={(e) => setBanner(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <h6 style={styles.header}>Logos</h6>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Main Logo</h6>
                        <div className="row">
                          <div className="col-md-8">
                            {library.MainLogo ? (
                              <div>
                                <img
                                  src={`${library.MainLogo}`}
                                  style={{ width: 200 }}
                                />
                                <a href={`${library.MainLogo}`} target="_blank">
                                  <code>Open in new tab</code>
                                </a>
                              </div>
                            ) : (
                              <code>Main Logo not uploded</code>
                            )}
                          </div>
                          <div className="col-md-4">
                            <div>
                              <input
                                type="file"
                                ref={register({})}
                                name="MainLogo"
                                accept="image/jpeg,image/png"
                                onChange={(e) => setMainLogo(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <h6>Footer Logo</h6>
                        <div className="row">
                          <div className="col-md-8">
                            {library.FooterLogo ? (
                              <div>
                                <img
                                  src={`${library.FooterLogo}`}
                                  style={{ width: 200 }}
                                />
                                <a
                                  href={`${library.FooterLogo}`}
                                  target="_blank"
                                >
                                  <code>Open in new tab</code>
                                </a>
                              </div>
                            ) : (
                              <code>Footer Logo not uploded</code>
                            )}
                          </div>
                          <div className="col-md-4">
                            <div>
                              <input
                                type="file"
                                ref={register({})}
                                name="FooterLogo"
                                accept="image/jpeg,image/png"
                                onChange={(e) =>
                                  setFooterLogo(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <h6>Favicon</h6>
                        <div className="row">
                          <div className="col-md-8">
                            {library.Favicon ? (
                              <div>
                                <img
                                  src={`${library.Favicon}`}
                                  style={{ width: 200 }}
                                />
                                <a href={`${library.Favicon}`} target="_blank">
                                  <code>Open in new tab</code>
                                </a>
                              </div>
                            ) : (
                              <code>Favicon not uploded</code>
                            )}
                          </div>
                          <div className="col-md-4">
                            <div>
                              <input
                                type="file"
                                ref={register({})}
                                name="UserManual"
                                accept="image/jpeg,image/png,image/x-icon,image/vnd.microsoft.icon"
                                onChange={(e) => setFavicon(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={editing}
                    type="submit"
                  >
                    {editing ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  header: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: 600,
  },
};
export default EditLibraries;
