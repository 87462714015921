import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { API_BASE } from "../../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

const AddResource = ({ match }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });
  const history = useHistory();
  const [resourceCategories, setResourceCategories] = useState([]);
  const [masterResources, setMasterResource] = useState([]);
  const [rawMasterResources, setRawMasterResource] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedMasterResources, setSelectedMasterResources] = useState(null);
  const [library, setLibrary] = useState({
    UniversityName: "Fetching...",
  });
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(match.params.libid);
    getMasterResources();
    getResourceCategories();
    return () => {};
  }, []);

  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLibrary(res.data);
        } else {
          setLibrary(404);
        }
      });
  };

  const getMasterResources = () => {
    var url = new URL(`${API_BASE}masterResources?pagination=off`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var raw = {};
          for (var mr in res.docs) {
            raw[res.docs[mr]._id] = res.docs[mr];
          }
          setRawMasterResource(raw);
          var data = res.docs.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setMasterResource([...data]);
        } else {
        }
      });
  };

  const getResourceCategories = () => {
    fetch(`${API_BASE}resourceCategories?library_id=${match.params.libid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setResourceCategories(res.data);
        }
      });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      data = { ...data, Library: match.params.libid };
      if (data.ResourceCategories == "select") {
        delete data["ResourceCategories"];
      }
      if (selectedMasterResources) {
        data = { ...data, MasterResource: selectedMasterResources };
      }
      setLoading(true);
      fetch(`${API_BASE}resources`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setError(res.msg);
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getParentBreadcrumb = () => {
    if (
      loggedInUser.Role.Name == "Master Admin" ||
      loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${match.params.libid}`,
        },
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`,
        },
      ];
    } else {
      return [
        {
          label: "Resources",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resources`,
        },
      ];
    }
  };

  if (library == 404) {
    return (
      <Fragment>
        <Breadcrumb
          title="Add Resource"
          parentLink={`${process.env.PUBLIC_URL}/libraries`}
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "false"
          }
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>Library not found!</p>
            <hr />
            <p className="mb-0">
              {(loggedInUser.Role.Name == "Master Admin" ||
                loggedInUser.Role.Name == "Organization Admin") && (
                <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                  Back to Libraries
                </Link>
              )}
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Breadcrumb
        title={`Add Resource`}
        specialParent={getParentBreadcrumb()}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Copy from master resource
                    </label>
                    <Select
                      isClearable
                      options={masterResources}
                      onChange={(selectedValue) => {
                        setSelectedMasterResources(
                          selectedValue ? selectedValue.value : null
                        );
                        if (selectedValue) {
                          setDefaultValues(
                            rawMasterResources[selectedValue.value]
                          );
                        } else {
                          setDefaultValues({});
                        }
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        placeholder="Name"
                        ref={register({
                          required: selectedMasterResources === null,
                        })}
                        defaultValue={defaultValues.Name}
                      />
                      <span>{errors.Name && "Name is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Code</label>
                      <input
                        className="form-control"
                        name="Code"
                        type="text"
                        placeholder="Code"
                        ref={register({
                          required: selectedMasterResources === null,
                        })}
                        defaultValue={defaultValues.Code}
                      />
                      <span>{errors.Code && "Code is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Category</label>
                      <select
                        className="form-control digits"
                        name="ResourceCategories"
                        ref={register({})}
                        defaultValue="select"
                      >
                        <option value="select">Select</option>
                        {resourceCategories.map((v, i) => {
                          return (
                            <option value={v._id} key={i}>
                              {v.Name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Description</label>
                      <textarea
                        className="form-control"
                        name="Description"
                        placeholder="Description"
                        ref={register({
                          required: selectedMasterResources === null,
                        })}
                        defaultValue={defaultValues.Description}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Description && "Description is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Meta</label>
                      <textarea
                        className="form-control"
                        name="Meta"
                        placeholder="Meta"
                        ref={register({
                          required: selectedMasterResources === null,
                        })}
                        defaultValue={defaultValues.Meta}
                        rows="10"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Meta && "Meta is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Create"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddResource;
