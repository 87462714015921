import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import { API_BASE } from "../../constant/api";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUser = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState({});
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [selectedLibrary, setSelectedLibrary] = useState({});
  const [libraries, setLibraries] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [user, setUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const getLibraries = () => {
    var url = new URL(`${API_BASE}libraries?pagination=off`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.docs.map((v) => {
            return {
              value: v._id,
              label: `${v.UniversityName} ${
                v.Organization
                  ? v.Organization.Name
                    ? `(${v.Organization.Name})`
                    : ``
                  : ``
              }`,
            };
          });
          setLibraries(data);
        } else {
        }
      });
  };
  const getOrganizations = () => {
    var url = new URL(`${API_BASE}organizations`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setOrganizations(data);
        } else {
        }
      });
  };
  const getRoles = () => {
    var url = new URL(`${API_BASE}roles`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = {};
          for (var role in res.data) {
            switch (JSON.parse(localStorage.getItem("user")).Role.Name) {
              case "Organization Admin":
                if (res.data[role].Name == "Library Admin")
                  data[res.data[role]._id] = res.data[role];
                break;

              default:
                data[res.data[role]._id] = res.data[role];
                break;
            }
          }
          setRoles(data);
        } else {
        }
      });
  };
  const getUser = (id) => {
    var url = new URL(`${API_BASE}users/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setUser(res.data);
          setRole(res.data.Role.Name);
          if (res.data.Library) {
            if (res.data.Library._id) {
              setSelectedLibrary({
                value: res.data.Library._id,
                label: `${res.data.Library.UniversityName} ${
                  res.data.Library.Organization
                    ? res.data.Library.Organization.Name
                      ? `(${res.data.Library.Organization.Name})`
                      : ``
                    : ``
                }`,
              });
            }
          }
          if (res.data.Organization) {
            if (res.data.Organization._id) {
              setSelectedOrganization({
                value: res.data.Organization._id,
                label: res.data.Organization.Name,
              });
            }
          }
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    if (
      JSON.parse(localStorage.getItem("user")).Role.Name == "Organization Admin"
    ) {
      setRole('Library Admin')
    }
    getRoles();
    getLibraries();
    getOrganizations();
    getUser(match.params.id);
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setEditing(true);
      data = { ...data, _id: match.params.id };
      switch (role) {
        case "Organization Admin":
          data = { ...data, Organization: selectedOrganization.value };
          break;
        case "Library Admin":
          data = { ...data, Library: selectedLibrary.value };
          break;

        default:
          break;
      }
      fetch(`${API_BASE}users`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setError(res.msg);
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/users`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getBreadcrumbTitle = () => {
    if (loggedInUser) {
      switch (loggedInUser.Role.Name) {
        case "Organization Admin":
          return "Library Admin";

        default:
          return "User";
      }
    }
    return "";
  };

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent={`${getBreadcrumbTitle()}s`}
          parentLink={`${process.env.PUBLIC_URL}/users`}
          title={`Edit ${getBreadcrumbTitle()}`}
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/users`}>Back to Users</Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !libraries || !organizations || !user) {
    return (
      <Fragment>
        <Breadcrumb
          parent={`${getBreadcrumbTitle()}s`}
          parentLink={`${process.env.PUBLIC_URL}/users`}
          title={`Edit ${getBreadcrumbTitle()}`}
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb
        parent={`${getBreadcrumbTitle()}s`}
        parentLink={`${process.env.PUBLIC_URL}/users`}
        title={`Edit ${getBreadcrumbTitle()}`}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <label htmlFor="validationCustom01">User Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="UserName"
                      placeholder="User Name"
                      defaultValue={user.UserName}
                      ref={register({ required: true })}
                    />
                    <span>{errors.UserName && "User Name is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Display Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Display Name"
                      name="DisplayName"
                      defaultValue={user.DisplayName}
                      ref={register({ required: true })}
                    />
                    <span>
                      {errors.DisplayName && "Display Name is required"}
                    </span>
                  </div>

                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="Email"
                      defaultValue={user.Email}
                      ref={register({ required: true })}
                    />
                    <span>{errors.Email && "Email is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Password
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Only, if you want to change"
                      name="Password"
                      ref={register({})}
                    />
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Role
                    </label>
                    {Object.values(roles).length > 0 && (
                      <select
                        className="form-control"
                        onChange={(e) => setRole(roles[e.target.value].Name)}
                        name="Role"
                        defaultValue={user.Role._id}
                        ref={register({ required: true })}
                      >
                        {Object.values(roles).map((v, k) => (
                          <option key={v._id} value={v._id}>
                            {v.Name}
                          </option>
                        ))}
                      </select>
                    )}
                    <span>{errors.Role && "Role is required"}</span>
                  </div>
                  {role == "Library Admin" && (
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="recipient-name"
                      >
                        Library
                      </label>
                      <Select
                        options={libraries}
                        onChange={(selectedLibrary) =>
                          setSelectedLibrary(selectedLibrary)
                        }
                        value={selectedLibrary}
                      />
                    </div>
                  )}
                  {role == "Organization Admin" && (
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="recipient-name"
                      >
                        Organization
                      </label>
                      <Select
                        options={organizations}
                        onChange={(selectedOrganization) =>
                          setSelectedOrganization(selectedOrganization)
                        }
                        value={selectedOrganization}
                      />
                    </div>
                  )}

                  {error && (
                    <div class="dark alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  )}

                  <div style={{ float: "right" }}>
                    <Button
                      color="primary"
                      style={{ marginLeft: "10px", marginTop: 10 }}
                    >
                      {editing ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditUser;
