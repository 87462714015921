import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import useForm from "react-hook-form";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
const EditOrganization = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [editing, setEditing] = useState(false);
  const [selectedLibrary, setSelectedLibrary] = useState({});
  const [libraries, setLibraries] = useState([]);
  const getLibraries = (org) => {
    var url = new URL(`${API_BASE}libraries?pagination=off&organizationid=${org._id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          if(org){
            let orgLib = res.data.docs.filter((lib)=> lib._id == org.OrgLibraryId);
            if(orgLib && orgLib.length > 0){
              setSelectedLibrary({
                value: orgLib[0]._id,
                label: orgLib[0].UniversityName
              })
            }
          }
          var data = res.data.docs.map((v) => {
            return {
              value: v._id,
              label: `${v.UniversityName} ${
                v.Organization
                  ? v.Organization.Name
                    ? `(${v.Organization.Name})`
                    : ``
                  : ``
              }`,
            };
          });
          setLibraries(data);
        } else {
        }
      });
  };
  const getOrganization = (id) => {
    var url = new URL(`${API_BASE}organizations/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setOrganization(res.data);
          getLibraries(res.data);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      });
  };
  useEffect(() => {
    getOrganization(match.params.id);
    
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setEditing(true);
      data = { ...data, _id: match.params.id, OrgLibraryId: selectedLibrary.value };
      
      fetch(`${API_BASE}organizations`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setEditing(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/organizations`);
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent="Organizations"
          parentLink={`${process.env.PUBLIC_URL}/organizations`}
          title="Edit Organization"
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/organizations`}>
                Back to Organizations
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !organization) {
    return (
      <Fragment>
        <Breadcrumb
          parent="Organizations"
          parentLink={`${process.env.PUBLIC_URL}/organizations`}
          title="Edit Organization"
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Breadcrumb
        parent="Organizations"
        parentLink={`${process.env.PUBLIC_URL}/organizations`}
        title="Edit Organization"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      defaultValue={organization.Name}
                      name="Name"
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className="form-group">
                  <label className="col-form-label" htmlFor="OrgLibraryId">
                      Library
                    </label>
                    
                      <Select
                        name="OrgLibraryId"
                        options={libraries}
                        onChange={(selectedLibrary) =>
                          setSelectedLibrary(selectedLibrary)
                        }
                        value={selectedLibrary}
                      />
                      {/* <span style={{ color: "red" }}>
                        {validationError.Library && "PLease select a library"}
                      </span> */}
                    </div>
                  {error && (
                    <div class="dark alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  )}
                  <div style={{ float: "right" }}>
                    <Button color="primary" style={{ marginLeft: "10px" }}>
                    {editing ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditOrganization;
