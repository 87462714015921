import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import data from "../../data/group";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../constant/api";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

class OrganizationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      organizations: [],
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getOrganizations = (page = null, limit = 1000) => {
    var url = new URL(`${API_BASE}organizations`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            organizations: res.data,
          });
        }
      })
      .catch((err) => {
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  componentDidMount() {
    this.getOrganizations();
  }

  editHandler = (organization) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/organization/edit/${organization._id}`
    );
  };

  viewHandler = (organization) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/organization/view/${organization._id}`
    );
  };

  deleteHandler = (organization) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteOrganization(organization._id)}
        >
          You will not be able to recover this Organization!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteOrganization = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}organizations/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getOrganizations();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb title="Organizations" parent="false" />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb title="Organizations" parent="false" />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Button
                style={{ float: "right", marginTop: -60 }}
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/organization/create`
                  )
                }
              >
                <i className="fa fa-plus"></i> ADD
              </Button>
              <div className="card">
                <div className="card-body datatable-react">
                  <p>
                    <strong>Total records: {this.state.organizations.length}</strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.organizations}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OrganizationComponent;
