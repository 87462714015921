import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
var moment = require("moment");

const EditLicense = ({ match }) => {

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [license, setLicense] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [extendEndDate, setExtendEndDate] = useState();
  const [isActive, setIsActive] = useState("1");
  const [expiryAlert, setExpiryAlert] = useState(false);
  const [extendExpiry, setExtendExpiry] = useState(false);
  const [expiryDays, setExpiryDays] = useState(1);
  const [note, setNote] = useState("");
  const [validationError, setValidationError] = useState({});
  const [IsRegular, setIsRegular] = useState(true);
  const getLicense = (id) => {
    var url = new URL(`${API_BASE}license/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLicense(res.data);
          setStartDate(new Date(res.data.Start));
          setEndDate(new Date(res.data.End));
          setExtendEndDate(res.data.ExtendEnd ? new Date(res.data.ExtendEnd) : '');
          setIsActive(res.data.IsActive ? "1" : "0");
          setExpiryAlert(res.data.ExpiryAlert);
          setExtendExpiry(res.data.ExtendExpiry);
          setExpiryDays(res.data.ExpiryAlertDays);
          setIsRegular(res.data.IsRegular);
          setLoading(false);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      });
  };
  useEffect(() => {
    getLicense(match.params.id);
    return () => { };
  }, []);

  const licenceCreateHandler = () => {
    var err = {};
    var valid = true;
    setValidationError(err);
    if (!startDate) {
      err = { ...err, Start: "Start date is required" };
      valid = false;
    }
    if (!endDate) {
      err = { ...err, End: "End date is required" };
      valid = false;
    }

    if (!note) {
      err = { ...err, Note: "Note is required" };
      valid = false;
    }
    if (expiryAlert && expiryDays != 0 && !expiryDays) {
      err = { ...err, End: "Please provide days for expiry alert" };
      valid = false;
    }
    if (extendExpiry == undefined) {
      extendExpiry = false;
    }
    setValidationError(err);
    if (valid) {
      var data = {
        _id: match.params.id,
        Start: startDate,
        End: endDate,
        note: note,
        Library: license.Library._id,
        IsActive: isActive == "1" ? true : false,
        ExpiryAlert: expiryAlert,
        ExtendExpiry: extendExpiry,
        ExpiryAlertDays: expiryDays,
        ExtendEnd: extendEndDate,
        IsRegular: IsRegular
      };
      if (note) data = { ...data, Note: note };
      setEditing(true);
      fetch(`${API_BASE}license`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setEditing(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/licenses`);
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent="License"
          parentLink={`${process.env.PUBLIC_URL}/licenses`}
          title="Edit License"
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/licenses`}>
                Back to Licenses
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !license) {
    return (
      <Fragment>
        <Breadcrumb
          parent="License"
          parentLink={`${process.env.PUBLIC_URL}/licenses`}
          title="Edit License"
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Breadcrumb
        parent="License"
        parentLink={`${process.env.PUBLIC_URL}/licenses`}
        title="Edit License"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form className="needs-validation">
                  {/* { IsRegular && ( */}
                  <div className="form-row row">
                    <div className="col-md-6">
                      <label>Start Date</label>
                      <DatePicker
                        selected={startDate}
                        className="form-control digits"
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select start date"
                        dateFormat="dd/MM/yyyy"
                      />
                      {validationError.Start && (
                        <span style={{ color: "red" }}>
                          {validationError.Start}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>End Date</label>
                      <DatePicker
                        selected={endDate}
                        className="form-control digits"
                        onChange={(date) => setEndDate(date)}
                        minDate={endDate}
                        placeholderText="Select end date"
                        dateFormat="dd/MM/yyyy"
                      />
                      {validationError.End && (
                        <span style={{ color: "red" }}>
                          {validationError.End}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Library
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={license.Library.UniversityName}
                    />
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Status
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setIsActive(e.target.value)}
                      defaultValue={isActive}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                  <br />
                  <div className="form-row">
                    <label className="d-block" htmlFor="chk-ani1">
                      <input
                        checked={expiryAlert}
                        onChange={(e) => setExpiryAlert(e.target.checked)}
                        className="checkbox_animated"
                        id="chk-ani1"
                        type="checkbox"
                      />
                      Expiry alert
                    </label>
                      &nbsp;&nbsp;
                      {IsRegular && (
                      <label className="d-block" htmlFor="ext-lic">
                        <input
                          checked={extendExpiry}
                          onChange={(e) => setExtendExpiry(e.target.checked)}
                          className="checkbox_animated"
                          id="ext-lic"
                          type="checkbox"
                        />  Extend Expiry
                      </label>
                    )}
                  </div>
                  {expiryAlert && (
                    <div className="form-row">
                      <label
                        className="col-form-label"
                        htmlFor="recipient-name"
                      >
                        About to expire alert before how many days
                      </label>
                      <input
                        value={expiryDays}
                        type="number"
                        className="form-control"
                        onChange={(e) =>
                          /[0-9]/.test(e.target.value)
                            ? setExpiryDays(e.target.value)
                            : null
                        }
                      />
                    </div>
                  )}

                  {extendExpiry && (

                    <div className="col-md-6" style={{ marginTop: '10px', marginLeft: '-2%' }}>
                      <label>Extend End Date</label>
                      <DatePicker
                        selected={extendEndDate}
                        className="form-control digits"
                        onChange={(date) => setExtendEndDate(date)}
                        minDate={startDate}
                        placeholderText="Select extend end date"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  )}

                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Note
                    </label>
                    <input
                      className="form-control"
                      onChange={(e) => setNote(e.target.value)}

                    />
                    {validationError.Note && (
                      <span style={{ color: "red" }}>
                        {validationError.Note}
                      </span>
                    )}

                  </div>
                  <br />
                  {error && (
                    <div class="dark alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  )}
                  <div style={{ float: "right" }}>
                    <Button
                      color="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={licenceCreateHandler}
                    >
                      {editing ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row p-3">
              {license.Notes.map((v, i) => (
                <div className="col-md-3" key={i}>
                  <div className="ribbon-wrapper card">
                    <div className="card-body">
                      <div className="ribbon ribbon-clip ribbon-secondary">
                        {moment(v.CreatedOn).format("D MMM YYYY, hh:mm A")}
                      </div>
                      <p>{v.Note}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditLicense;
