import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

class UserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFiltered: false,
      users: [],
      groups: [],
      collegeName: [],
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      filter: {
        email: "",
        collegeName: "",
        id: "",
        username: "",
        group: "",
        //
        isactive: "",
        //
        loading: false,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getUsers = (page = null, limit = 1000) => {
    ;
    var url = new URL(
      `${API_BASE}libraryUsers/${this.props.match.params.libid}`
    ),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = Object.fromEntries(urlParams);
    for (var i in search) {
      const searchables = ["id", "username", "email", "collegeName", "group", "isactive", "isMobile"];
      if (searchables.includes(i)) {
        var filter = this.state.filter;
        filter[i] = search[i];
        params[i] = search[i];
        this.setState({ filter });
      }
    }
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            users: res.docs ? res.docs : [],
            pagination: {
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      });
  };

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  getGroups = (page = null, limit = 1000) => {
    var url = new URL(
      `${API_BASE}groups?library_id=${this.props.match.params.libid}`
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            groups: res.data,
          });
        }
      })
      .catch((err) => {
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    // this.getUsers();
    // this.getLibrary(this.props.match.params.libid);
    this.getGroups();
  }

  editHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/user/edit/${user._id}`
    );
  };

  viewHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/user/view/${user._id}/${this.props.location.search}`
    );
  };

  deleteHandler = (user) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteUser(user._id)}
        >
          You will not be able to recover this user!
        </SweetAlert>
      ),
    });
  };

  toggleStatusHandler = (user) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="info"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.toggleStatus(user._id)}
        ></SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteUser = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}libraryUsers/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getUsers();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  toggleStatus = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}libraryUsers/toggleStatus/${id}`);
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getUsers();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
      ];
    } else {
      return [];
    }
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    this.setState({ filter });
  };

  filterHandler = () => {
    var url = new URL(
      `${API_BASE}libraryUsers/${this.props.match.params.libid}`
    ),
      params = {};
    params["pagination"] = "off";
    if (this.state.filter.username)
      params["username"] = this.state.filter.username;
    if (this.state.filter.email) params["email"] = this.state.filter.email;
    if (this.state.filter.collegeName) params["collegeName"] = this.state.filter.collegeName;
    if (this.state.filter.id) params["id"] = this.state.filter.id;
    if (this.state.filter.group) params["group"] = this.state.filter.group;
    //
    if (this.state.filter.isactive) params["isactive"] = this.state.filter.isactive;
    //
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.props.history.push({
      search: url.search
        .replace("pagination=off&", "")
        .replace("pagination=off", ""),
    });
    this.filterInputHandler("loading", true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        if (res.success) {
          this.setState({
            users: res.docs ? res.docs : [],
            pagination: {
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Users`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
                this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                  "Organization Admin") && (
                    <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                      Back to Libraries
                    </Link>
                  )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Users`}
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title={`Users`}
          specialParent={this.getParentBreadcrumb()}
        />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Button
                    style={{
                      float: "right",
                      marginTop: -90,
                      marginRight: "115px",
                    }}
                    color="info"
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/user/upload`
                      )
                    }
                  >
                    <i className="fa fa-upload"></i> UPLOAD
                  </Button>
                  <Button
                    style={{ float: "right", marginTop: -90 }}
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/user/create`
                      )
                    }
                  >
                    <i className="fa fa-plus"></i> ADD
                  </Button>
                  <div className="clearfix"></div>
                  <div className="row pl-5 pr-5">
                    {/* <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Database ID"
                          value={this.state.filter.id}
                          onChange={(e) =>
                            this.filterInputHandler("id", e.target.value)
                          }
                        />
                      </div>
                    </div> */}
                    <div className="col-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="User Name"
                          value={this.state.filter.username}
                          onChange={(e) =>
                            this.filterInputHandler("username", e.target.value)
                          }
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          value={this.state.filter.email}
                          onChange={(e) =>
                            this.filterInputHandler("email", e.target.value)
                          }
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="CollegeName"
                          value={this.state.filter.collegeName}
                          onChange={(e) =>
                            this.filterInputHandler("collegeName", e.target.value)
                          }
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>

                    
                    {/* {this.isFiltered && <div className="col-md-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <select
                          className="form-control"
                          onChange={(e) =>
                            this.filterInputHandler("group", e.target.value)
                          }
                        >
                          <option value="">All groups</option>
                          {this.state.groups.map((v, i) => (
                            <option
                              selected={this.state.filter.group == v._id}
                              value={v._id}
                              key={i}
                            >
                              {v.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>} */}

 {this.isFiltered &&<div className="col-md-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <select
                    className="form-control"
                    type="text"
                    placeholder="Search by Activs Status"
                    onChange={(e) =>
                      this.filterInputHandler("isactive", e.target.value)
                    }
                    value={this.state.filter.isactive}
                    onKeyPress={(event) => {
                      var code = event.keyCode || event.which;
                      if (code === 13) {
                        this.filterHandler();
                      }}} 
                  >
                    <option value="1" >Active</option>
                    <option value="0" >Inactive</option>
                
                    <option value=""  >All Status
                   
                    </option>
                  
                  </select>
               
                </div>
              </div>}

                    <div className="col-md-3" style={{marginTop:'4px'}}>
                      <Button
                        className="btn-block"
                        color="secondary"
                        disabled={this.state.filter.loading}
                        // onclick arrow function is used to prevent the function from executing on page load
                        onClick={() => { this.filterHandler(); this.isFiltered = true; }}
                      >
                        {this.state.filter.loading ? (
                          <i className="fa fa-cog fa-spin"></i>
                        ) : (
                          <i className="fa fa-filter"></i>
                        )}{" "}
                        FILTER
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <p>
                    <strong>Total records: {this.state.users.length}</strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.users}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                    toggleStatusHandler={this.toggleStatusHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserComponent;
