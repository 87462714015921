import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../users/datatable";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class UserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      loggedInUser: null,
      modalUpdatePassword: false,
      passwordUpdate: {
        user: null,
        newPassword: "",
        updating: false,
      },
      filter: {
        id: "",
        library_id: "",
        email: "",
        username: "",
        loading: false,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getUsers = (page = null, limit = 1000) => {
    var url = new URL(`${API_BASE}users`),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = Object.fromEntries(urlParams);
    for (var i in search) {
      const searchables = ["id", "email", "library_id", "username"];
      if (searchables.includes(i)) {
        var filter = this.state.filter;
        filter[i] = search[i];
        params[i] = search[i];
        this.setState({ filter });
      }
    }
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            users: res.docs,
            pagination: {
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      });
  };

  componentDidMount() {
    this.getUsers();
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
  }

  editHandler = (user) => {
    this.props.history.push(`${process.env.PUBLIC_URL}/user/edit/${user._id}`);
  };

  viewHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/user/view/${user._id}/${this.props.location.search}`
    );
  };

  toggleUpdatePassword = () => {
    this.setState({ modalUpdatePassword: !this.state.modalUpdatePassword });
  };

  updatePasswordHandler = (user) => {
    this.setState({
      passwordUpdate: { ...this.state.passwordUpdate, user, newPassword: "" },
    });
    this.toggleUpdatePassword();
  };

  updatePassword = () => {
    this.setState({
      passwordUpdate: { ...this.state.passwordUpdate, updating: true },
    });
    fetch(`${API_BASE}users/update-password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        _id: this.state.passwordUpdate.user._id,
        Password: this.state.passwordUpdate.newPassword,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          passwordUpdate: { ...this.state.passwordUpdate, updating: false },
        });
        if (!res.success) {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.toggleUpdatePassword();
        }
      })
      .catch((err) => {
        this.setState({
          passwordUpdate: { ...this.state.passwordUpdate, updating: false },
        });
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  deleteHandler = (user) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteUser(user._id)}
        >
          You will not be able to recover this{" "}
          {this.getBreadcrumbTitle().slice(0, -1)}!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteUser = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}users/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getUsers();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  getBreadcrumbTitle = () => {
    if (this.state.loggedInUser) {
      switch (this.state.loggedInUser.Role.Name) {
        case "Organization Admin":
          return "Library Admins";

        default:
          return "Users";
      }
    }
    return "";
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    this.setState({ filter });
  };

  filterHandler = () => {
    var url = new URL(`${API_BASE}users`),
      params = {};
    params["pagination"] = "off";
    if (this.state.filter.id) params["id"] = this.state.filter.id;
    if (this.state.filter.library_id)
      params["library_id"] = this.state.filter.library_id;
    if (this.state.filter.email) params["email"] = this.state.filter.email;
    if (this.state.filter.username)
      params["username"] = this.state.filter.username;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );

    this.props.history.push({
      search: url.search
        .replace("pagination=off&", "")
        .replace("pagination=off", ""),
    });
    this.filterInputHandler("loading", true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        if (res.success) {
          this.setState({
            users: res.docs,
            pagination: {
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb title={this.getBreadcrumbTitle()} parent="false" />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb title={this.getBreadcrumbTitle()} parent="false" />
        {this.state.alert}
        <Modal
          isOpen={this.state.modalUpdatePassword}
          toggle={this.toggleUpdatePassword}
        >
          <ModalHeader toggle={this.toggleUpdatePassword}>
            Update password for{" "}
            {this.state.passwordUpdate.user
              ? this.state.passwordUpdate.user.UserName
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                New password
              </label>
              <input
                className="form-control"
                type="text"
                value={this.state.passwordUpdate.newPassword}
                onChange={(e) =>
                  this.setState({
                    passwordUpdate: {
                      ...this.state.passwordUpdate,
                      newPassword: e.target.value,
                    },
                  })
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updatePassword}>
              {this.state.passwordUpdate.updating ? (
                <i style={{ fontSize: 18 }} className="fa fa-cog fa-spin"></i>
              ) : (
                "Update"
              )}
            </Button>
            <Button color="secondary" onClick={this.toggleUpdatePassword}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Button
                    style={{ float: "right", marginTop: -90 }}
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/user/create`
                      )
                    }
                  >
                    <i className="fa fa-plus"></i> ADD
                  </Button>
                  <div className="clearfix"></div>
                  <div className="row mt-2 pl-5 pr-5">
                    <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by User Email"
                          onChange={(e) =>
                            this.filterInputHandler("email", e.target.value)
                          }
                          value={this.state.filter.email}
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by User Name"
                          onChange={(e) =>
                            this.filterInputHandler("username", e.target.value)
                          }
                          value={this.state.filter.username}
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Button
                        style={{ float: "right" }}
                        color="secondary"
                        disabled={this.state.filter.loading}
                        onClick={this.filterHandler}
                      >
                        {this.state.filter.loading ? (
                          <i className="fa fa-cog fa-spin"></i>
                        ) : (
                          <i className="fa fa-filter"></i>
                        )}{" "}
                        FILTER
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                <p>
                    <strong>Total records: {this.state.users.length}</strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.users}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                    updatePasswordHandler={this.updatePasswordHandler}
                    isAdmin={this.state.loggedInUser.Role.IsAdmin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserComponent;
