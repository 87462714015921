import React, { Fragment, Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import data from '../../data/role';
import Datatable from '../roles/datatable';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router'


// const toggle = () => {
//     setModal(!modal)
// }


class DataTableComponent extends Component {

    constructor(props) {
        super(props);
        this.state= {
            toggleAdd: false,
            show: false
        }
    }

    closeModalAdd = ()=> {
        this.setState((prevState, props) => ({
            show: !prevState.show
        })
        )
    }
    showModalAdd = ()=> {
        // this.setState((prevState, props) =>({
        //     show: true
        // }))
        this.props.history.push(`${process.env.PUBLIC_URL}/role/create`);

    }

    editActionLoad = (id)=> {
        this.props.history.push(`${process.env.PUBLIC_URL}/role/edit/${id}`);

    }
    
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Roles" parent="false" />
                <Modal isOpen={this.state.show}>
                    <ModalHeader >Modal title</ModalHeader>
                    <ModalBody>
                        .....
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary">Save Changes</Button>
                        <Button color="secondary" onClick={this.closeModalAdd}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Roles</h5>
                                    <Button style={{"float":"right"}} color="primary" onClick={this.showModalAdd}>ADD</Button>

                                </div>
                                <div className="card-body datatable-react">
                                    <Datatable
                                        multiSelectOption={false}
                                        myData={data}
                                        pageSize={25}
                                        pagination={true}
                                        class="-striped -highlight"
                                        editActionLoad={this.editActionLoad}
                                        
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};


export default DataTableComponent;