import React, { Component, Fragment } from "react";
import "./customEmails.css";

export default class InputTag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
    };
  }
  componentWillReceiveProps(newProps) {
    this.setState({ tags: newProps.tags });
  }

  removeTag = (i) => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
    this.props.change(newTags);
  };

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        alert("Duplicate email found");
        return;
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
        alert("Please enter a valid email address");
        return;
      }
      const newTags = [...this.state.tags, val];
      this.setState({ tags: newTags });
      this.tagInput.value = null;
      this.props.change(newTags);
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  render() {
    const { tags } = this.state;

    return (
      <div className="input-tag">
        <ul className="input-tag__tags">
          {tags.map((tag, i) => (
            <li key={tag}>
              {tag}
              <button
                type="button"
                onClick={() => {
                  this.removeTag(i);
                }}
              >
                +
              </button>
            </li>
          ))}
          <li className="input-tag__tags__input">
            <input
              type="text"
              onKeyDown={this.inputKeyDown}
              placeholder="Type email and press enter"
              ref={(c) => {
                this.tagInput = c;
              }}
            />
          </li>
        </ul>
      </div>
    );
  }
}
