import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import useForm from "react-hook-form";
import { useHistory } from "react-router-dom";
import { API_BASE } from "../../../constant/api";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountryCodeList from "../../../data/countryCode";
const EditUser = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });
  const [dob, setDOB] = useState();
  const [yoa, setYOA] = useState();
  //user expiry
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  //
  const [groups, setGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState(null);
  const [library, setLibrary] = useState({
    UniversityName: "Fetching...",
  });

  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLibrary(res.data);
        } else {
          setLibrary(404);
        }
      });
  };
  const getGroups = () => {
    var url = new URL(`${API_BASE}groups?library_id=${match.params.libid}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setGroups(
            res.data.map((v) => ({
              value: v._id,
              label: v.Name,
            }))
          );
        } else {
        }
      });
  };

  const getUser = (id) => {
    var url = new URL(`${API_BASE}libraryUsers/detail/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {


          setLoading(false);
          setUser(res.data);
          Date.parse(res.data.DOB) ? setDOB(new Date(res.data.DOB)) : setDOB(new Date())
          //expiry
          // Date.parse(res.data.EndsOn) ? setEndDate(new Date(res.data.EndsOn)) : setEndDate(new Date())
          //
          if (res.data.Groups) {
            setSelectedGroups(
              res.data.Groups.map((v) => ({
                value: v._id,
                label: v.Name,
              }))
            );
          }
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      })
      .catch((err) => {
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(match.params.libid);
    getGroups();
    getUser(match.params.id);
    return () => { };
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setEditing(true);
      data = {
        ...data, _id: match.params.id, Library: match.params.libid,
        // user expiry
        // StatsOn: startDate,
        // EndsOn: endDate
      };
      // end
      if (selectedGroups) {
        data = { ...data, Groups: selectedGroups.map((v) => v.value), DOB: new Date(dob) };
      }
      fetch(`${API_BASE}libraryUsers`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success || res.errors) {
            let errMsg = "";
            for (var formErr in res.errors) {
              errMsg += res.errors[formErr].message + "\n";
            }
            setError(errMsg)
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/library/${match.params.libid}/users`
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getParentBreadcrumb = () => {
    if (
      loggedInUser.Role.Name == "Master Admin" ||
      loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${match.params.libid}`,
        },
        {
          label: "Users",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/users`,
        },
      ];
    } else {
      return [
        {
          label: "Users",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/users`,
        },
      ];
    }
  };


  if (library == 404) {
    return (
      <Fragment>
        <Breadcrumb
          title="Edit User"
          parentLink={`${process.env.PUBLIC_URL}/libraries`}
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
              loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "false"
          }
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>Library not found!</p>
            <hr />
            <p className="mb-0">
              {(loggedInUser.Role.Name == "Master Admin" ||
                loggedInUser.Role.Name == "Organization Admin") && (
                  <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                    Back to Libraries
                  </Link>
                )}
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb title={`Edit User`} specialParent={getParentBreadcrumb()} />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/users`}>Back to Users</Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !user || !groups) {
    return (
      <Fragment>
        <Breadcrumb title={`Edit User`} specialParent={getParentBreadcrumb()} />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb title={`Edit User`} specialParent={getParentBreadcrumb()} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >

                  <div className="form-row">
                    <div className="col-sm-6">
                      <label className="col-form-label" htmlFor="validationCustom01">User Name (Login Id)</label>
                      <input
                        className="form-control"
                        type="text"
                        name="UserName"
                        placeholder="User Name"
                        defaultValue={user.UserName}
                        ref={register({ required: true })}
                      />
                      <span>{errors.UserName && "User Name is required"}</span>

                    </div>
                    <div className="col-sm-6">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Password
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Only, if you want to change"
                        name="Password"
                        ref={register({})}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="Salutation">
                        Salutation
                      </label>
                      <select

                        className="form-control"
                        name="Salutation"
                        ref={register({ required: true })}
                        defaultValue={user.Salutation}

                      >
                        <option value="Mr">Mr</option>
                        <option value="Miss">Miss</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                        <option value="Prof">Prof</option>
                        <option value="Other">Other</option>
                        <option value=" ">None</option>
                      </select>
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="FirstName">
                        First name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First name"
                        name="FirstName"
                        defaultValue={user.FirstName}
                        ref={register({ required: true })}
                      />
                      <span>
                        {errors.FirstName && "First name is required"}
                      </span>
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="LastName">
                        Last name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last name"
                        name="LastName"
                        defaultValue={user.LastName}
                        ref={register({ required: true })}
                      />
                      <span>
                        {errors.FirstName && "Last name is required"}
                      </span>
                    </div>

                  </div>
                  {/* <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Display Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Display Name"
                      name="DisplayName"
                      defaultValue={user.DisplayName}
                      ref={register({ required: true })}
                    />
                    <span>
                      {errors.DisplayName && "Display Name is required"}
                    </span>
                  </div> */}
                  <div className="form-row">
                    <div className="col-sm-2">
                      <label className="col-form-label" htmlFor="CountryCode">
                        Country Code
                      </label>
                      <select
                        className="form-control"
                        name="CountryCode"
                        ref={register({ required: true })}
                        defaultValue={user.CountryCode}
                      >
                        {
                          CountryCodeList.map((country, index) =>

                            <option value={`${country.PhoneCode}`}>{country.CountryName} - {country.PhoneCode}</option>
                          )
                        }
                      </select>
                      <span>{errors.UserType && "Country code is required"}</span>
                    </div>
                    <div className="col-sm-2">
                      <label className="col-form-label" htmlFor="MobileNumber">
                        Mobile number
                      </label>
                      <input
                        className="form-control"
                        placeholder="Mobile number"
                        name="MobileNumber"
                        defaultValue={user.MobileNumber}
                        ref={register({ required: true })}
                      />
                      <span>{errors.Email && "Mobile number is required"}</span>
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        name="Email"
                        defaultValue={user.Email}
                        ref={register({ required: true })}
                      />
                      <span>{errors.Email && "Email is required"}</span>
                    </div>
                    {/* <div className="col-sm-4">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Cc
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Cc"
                      name="Cc"
                      defaultValue={user.Cc}
                      ref={register({ required: true })}
                    />
                    <span>{errors.Email && "Cc is required"}</span>
                  </div> */}

                    <div className="col-sm-4">
                      <div>
                        <label className="col-form-label" htmlFor="DOB">
                          Date of birth
                        </label>
                        <DatePicker
                          // selected={dob}
                          name="DOB"
                          className="form-control"
                          onChange={(date) => setDOB(date)}
                          placeholderText="DOB (DD/MM/YYYY)"
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                        />
                        {errors.DOB && (
                          <span style={{ color: "red" }}>
                            {errors.DOB}
                          </span>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="form-row">
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="UserId">
                        User Id (Library/Admission/Student ID)
                      </label>
                      <input
                        className="form-control"
                        placeholder="User Id"
                        name="UserId"
                        defaultValue={user.UserId}
                        ref={register({})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="UserType">
                        User type
                      </label>
                      <select
                        className="form-control"
                        name="UserType"
                        ref={register({})}
                        defaultValue={user.UserType}
                      >
                        <option value="UG">UG</option>
                        <option value="PG">PG</option>
                        <option value="Research Scholars">Research Scholars</option>
                        <option value="Staff">Staff</option>
                        <option value="Faculty">Faculty</option>
                        <option value="Guest">Guest</option>
                        <option value="Scientist">Scientist</option>
                        <option value="Others">Others</option>
                      </select>
                      <span>{errors.UserType && "User type is required"}</span>
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="Designation">
                        Designation
                      </label>
                      <input
                        className="form-control"
                        placeholder="Designation"
                        name="Designation"
                        defaultValue={user.Designation}
                        ref={register({})}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-sm-6">
                      <label className="col-form-label" htmlFor="DepartmentName">
                        Department name
                      </label>
                      <input
                        className="form-control"
                        placeholder="Department name"
                        name="DepartmentName"
                        defaultValue={user.DepartmentName}
                        ref={register({})}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="col-form-label" htmlFor="CollegeName">
                        College name
                      </label>
                      <input
                        className="form-control"
                        placeholder="College name"
                        name="CollegeName"
                        defaultValue={user.CollegeName}
                        ref={register({})}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="City">
                        City
                      </label>
                      <input
                        className="form-control"
                        placeholder="City"
                        name="City"
                        defaultValue={user.City}
                        ref={register({})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="State">
                        State
                      </label>
                      <input
                        className="form-control"
                        placeholder="State"
                        name="State"
                        defaultValue={user.State}
                        ref={register({})}
                      />
                    </div>
                    <div className="col-sm-4">
                      <label className="col-form-label" htmlFor="Country">
                        Country
                      </label>
                      <input
                        className="form-control"
                        placeholder="Country"
                        name="Country"
                        defaultValue={user.Country}
                        ref={register({})}
                      />
                    </div>

                  </div>

                  <div className="form-row">
                    <div className="col-sm-6 form-group">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Groups
                      </label>
                      <Select
                        value={selectedGroups}
                        isMulti
                        isClearable
                        options={groups}
                        onChange={(value) => setSelectedGroups(value)}
                      />
                    </div>
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="IsVerified">
                        Registration Verification Status
                      </label>
                      <select
                        className="form-control"
                        name="IsVerified"
                        ref={register({ required: true })}
                        defaultValue={user.IsVerified ? "1" : "0"}
                      >
                        <option value="0">Not Verified</option>
                        <option value="1">Verified</option>
                      </select>
                      <span>{errors.IsActive && "Verification status is required"}</span>
                    </div>
                    <div className="col-sm-3">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Active Status
                      </label>
                      <select
                        className="form-control"
                        name="IsActive"
                        ref={register({ required: true })}
                        defaultValue={user.IsActive ? "1" : "0"}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span>{errors.IsActive && "Status is required"}</span>
                    </div>

                  </div>
                  {/* user expiry */}
                  <div className="form-row">
                    {/* <div className="col-sm-4">

                      <label className="col-form-label" htmlFor="State" >
                        Expiry Date
                    </label>


                      <DatePicker
                        selected={endDate}
                        className="form-control digits"
                        onChange={(date) => setEndDate(date)}
                        minDate={startDate}
                        placeholderText="DD/MM/YYYY"
                                       
                        dateFormat="dd/MM/yyyy"
                      />
                      <span>{errors.EndsOn && " End Date is required"}</span>
                    </div> */}

                    <div className="col-sm-4">
                      <div>
                        <label className="col-form-label" htmlFor="DOB">
                          Year_Of_Admission
                        </label>
                        <DatePicker
                          selected={yoa}
                          name="DOB"
                          className="form-control"
                          onChange={(date) => setYOA(date)}
                          placeholderText="YOA (DD/MM/YYYY)"
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          showYearDropdown
                        />


                      </div>
                    </div>
                  </div>
                  {/* end */}

                  {error && (
                    <div style={{ whiteSpace: "pre-line" }} className="dark alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  )}

                  <div style={{ float: "right" }}>
                    <Button
                      color="primary"
                      style={{ marginLeft: "10px", marginTop: 10 }}
                    >
                      {editing ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditUser;
