import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { Button } from "reactstrap";
var moment = require("moment");

class ViewGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      group: null,
      errMsg: null,
    };
  }

  getGroup = (id) => {
    var url = new URL(
      `${API_BASE}groups/${id}?library_id=${this.props.match.params.libid}`
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            group: res.data,
          });
        } else {
          this.setState({ loading: false, errMsg: res.msg });
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLibrary(this.props.match.params.libid);
    this.getGroup(this.props.match.params.id);
  }

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
        {
          label: "User Groups",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/groups/${this.props.location.search}`,
        },
      ];
    } else {
      return [
        {
          label: "User Groups",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/groups/${this.props.location.search}`,
        },
      ];
    }
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`View User Group`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                    "Organization Admin") && (
                  <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                    Back to Libraries
                  </Link>
                )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Group"
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (this.state.errMsg) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Group"
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>{this.state.errMsg}</p>
              <hr />
              <p className="mb-0">
                <Link to={`${process.env.PUBLIC_URL}/gorups`}>
                  Back to Groups
                </Link>
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Breadcrumb
          title="View Group"
          specialParent={this.getParentBreadcrumb()}
        />
        <Button
          style={{ float: "right", marginTop: -60, marginRight: 16 }}
          color="primary"
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/group/edit/${this.props.match.params.id}`
            )
          }
        >
          <i className="fa fa-pencil"></i> EDIT
        </Button>
        <div className="container-fluid">
          <div className="card p-4">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    <div className="blog-details">
                      <h4>{this.state.group.DisplayName}</h4>
                    </div>
                  </div>
                  <section className="comment-box" style={{ paddingTop: 20 }}>
                    {/* <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>#ID</h5>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <code>{this.state.group._id}</code>
                        </p>
                      </div>
                    </div> */}
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Name</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.group.Name}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Users</h5>
                      </div>
                      <div className="col-md-4">
                        <p>
                          <Link
                            className="mb-5"
                            to={`${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/users?group=${this.state.group._id}`}
                          >
                            <span className="badge badge-pill badge-primary">
                              {this.state.group.LibraryUsers
                                ? this.state.group.LibraryUsers.length
                                : 0}
                            </span>
                          </Link>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <span style={{ fontSize: "1.25rem" }}>Resources</span>
                        <span className="badge badge-pill badge-dark ml-3">
                          {this.state.group.Resources
                            ? this.state.group.Resources.length
                            : 0}
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <ul className="list-group">
                          {this.state.group.Resources.map((v, k) => (
                            <li
                              key={k}
                              className="list-group-item d-flex justify-content-between align-items-center"
                            >
                              {v.Name}
                              {v.Libraries && v.Libraries.length > 0 && (
                                <span className="badge badge-pill badge-light">
                                  <Link
                                    className="mb-5"
                                    to={`${process.env.PUBLIC_URL}/library/${v.Libraries[0]._id}/resource/view/${v._id}`}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <hr />

                    <div className="blog-box blog-details">
                      <div className="blog-details">
                        <ul className="blog-social">
                          {this.state.group.CreatedOn && (
                            <li
                              className="digits"
                              style={{
                                border: "none",
                                color: "#007bff",
                                paddingRight: 0,
                                cursor: "pointer",
                              }}
                              id="createdOn"
                            >
                              <i className="fa fa-clock-o"></i>
                              {moment(this.state.group.CreatedOn).format(
                                "D MMM YYYY"
                              )}
                            </li>
                          )}
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdOn"
                          >
                            Created On
                          </UncontrolledTooltip>
                          {this.state.group.CreatedBy && (
                            <li
                              id="createdBy"
                              className="digits"
                              style={{
                                marginRight: 40,
                                color: "#007bff",
                                cursor: "pointer",
                              }}
                            >
                              <i className="icofont icofont-user"></i>
                              {this.state.group.CreatedBy.UserName}
                            </li>
                          )}
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdBy"
                          >
                            Created By
                          </UncontrolledTooltip>
                          {this.state.group.UpdatedOn && (
                            <Fragment>
                              <li
                                id="updatedOn"
                                className="digits"
                                style={{
                                  borderLeft: "1px solid #777777",
                                  color: "#ff9f40",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-clock-o"></i>
                                {moment(this.state.group.UpdatedOn).format(
                                  "D MMM YYYY"
                                )}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedOn"
                              >
                                Updated On
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                          {this.state.group.UpdatedBy && (
                            <Fragment>
                              <li
                                id="updatedBy"
                                className="digits"
                                style={{ color: "#ff9f40", cursor: "pointer" }}
                              >
                                <i className="icofont icofont-user"></i>
                                {this.state.group.UpdatedBy.UserName}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedBy"
                              >
                                Updated By
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <section className="comment-box">
            <Link
              className="mb-5"
              to={`${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/groups`}
            >
              <i className="fa fa-arrow-circle-left"></i> Back to Groups
            </Link>
          </section>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ViewGroup;
