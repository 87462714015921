import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import useForm from "react-hook-form";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
const AddOrganization = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedResources, setSelectedResources] = useState([]);
  
  useEffect(() => {
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setLoading(true);
      fetch(`${API_BASE}organizations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setLoading(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/organizations`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Organizations"
        parentLink={`${process.env.PUBLIC_URL}/organizations`}
        title="Add Organization"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="Name"
                      ref={register({ required: true })}
                    />
                    <span>{errors.Name && "Name is required"}</span>
                  </div>
                  <div className="mt-2" style={{ float: "right" }}>
                    <Button color="primary" style={{ marginLeft: "10px" }}>
                    {loading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Create"
                    )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddOrganization;
