import React, { Fragment, useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut, Key } from "react-feather";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../constant/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const UserMenu = () => {
  const history = useHistory();
  const [user, setUser] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [modal, setModal] = useState();
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    return () => { };
  }, []);
  const toggle = () => {
    setModal(!modal);
  };
  const logoutHandler = () => {
    toast.success("You have successfully logged out", {
      position: toast.POSITION.TOP_CENTER,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const updatePassword = () => {
    setUpdating(true);
    fetch(`${API_BASE}auth/update-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        _id: user._id,
        OldPassword: oldPassword,
        NewPassword: newPassword,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdating(false);
        if (!res.success) {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          toggle();
          setNewPassword('')
          setOldPassword('')
        }
      })
      .catch((err) => {
        setUpdating(false);
        // toast.error("No response from server! Try again.", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      });
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update password</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label className="col-form-label" htmlFor="recipient-name">
              Old password
            </label>
            <input
              className="form-control"
              type="text"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label" htmlFor="recipient-name">
              New password
            </label>
            <input
              className="form-control"
              type="text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updatePassword}>
            {updating ? (
              <i style={{ fontSize: 18 }} className="fa fa-cog fa-spin"></i>
            ) : (
              "Update"
            )}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <i
            className="fa fa-user"
            style={{ fontSize: 25, color: "#4466F2" }}
          ></i>
        </div>
        <ul
          className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"
          style={{ width: "180px", marginTop: "-30px" }}
        >
          <li>
            <a onClick={toggle}>
              <Key />
              Update password
            </a>
          </li>
          <li>
            <Link to="/login" onClick={() => logoutHandler()}>
              <LogOut /> Log out
            </Link>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
