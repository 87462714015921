import React from 'react'
export const data =[
    {
        "_id": "5e85a9edcc76836f49a7fdc6",
        id:1,
        "Name": "Master Admin",
        "IsAdmin": true,
        "CreatedOn": "2020-04-02T09:01:06.248Z",
        "UpdatedOn": "2020-04-02T09:01:06.248Z",
    },
    {
        "_id": "5e85aa2ccc76836f49a7fdc7",
        id:2,
        "Name": "Organization Admin",
        "IsAdmin": false,
        "CreatedOn": "2020-04-02T09:01:06.248Z",
        "UpdatedOn": "2020-04-02T09:01:06.248Z",

    },
    {
        "_id": "5e85aa47cc76836f49a7fdc8",
        id:3,
        "Name": "Library Admin",
        "IsAdmin": false,
        "CreatedOn": "2020-04-02T09:01:06.248Z",
        "UpdatedOn": "2020-04-02T09:01:06.248Z",
    }
]

export default data