import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { Button } from "reactstrap";
// import Organization from "../../../../API/app/models/Organization";
var moment = require("moment");

class ViewOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, organization: null, errMsg: null };
  }

  getOrganization = (id) => {
    var url = new URL(`${API_BASE}organizations/${id}`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            organization: res.data,
          });
        } else {
          this.setState({ loading: false, errMsg: res.msg });
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  componentDidMount() {
    this.getOrganization(this.props.match.params.id);
  }

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Organization"
            parentLink={`${process.env.PUBLIC_URL}/organizations`}
            parent="Organizations"
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (this.state.errMsg) {
      return (
        <Fragment>
          <Breadcrumb
            title="View Organization"
            parentLink={`${process.env.PUBLIC_URL}/organizations`}
            parent="Organizations"
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>{this.state.errMsg}</p>
              <hr />
              <p className="mb-0">
                <Link to={`${process.env.PUBLIC_URL}/organizations`}>
                  Back to Organizations
                </Link>
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Breadcrumb
          title="View Organization"
          parentLink={`${process.env.PUBLIC_URL}/organizations`}
          parent="Organizations"
        />
        <Button
          style={{ float: "right", marginTop: -60, marginRight: 16 }}
          color="primary"
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/organization/edit/${this.props.match.params.id}`
            )
          }
        >
          <i className="fa fa-pencil"></i> EDIT
        </Button>
        <div className="container-fluid">
          <div className="card p-4">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    <div className="blog-details">
                      <h4>{this.state.organization.Name}</h4>
                    </div>
                  </div>
                  <section className="comment-box" style={{ paddingTop: 20 }}>
                   <hr />
                   <div className="row">
                      <div className="col-md-12">
                        <span style={{ fontSize: "1.25rem" }}>Consortia Library</span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <ul className="list-group">
                          {this.state.organization.Libraries.filter((l) => l._id == this.state.organization.OrgLibraryId).map((v, k) => (
                            <li
                              key={k}
                              className="list-group-item d-flex justify-content-between align-items-center"
                            >
                              {v.UniversityName}
                              <span className="badge badge-pill badge-light">
                                <Link
                                  className="mb-5"
                                  to={`${process.env.PUBLIC_URL}/library/view/${v._id}`}
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div> 
                  <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <span style={{ fontSize: "1.25rem" }}>Libraries</span>
                        <span className="badge badge-pill badge-dark ml-3">
                          {this.state.organization.Libraries
                            ? this.state.organization.Libraries.filter((l) => l._id != this.state.organization.OrgLibraryId).length
                            : 0}
                        </span>
                      </div>
                      <div className="col-md-6 mt-2">
                        <ul className="list-group">
                          {this.state.organization.Libraries.filter((l) => l._id != this.state.organization.OrgLibraryId).map((v, k) => (
                            <li
                              key={k}
                              className="list-group-item d-flex justify-content-between align-items-center"
                            >
                              {v.UniversityName}
                              <span className="badge badge-pill badge-light">
                                <Link
                                  className="mb-5"
                                  to={`${process.env.PUBLIC_URL}/library/view/${v._id}`}
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <hr />
                    <div className="blog-box blog-details">
                      <div className="blog-details">
                        <ul className="blog-social">
                          {this.state.organization.CreatedOn && (
                            <li
                              className="digits"
                              style={{
                                border: "none",
                                color: "#007bff",
                                paddingRight: 0,
                                cursor: "pointer",
                              }}
                              id="createdOn"
                            >
                              <i className="fa fa-clock-o"></i>
                              {moment(this.state.organization.CreatedOn).format(
                                "D MMM YYYY"
                              )}
                            </li>
                          )}
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdOn"
                          >
                            Created On
                          </UncontrolledTooltip>
                          {this.state.organization.CreatedBy && (
                            <li
                              id="createdBy"
                              className="digits"
                              style={{
                                marginRight: 40,
                                color: "#007bff",
                                cursor: "pointer",
                              }}
                            >
                              <i className="icofont icofont-user"></i>
                              {this.state.organization.CreatedBy.UserName}
                            </li>
                          )}
                          <UncontrolledTooltip
                            placement="bottom"
                            target="createdBy"
                          >
                            Created By
                          </UncontrolledTooltip>
                          {this.state.organization.UpdatedOn && (
                            <Fragment>
                              <li
                                id="updatedOn"
                                className="digits"
                                style={{
                                  borderLeft: "1px solid #777777",
                                  color: "#ff9f40",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-clock-o"></i>
                                {moment(this.state.organization.UpdatedOn).format(
                                  "D MMM YYYY"
                                )}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedOn"
                              >
                                Updated On
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                          {this.state.organization.UpdatedBy && (
                            <Fragment>
                              <li
                                id="updatedBy"
                                className="digits"
                                style={{ color: "#ff9f40", cursor: "pointer" }}
                              >
                                <i className="icofont icofont-user"></i>
                                {this.state.organization.UpdatedBy.UserName}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedBy"
                              >
                                Updated By
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <section className="comment-box">
            <Link className="mb-5" to={`${process.env.PUBLIC_URL}/organizations`}>
              <i className="fa fa-arrow-circle-left"></i> Back to Organizations
            </Link>
          </section>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ViewOrganization;
