import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { Button } from "reactstrap";
import "./users.scss";
var moment = require("moment");

class UploadUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isReady: false,
      library: {
        UniversityName: "Fetching...",
      },
      userData: null,
      sendEmail: false,
      errMsg: null,
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
    };
  }

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLibrary(this.props.match.params.libid);
  }

  csvJSON = (csv) => {
    let allTextLines = csv.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(",");
    if (headers.length < 6) {
      return false;
    }
    let lines = [];
    for (let i = 0; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      if (data.length === headers.length) {
        let tarr = [];
        for (let j = 0; j < headers.length; j++) {
          tarr.push(data[j].replace(/(^")|("$)/g, ""));
        }
        lines.push(tarr);
      }
    }
    return lines;
  };

  convertFile(event) {
    this.setState({ loading: true });
    this.setState({ csvData: null });
    var file = event.target.files[0];
    if (!file) {
      this.setState({ loading: false });
      return;
    }
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      let csv = reader.result.toString();
      var jsonData = this.csvJSON(csv);
      if (!jsonData) {
        this.setState({ loading: false });
        return false;
      }
      jsonData.splice(0, 1);
      var bulkData = jsonData.map((v) => {
        let user = {
          UserName: v[0],
          Salutation: v[1],
          UserId: v[2],
          FirstName: v[3],
          LastName: v[4],
          Email: v[5],
          CountryCode: v[6],
          MobileNumber: v[7],
          UserType: v[8],
          DOB: v[9],
          Designation: v[10],
          DepartmentName: v[11],
          CollegeName: v[12],
          YearOfAdmission: v[12],
          City: v[13],
          State: v[14],
          Country: v[15],
          Groups: v[16].split(",").filter((v) => v),
          IsActive: v[17] == "Active" ? true : false,
          Delete: v[17] == "Delete" ? true : false,
        };
        return user;
      });

      this.setState({ userData: bulkData });
      this.setState({ loading: false, isReady: true });
    };
  }

  uploadHandler = () => {
    if (this.state.userData) {
      this.setState({ loading: true });
      var data = {
        Library: this.props.match.params.libid,
        SendEmail: this.state.sendEmail,
        Data: this.state.userData,
      };
      fetch(`${API_BASE}libraryUsers/upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ loading: false, isReady: false, userData: null });
          this.fileInput.value = "";
          if (!res.success) {
            toast.error(JSON.stringify(res.msg), {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(JSON.stringify(res.msg), {
              position: toast.POSITION.TOP_CENTER,
            });
            this.props.history.push(
              `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/users`
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
        {
          label: "Users",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/users`,
        },
      ];
    } else {
      return [
        {
          label: "Users",
          link: `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/users`,
        },
      ];
    }
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Upload User`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
                this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                  "Organization Admin") && (
                    <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                      Back to Libraries
                    </Link>
                  )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title={`Upload User`}
          specialParent={this.getParentBreadcrumb()}
        />
        <div className="container-fluid">
          <div
            className="card p-4"
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
            }}
          >
            <div className="row">
              <div
                className="col-md-12 p-2 mb-5"
                style={{ backgroundColor: "beige" }}
              >
                <p>
                  <b>#Upload Note ::</b>
                  <br />-{" "}
                  <a
                    href={`${API_BASE}libraryUsers/export/${this.props.match.params.libid}`}
                    target="_blank"
                    download
                  >
                    Download User Data
                  </a>{" "}
                  and Upload it here
                  <br />
                  - For Groups Column Please Provide Valid Group Name or Leave
                  blank, wrong name will be ignored
                  <br />- New Records must have all valid inputs including
                  Password fields, otherwise it will ignored
                  <br />- To update existing records without Password field
                  please keep the Password field as blank or else the Password
                  field will be updated too
                  <br />
                </p>
              </div>
              <div className="col-md-8">
                <input
                  type="file"
                  onChange={(e) => this.convertFile(e)}
                  accept=".csv"
                  ref={(ref) => (this.fileInput = ref)}
                />
              </div>
              <div className="col-md-4">
                <Button
                  style={{
                    float: "right",
                  }}
                  color="primary"
                  disabled={!this.state.isReady}
                  onClick={this.uploadHandler}
                >
                  {this.state.loading ? (
                    <i className="fa fa-cog fa-spin"></i>
                  ) : (
                    <i className="fa fa-upload"></i>
                  )}{" "}
                  UPLOAD
                </Button>
              </div>
              <div className="col-md-4">
                <label className="d-block" htmlFor="chk-ani1">
                  <input
                    checked={this.state.sendEmail}
                    onChange={(e) =>
                      this.setState({ sendEmail: e.target.checked })
                    }
                    className="checkbox_animated"
                    id="chk-ani1"
                    type="checkbox"
                  />
                  Send email
                </label>
              </div>
            </div>
          </div>
          <section className="comment-box">
            <Link
              className="mb-5"
              to={`${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/users`}
            >
              <i className="fa fa-arrow-circle-left"></i> Back to Users
            </Link>
          </section>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default UploadUser;
