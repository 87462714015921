import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import useForm from "react-hook-form";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";

const EditResourceCategory = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const [resourceCategories, setResourceCategories] = useState(null);
  const [resourceCategory, setResourceCategory] = useState(null);
  const [selectedResourceCategory, setSelectedResourceCategory] = useState(
    null
  );
  const [editing, setEditing] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });
  const [library, setLibrary] = useState({
    UniversityName: "Fetching...",
  });
  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLibrary(res.data);
        } else {
          setLibrary(404);
        }
      });
  };
  const getResourceCategories = () => {
    var url = new URL(
      `${API_BASE}resourceCategories?library_id=${match.params.libid}`
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          var data = res.data
            .map((v) => ({
              value: v._id,
              label: v.Name,
            }))
            .filter((v) => v.value != match.params.id);
          setResourceCategories(data);
        }
      })
      .catch((err) => {
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const getResourceCategory = (id) => {
    var url = new URL(`${API_BASE}resourceCategories/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setResourceCategory(res.data);
          if (res.data.ParentResourceCategory) {
            console.log("hello");
            setSelectedResourceCategory({
              value: res.data.ParentResourceCategory._id,
              label: res.data.ParentResourceCategory.Name,
            });
          }
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(match.params.libid);
    getResourceCategory(match.params.id);
    getResourceCategories();
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setEditing(true);
      data = { ...data, _id: match.params.id };
      if (selectedResourceCategory) {
        data = {
          ...data,
          ParentResourceCategory: selectedResourceCategory.value,
        };
      }
      fetch(`${API_BASE}resourceCategories`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setEditing(false);
          if (!res.success) {
            setEditing(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/library/${match.params.libid}/resource-categories`);
          }
        })
        .catch((err) => {
          setEditing(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getParentBreadcrumb = () => {
    if (
      loggedInUser.Role.Name == "Master Admin" ||
      loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${match.params.libid}`,
        },
        {
          label: "Resource Category",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resource-categories`,
        },
      ];
    } else {
      return [
        {
          label: "Resource Category",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/resource-categories`,
        },
      ];
    }
  };

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb
          parent="Resource Category"
          parentLink={`${process.env.PUBLIC_URL}/resource-categories`}
          title="Edit Resource Category"
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to={`${process.env.PUBLIC_URL}/resource-categories`}>
                Back to Resource Categories
              </Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  if (loading || !resourceCategories || !resourceCategory) {
    return (
      <Fragment>
        <Breadcrumb
          specialParent={getParentBreadcrumb()}
          title="Edit Resource Category"
        />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb
        specialParent={getParentBreadcrumb()}
        title="Edit Resource Category"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      defaultValue={resourceCategory.Name}
                      name="Name"
                      ref={register({ required: true })}
                    />
                    <span>{errors.Name && "Name is required"}</span>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Display Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Display Name"
                      defaultValue={resourceCategory.DisplayName}
                      name="DisplayName"
                      ref={register({ required: true })}
                    />
                    <span>
                      {errors.DisplayName && "DisplayName is required"}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Parent Resource Category
                    </label>
                    <Select
                      value={selectedResourceCategory}
                      options={resourceCategories}
                      isClearable={true}
                      onChange={(selected) =>
                        setSelectedResourceCategory(selected)
                      }
                    />
                  </div>
                  <div style={{ float: "right" }}>
                    <Button color="primary" style={{ marginLeft: "10px" }}>
                      {editing ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditResourceCategory;
