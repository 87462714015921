import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";

// Import custom Components

import Default from "./components/dashboard/defaultCompo/default";
import Ecommerce from "./components/dashboard/ecommerce";
import University from "./components/dashboard/university";
import CryptoComponent from "./components/dashboard/crypto/cryptoComponent";
import Project from "./components/dashboard/project/project";

// sample page
import Samplepage from "./components/sample/samplepage";
import SupportTicket from "./components/support-ticket/supportTicket";
import login from "./pages/login";
import DataTableComponent from "./components/roles/dataTableComponent";
import EditRole from "./components/roles/editrole";
import AddRole from "./components/roles/addrole";

import OrganizationComponent from "./components/organization/organizationComponent";
import AddOrganization from "./components/organization/addOrganization";
import EditOrganization from "./components/organization/editOrganization";
import ViewOrganization from "./components/organization/viewOrganization";

import UserComponent from "./components/users/userComponent";
import AddUser from "./components/users/adduser";
import EditUser from "./components/users/edituser";
import ViewUser from "./components/users/viewUser";

// signup new code//
import RegisterUserComponent from "./components/libraries/register-users/registerUserComponent";

import ResourceCategoryComponent from "./components/libraries/resource-category/resourceCategoryComponent";
import AddResourceCategory from "./components/libraries/resource-category/addresourcecategory";
import EditResourceCategory from "./components/libraries/resource-category/ediresourcecategory";
import ViewResourceCategory from "./components/libraries/resource-category/viewResourceCategory";

import GroupComponent from "./components/libraries/groups/groupComponent";
import AddGroup from "./components/libraries/groups/addgroup";
import EditGroup from "./components/libraries/groups/editgroup";
import ViewGroup from "./components/libraries/groups/viewGroup";

import MasterResourceComponent from "./components/master-resource/masterResourceComponent";
import AddMasterResource from "./components/master-resource/addMasterResource";
import EditMasterResource from "./components/master-resource/editMasterResource";
import ViewMasterResource from "./components/master-resource/viewMasterResource";

import ResourceComponent from "./components/libraries/resources/resourceComponent";
import AddResource from "./components/libraries/resources/addResource";
import EditResource from "./components/libraries/resources/editResource";
import ViewResource from "./components/libraries/resources/viewResource";

import LibrariesComponent from "./components/libraries/librariesComponent";
import AddLibrary from "./components/libraries/addLibrary";
import EditLibraries from "./components/libraries/editLibraries";
import LibraryFiles from "./components/libraries/libraryFiles";
import ViewLibrary from "./components/libraries/viewLibrary";

import LibraryUserComponent from "./components/libraries/users/userComponent";
import AddLibraryUser from "./components/libraries/users/adduser";
import EditLibraryUser from "./components/libraries/users/edituser";
import ViewLibraryUser from "./components/libraries/users/viewUser";
import UploadLibraryUser from "./components/libraries/users/uploadUser";

import LibraryAuthComponent from "./components/libraries/auth/authComponent";
import AddLibraryAuth from "./components/libraries/auth/addAuth";
import EditLibraryAuth from "./components/libraries/auth/editAuth";
import ViewLibraryAuth from "./components/libraries/auth/viewAuth";

import LicenseComponent from "./components/license/licenseComponent";
import AddLicense from "./components/license/addLicense";
import EditLicense from "./components/license/editLicense";
import ViewLicense from "./components/license/viewLicense";

import ResourceAccessComponent from "./components/resource-access/resourceAccessComponent";
import UserActivityComponent from "./components/user-activity/userActivityComponent";

import ReportsComponent from "./components/reports/reportsComponent";

import CustomEmailComponent from "./components/libraries/custom-emails/customEmailComponent";


//firebase Auth
function Root() {
  
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({
    pageLanguage: 'en',
    autoDisplay: false, layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
    }
    useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    }, [])

  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user ? user.Role : null;
  useEffect(() => {
    const layout = localStorage.getItem("layout_version");
    document.body.classList.add(layout);
  }, []);
  return (
   
    // <div id="google_translate_element">
     
    <div className="App">
     
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={login}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                component={login}
              />
               {/* <div id="google_translate_element"> */}
              <Fragment>
                <App>
                  {/* dashboard menu */}
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={Default}
                  />
                  {/* <Route path={`${process.env.PUBLIC_URL}/dashboard/ecommerce`} component={Ecommerce} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/university`} component={University} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/crypto`} component={CryptoComponent} />
                                    <Route path={`${process.env.PUBLIC_URL}/dashboard/project`} component={Project} /> */}

                  {/* Role */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/roles`}
                    component={DataTableComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/role/edit/:id`}
                    component={EditRole}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/role/create`}
                    component={AddRole}
                  />

                  {/* Organization */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/organizations`}
                    component={OrganizationComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/organization/edit/:id`}
                    component={EditOrganization}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/organization/view/:id`}
                    component={ViewOrganization}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/organization/create`}
                    component={AddOrganization}
                  />

                  {/* User */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/users`}
                    component={UserComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/user/create`}
                    component={AddUser}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/user/edit/:id`}
                    component={EditUser}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/user/view/:id`}
                    component={ViewUser}
                  />

                  {/* Master Resource */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/master-resources`}
                    component={MasterResourceComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/master-resource/create`}
                    component={AddMasterResource}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/master-resource/view/:id`}
                    component={ViewMasterResource}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/master-resource/edit/:id`}
                    component={EditMasterResource}
                  />

                  {/* Libraries */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/libraries`}
                    component={LibrariesComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/create`}
                    component={AddLibrary}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/edit/:id`}
                    component={EditLibraries}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/files/:id/:isUserManual`}
                    component={LibraryFiles}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/view/:id`}
                    component={ViewLibrary}
                  />

                  {/* Group */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/groups`}
                    component={GroupComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/group/edit/:id`}
                    component={EditGroup}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/group/view/:id`}
                    component={ViewGroup}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/group/create`}
                    component={AddGroup}
                  />

                  {/* Resource Category */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource-categories`}
                    component={ResourceCategoryComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource-category/create`}
                    component={AddResourceCategory}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource-category/edit/:id`}
                    component={EditResourceCategory}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource-category/view/:id`}
                    component={ViewResourceCategory}
                  />

                  {/* Library Resources */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resources`}
                    component={ResourceComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource/create`}
                    component={AddResource}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource/edit/:id`}
                    component={EditResource}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/resource/view/:id`}
                    component={ViewResource}
                  />

                  {/* User */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/users`}
                    component={LibraryUserComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/user/create`}
                    component={AddLibraryUser}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/user/upload`}
                    component={UploadLibraryUser}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/user/edit/:id`}
                    component={EditLibraryUser}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/user/view/:id`}
                    component={ViewLibraryUser}
                  />

                  {/* new code Matin */}
                  {/* Register Users*/}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/register-users`}
                    component={RegisterUserComponent}
                  />

                  {/* Authentication */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/auths`}
                    component={LibraryAuthComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/auth/create`}
                    component={AddLibraryAuth}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/auth/edit/:id`}
                    component={EditLibraryAuth}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/library/:libid/auth/view/:id`}
                    component={ViewLibraryAuth}
                  />

                  {/* Licence */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/licenses`}
                    component={LicenseComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/license/create`}
                    component={AddLicense}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/license/edit/:id`}
                    component={EditLicense}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/license/view/:id`}
                    component={ViewLicense}
                  />

                  {/* Reporting */}
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/resource-access`}
                    component={ResourceAccessComponent}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/user-activity`}
                    component={UserActivityComponent}
                  /> */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/reports`}
                    component={ReportsComponent}
                  />

                      <Route
                        path={`${process.env.PUBLIC_URL}/library/:libid/custom-emails`}
                        component={CustomEmailComponent}
                      />

                  {/* Sample page */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/sample/samplepage`}
                    component={Samplepage}
                  />

                  {/* Pricing */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/support-ticket/supportTicket`}
                    component={SupportTicket}
                  />
                </App>
                
              </Fragment>
              {/* </div> */}
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    </div>
    // </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
