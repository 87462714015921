import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CountUp from "react-countup";
import {
  Users,
  Book,
  UserCheck,
  BookOpen,
  Download,
  Unlock,
  Send,
  LogIn,
} from "react-feather";
import { Link } from "react-router-dom";
import { Button, Collapse } from "reactstrap";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Datepicker from "../../common/datepicker";
var moment = require("moment");
class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveUsers: 0,
      liveUsersDetails: [],
      totalUsers: 0,
      //r new
      totalMobileUsers: 0,
      //old
      totalResources: 0,
      loginThisMonth: 0,
      resourceAccessThisMonth: 0,
      pdfDownloadThisMonth: 0,
      authenticationMethods: 0,
      license: null,
      filterlibrary: "",
      filteredlibrary: "",
      filterisOpen: false,
      filterloading: false,
      fromDate: new Date(moment().subtract(1, "year").format()),
      toDate: new Date(moment().format()),
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      libraries: [],
      nvUsers: 0
    };
  }

  getLibraries = () => {
    var url = new URL(`${API_BASE}libraries`),
      params = {};
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            libraries: res.data.docs.map((v) => ({
              value: v._id,
              label: v.UniversityName,
            })),
          });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    if (JSON.parse(localStorage.getItem("user")).Role.Name == "Library Admin") {
      this.setState({
        filterlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
        filteredlibrary: JSON.parse(localStorage.getItem("user")).Library._id,
      });
      this.getDashboardReports(
        JSON.parse(localStorage.getItem("user")).Library._id
      );
    } else {
      this.getLibraries();
      this.getDashboardReports();
    }
  }

  datePickerEventHandler = (event, picker) => {
    this.setState({
      fromDate: new Date(picker.startDate),
      toDate: new Date(picker.endDate),
    });
  };

  filterHandler = () => {
    this.getDashboardReports();
  };
  //new code
  getDashboardReports = (defaultLibrary = null) => {
    this.setState({
      resourceAccessLoading: true,
      resourceAccessLoaded: false,
      filterloading: true,
    });
    var url = new URL(`${API_BASE}reports/dashboard`),
      params = {};
    if (defaultLibrary) {
      this.setState({ filteredlibrary: defaultLibrary });
    } else {
      this.setState({ filteredlibrary: this.state.filterlibrary });
    }
    if (this.state.filterlibrary)
      params["library_id[]"] = this.state.filterlibrary;
    if (defaultLibrary) params["library_id[]"] = defaultLibrary;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.getNotVerifiedUser();
        this.setState({
          liveUsers: res.liveUsers,
          liveUsersDetails: res.liveUsersDetails,
          totalUsers: res.totalUsers,
          // r new
          totalMobileUsers: res.totalMobileUsers,
          // end
          totalResources: res.totalResources,
          loginThisMonth: res.loginThisMonth,
          resourceAccessThisMonth: res.resourceAccessThisMonth,
          pdfDownloadThisMonth: res.pdfDownloadThisMonth,
          authenticationMethods: res.authenticationMethods,
          license: res.license,
          filterloading: false,
        });
      })
      .catch((err) => {
        this.setState({
          filterloading: false,
        });

        // toast.error("No response from server!", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      });
  };
  //end code


  getNotVerifiedUser = (page = null, limit = 1000) => {
    var url = new URL(
      `${API_BASE}libraryUsers/getNotVerifiedUsers/${JSON.parse(localStorage.getItem("user")).Library._id}`
    ),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            nvUsers: res.totalDocs ? res.totalDocs : 0,
          });
          localStorage.setItem("nvUsers", this.state.nvUsers);
        }
      });
  };



  nFormatter = (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };

  formatDeviceType = (data) => {
    const { browser, device, os } = data;
    var deviceType = '';
    if (device) {
      deviceType += device.type + ':' + device.vendor + ":" + device.model + " ";
    } else if (browser) {
      deviceType += browser.name + ':' + browser.major + " ";
    }
    if (os) {
      deviceType += os.name + ':' + os.version
    }
    return deviceType.replace(/undefined/g, "");
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="false" title="Dashboard" />
        <div className="container-fluid">
          {(this.state.loggedInUser.Role.IsAdmin ||
            this.state.loggedInUser.Role.Name == "Organization Admin") && (
              <Fragment>
                <button
                  className="btn btn-pill btn-info btn-air-info btn-xs"
                  type="button"
                  onClick={() => {
                    this.setState({ filterisOpen: !this.state.filterisOpen });
                  }}
                >
                  {this.state.filterisOpen ? (
                    <span>
                      Hide filter <i className="fa fa-minus"></i>
                    </span>
                  ) : (
                    <span>
                      Show filter <i className="fa fa-plus"></i>
                    </span>
                  )}
                </button>
                <div className="clearfix mt-2"></div>
                <Collapse isOpen={this.state.filterisOpen}>
                  <div className="card p-3">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-building"></i>
                            </span>
                          </div>
                          <Select
                            isClearable
                            className="reports-1st-react-select-container"
                            options={this.state.libraries}
                            placeholder="All library"
                            onChange={(value) =>
                              this.setState({
                                filterlibrary: value ? value.value : null,
                              })
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-2">
                      <Datepicker
                        maxDate={moment()}
                        startDate={this.state.fromDate}
                        endDate={this.state.toDate}
                        onEvent={this.datePickerEventHandler}
                      >
                        <i className="fa fa-calendar "></i> Date range
                      </Datepicker>
                    </div> */}
                      <div className="col-md-4">
                        <Button
                          className="btn-block"
                          color="secondary"
                          disabled={this.state.filterloading}
                          onClick={this.filterHandler}
                        >
                          {this.state.filterloading ? (
                            <i className="fa fa-cog fa-spin"></i>
                          ) : (
                            <i className="fa fa-filter"></i>
                          )}
                        FILTER
                      </Button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <div className="clearfix"></div>
              </Fragment>
            )}
          <div className="row mt-4" >
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-success b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <UserCheck />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Active Users</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.liveUsers}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <UserCheck className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/library/${this.state.filteredlibrary}/users`
                    );
                  }
                }}
              >
                <div className="bg-secondary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Users </span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.totalUsers}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* r new */}

            {/* web users */}
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/library/${this.state.filteredlibrary}/users?isMobile=false`
                    );
                  }
                }}
              >
                <div className="bg-secondary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Web Users</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.totalUsers - this.state.totalMobileUsers}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* mobile users */}

            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/library/${this.state.filteredlibrary}/users?isMobile=true`
                    );
                  }
                }}
              >
                <div className="bg-secondary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Users />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Mobile Users</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.totalMobileUsers}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Users className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* end */}
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/library/${this.state.filteredlibrary}/resources`
                    );
                  }
                }}
              >
                <div className="bg-info b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Book />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Total Resources </span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.totalResources}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Book className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/reports?library_id[]=${this.state.filteredlibrary
                      }&from_date=${moment()
                        .startOf("month")
                        .format("M/D/YYYY, h:m:s A")}&to_date=${moment()
                          .endOf("month")
                          .format("M/D/YYYY, h:m:s A")}`
                    );
                  }
                }}
              >
                <div className="bg-warning b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <LogIn />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Login This Month </span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.loginThisMonth}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <LogIn className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/reports?library_id[]=${this.state.filteredlibrary
                      }&from_date=${moment()
                        .startOf("month")
                        .format("M/D/YYYY, h:m:s A")}&to_date=${moment()
                          .endOf("month")
                          .format("M/D/YYYY, h:m:s A")}`
                    );
                  }
                }}
              >
                <div className="bg-primary b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <BookOpen />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Resource Access This Month</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.resourceAccessThisMonth}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <BookOpen className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div
                className="card o-hidden"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    this.state.filterlibrary &&
                    this.state.filteredlibrary == this.state.filterlibrary
                  ) {
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/reports?library_id[]=${this.state.filteredlibrary
                      }&from_date=${moment()
                        .startOf("month")
                        .format("M/D/YYYY, h:m:s A")}&to_date=${moment()
                          .endOf("month")
                          .format("M/D/YYYY, h:m:s A")}`
                    );
                  }
                }}
              >
                <div className="bg-info b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Download />
                    </div>
                    <div className="media-body">
                      <span className="m-0">PDF Download This Month</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.pdfDownloadThisMonth}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Download className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <div className="card o-hidden">
                <div className="bg-danger b-r-4 card-body">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Unlock />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Authentication Methods</span>
                      <h4 className="mb-0 counter">
                        <CountUp
                          className="counter"
                          end={this.state.authenticationMethods}
                          formattingFn={(value) => this.nFormatter(value, 0)}
                        />
                      </h4>
                      <Unlock className="icon-bg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(this.state.loggedInUser.Role.IsAdmin ||
              this.state.loggedInUser.Role.Name == "Organization Admin") &&
              this.state.license && (
                <div className="col-sm-12 col-xl-6">
                  <div className="card shadow-0 border">
                    <div className="card-header">
                      <h5>
                        License &nbsp;&nbsp;
                        {/* <Link
                          to={`${process.env.PUBLIC_URL}/dashboard`}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <i className="fa fa-eye"></i>
                        </Link> */}
                      </h5>
                    </div>
                    <div className="card-body">
                      <ul style={{ width: "285px", marginTop: "-30px" }}>
                        <li>
                          <b>Start: </b>
                          <code>
                            {moment(this.state.license.start).format(
                              "D MMM YYYY"
                            )}
                          </code>
                        </li>
                        <li>
                          <b>End: </b>
                          <code>
                            {moment(this.state.license.end)
                              .add(1, "month")
                              .format("D MMM YYYY")}
                          </code>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5>Active Users</h5>
                </div>
                <div >
                  <div className="table-responsive shopping-table text-center">
                    <table className="table table-bordernone">
                      <thead>
                        <tr>
                          <th scope="col">UserName</th>
                          <th scope="col">IP</th>
                          <th scope="col">AuthType</th>
                          <th scope="col">DeviceType</th>
                          <th scope="col">TimeStamp</th>
                          <th scope="col">City</th>
                          <th scope="col">Region</th>
                          <th scope="col">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.liveUsersDetails.map(user => (
                          <tr>
                            <td>{user.loginLog.UserName}</td>
                            <td>{user.loginLog.IP}</td>
                            <td>{user.loginLog.AuthType}</td>
                            <td>{this.formatDeviceType(user.loginLog.DeviceType)}</td>
                            <td>{moment(user.loginLog.TimeStamp).format("D-MM-YYYY h:mm:ss a")}</td>
                            <td>{user.loginLog.locationDetails.city}</td>
                            <td>{user.loginLog.locationDetails.region_name}</td>
                            <td>{user.loginLog.locationDetails.country_name}</td>
                          </tr>
                        ))}
                        {this.state.liveUsersDetails.length == 0 && <tr><td colSpan="8">No Live Users Available</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Default;