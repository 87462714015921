import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button } from "reactstrap";
import { API_BASE } from "../../../constant/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./resource.scss";
import { Alert } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
var moment = require("moment");

class ResourceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      resources: [],
      pagination: {},
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      filter: {
        name: "",
        id: "",
        code: "",
        created_by: "",
        sort: null,
        loading: false,
      },
      library: {
        UniversityName: "Fetching...",
      },
      delete: {
        id: null,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }
  getResources = (page = null, limit = null) => {
    var url = new URL(`${API_BASE}resources`),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = Object.fromEntries(urlParams);
    for (var i in search) {
      const searchables = ["id", "name", "created_by"];
      if (searchables.includes(i)) {
        var filter = this.state.filter;
        filter[i] = search[i];
        params[i] = search[i];
        this.setState({ filter });
      }
    }
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["library_id"] = this.props.match.params.libid;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            resources: res.docs,
            pagination: {
              totalPages: res.totalPages,
              pagingCounter: res.pagingCounter,
              totalDocs: res.totalDocs,
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };
  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getResources(null, 25);
    this.getLibrary(this.props.match.params.libid);
  }

  deleteHandler = (_id) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteResource(_id)}
        >
          You will not be able to recover this Resource!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteResource = (id) => {
    this.setState({
      alert: null,
      delete: {
        id,
      },
    });
    var url = new URL(
      `${API_BASE}resources/${id}/${this.props.match.params.libid}`
    );
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            delete: {
              id: null,
            },
          });
          this.getResources(this.state.pagination.page, 25);
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            delete: {
              id: null,
            },
          });
        }
      });
  };

  getPaginationPills = () => {
    var pills = [];
    if (this.state.pagination.hasPrevPage) {
      pills.push(this.state.pagination.prevPage);
    }
    pills.push(this.state.pagination.page);
    if (this.state.pagination.hasNextPage) {
      pills.push(this.state.pagination.nextPage);
    }

    while (pills.length < 3) {
      if (
        this.state.pagination.prevPage &&
        this.state.pagination.prevPage - 1 > 0
      ) {
        pills = [this.state.pagination.prevPage - 1, ...pills];
        continue;
      }
      if (
        this.state.pagination.nextPage &&
        this.state.pagination.nextPage + 1 <
          this.state.pagination.totalPages + 1
      ) {
        pills = [...pills, this.state.pagination.nextPage + 1];
        continue;
      }
      break;
    }
    return pills;
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    this.setState({ filter });
  };

  filterHandler = () => {
    var url = new URL(`${API_BASE}resources`),
      params = {};
    if (this.state.filter.name) params["name"] = this.state.filter.name;
    if (this.state.filter.id) params["id"] = this.state.filter.id;
    if (this.state.filter.code) params["code"] = this.state.filter.code;
    if (this.state.filter.sort) params["sort"] = this.state.filter.sort;
    if (this.state.filter.created_by)
      params["created_by"] = this.state.filter.created_by;
    if (this.state.filter.code) params["code"] = this.state.filter.code;
    params["library_id"] = this.props.match.params.libid;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.props.history.push({
      search: url.search
        .replace("pagination=off&", "")
        .replace("pagination=off", "")
        .replace(`library_id=${this.props.match.params.libid}&`, "")
        .replace(`library_id=${this.props.match.params.libid}`, ""),
    });
    this.filterInputHandler("loading", true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        if (res.success) {
          this.setState({
            resources: res.docs,
            pagination: {
              totalPages: res.totalPages,
              pagingCounter: res.pagingCounter,
              totalDocs: res.totalDocs,
              hasPrevPage: res.hasPrevPage,
              hasNextPage: res.hasNextPage,
              page: res.page,
              prevPage: res.prevPage,
              nextPage: res.nextPage,
            },
          });
        }
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Resources`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                    "Organization Admin") && (
                  <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                    Back to Libraries
                  </Link>
                )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Resources`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? this.state.library.UniversityName
                : "false"
            }
            parentLink={`${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title={`Resources`}
          parent={
            this.state.loggedInUser.Role.Name == "Master Admin" ||
            this.state.loggedInUser.Role.Name == "Organization Admin"
              ? this.state.library.UniversityName
              : "false"
          }
          parentLink={`${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`}
        />
        <div className="container-fluid">
          {this.state.alert}
          <div className="row">
            <div className="col-md-12">
              <Button
                className="mb-3"
                style={{ float: "right", marginTop: -60 }}
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource/create`
                  )
                }
              >
                <i className="fa fa-plus"></i> ADD
              </Button>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="card pt-4">
            <div className="row pb-4 pr-5" style={{ paddingLeft: "5rem" }}>
              <div className="col-md-5">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Resource Code"
                    value={this.state.filter.code}
                    onChange={(e) =>
                      this.filterInputHandler("code", e.target.value)
                    }
                    onKeyPress={(event) => {
                      var code = event.keyCode || event.which;
                      if (code === 13) {
                        this.filterHandler();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Resource Name"
                    value={this.state.filter.name}
                    onChange={(e) =>
                      this.filterInputHandler("name", e.target.value)
                    }
                    onKeyPress={(event) => {
                      var code = event.keyCode || event.which;
                      if (code === 13) {
                        this.filterHandler();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5 mt-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Created User"
                    value={this.state.filter.created_by}
                    onChange={(e) =>
                      this.filterInputHandler("created_by", e.target.value)
                    }
                    onKeyPress={(event) => {
                      var code = event.keyCode || event.which;
                      if (code === 13) {
                        this.filterHandler();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-5 mt-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <select
                    className="form-control"
                    onChange={(e) =>
                      this.filterInputHandler("sort", e.target.value)
                    }
                  >
                    <option value="">A - Z</option>
                    <option value="-1">Z - A</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2 mt-3">
                <Button
                  style={{ textAlign: "center" }}
                  color="secondary"
                  disabled={this.state.filter.loading}
                  onClick={this.filterHandler}
                  className="btn-block"
                >
                  {this.state.filter.loading ? (
                    <i className="fa fa-cog fa-spin"></i>
                  ) : (
                    <i className="fa fa-filter"></i>
                  )}{" "}
                  FILTER
                </Button>
              </div>
            </div>
          </div>
          {this.state.resources.length == 0 && (
            <div className="dark alert alert-danger fade show" role="alert">
              No records found!
            </div>
          )}
          {this.state.resources.length > 0 && (
            <Fragment>
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <p>
                    <strong>
                      Total records: {this.state.pagination.totalDocs}
                    </strong>
                  </p>
                  <div className="row">
                    {this.state.resources.map((resource, i) => {
                      return (
                        <div className="col-1-5" key={i}>
                          <div className="card">
                            <div className="col-1-5-box blog-box blog-grid text-center product-box">
                              <div className="product-img">
                                <img
                                  className="img-fluid top-radius-blog"
                                  src={require("../../../assets/images/ebook.png")}
                                  alt=""
                                  style={{ width: "80%" }}
                                />
                                <div className="product-hover">
                                  <ul>
                                    <li
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#6883f4",
                                      }}
                                      onClick={() =>
                                        this.props.history.push(
                                          `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource/view/${resource._id}/${this.props.location.search}`
                                        )
                                      }
                                    >
                                      <i className="fa fa-eye"></i>
                                    </li>
                                    <li
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#65c2f2",
                                      }}
                                      onClick={() =>
                                        this.props.history.push(
                                          `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource/edit/${resource._id}`
                                        )
                                      }
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </li>
                                    <li
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#f274a0",
                                      }}
                                      onClick={() =>
                                        this.deleteHandler(resource._id)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="blog-details-main">
                                <div className="col-fix-50">
                                  <p>
                                    {moment(resource.CreatedOn).format(
                                      "D MMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="col-fix-50">
                                  <p>By: {resource.CreatedBy.DisplayName}</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="clearfix"></div>
                                <div className="">
                                  <h6 className="blog-bottom-details max-lines">
                                    {resource.Name}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            {this.state.delete.id == resource._id && (
                              <div
                                className="loader-box"
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  right: 0,
                                }}
                              >
                                <div
                                  className="loader"
                                  style={{ width: "100%", marginTop: "35%" }}
                                >
                                  <div
                                    className="line bg-danger"
                                    style={{ width: 2, height: 30 }}
                                  ></div>
                                  <div
                                    className="line bg-danger"
                                    style={{ width: 2, height: 30 }}
                                  ></div>
                                  <div
                                    className="line bg-danger"
                                    style={{ width: 2, height: 30 }}
                                  ></div>
                                  <div
                                    className="line bg-danger"
                                    style={{ width: 2, height: 30 }}
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row mb-5 mt-3" style={styles.pagination}>
                <div
                  className="col-xl-4 col-md-6 col-sm-12 button-group-mb-sm"
                  style={{ textAlign: "center" }}
                >
                  <div
                    className="btn-group btn-group-pill"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-primary"
                    >
                      <PaginationItem
                        disabled={this.state.pagination.page == 1}
                      >
                        <PaginationLink
                          first
                          onClick={() => this.getResources(1, 25)}
                        />
                      </PaginationItem>
                      <PaginationItem
                        disabled={!this.state.pagination.hasPrevPage}
                      >
                        <PaginationLink
                          previous
                          onClick={() =>
                            this.getResources(
                              this.state.pagination.prevPage,
                              25
                            )
                          }
                        />
                      </PaginationItem>
                      {this.getPaginationPills().map((v, i) => (
                        <PaginationItem
                          key={v}
                          active={v == this.state.pagination.page}
                        >
                          <PaginationLink
                            onClick={() => this.getResources(v, 25)}
                          >
                            {v}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        disabled={!this.state.pagination.hasNextPage}
                      >
                        <PaginationLink
                          next
                          onClick={() =>
                            this.getResources(
                              this.state.pagination.nextPage,
                              25
                            )
                          }
                        />
                      </PaginationItem>
                      <PaginationItem
                        disabled={
                          this.state.pagination.page ==
                          this.state.pagination.totalPages
                        }
                      >
                        <PaginationLink
                          last
                          onClick={() =>
                            this.getResources(
                              this.state.pagination.totalPages,
                              25
                            )
                          }
                        />
                      </PaginationItem>
                    </Pagination>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ResourceComponent;
