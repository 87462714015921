export const CountryCodeList = [
  {
    "CountryCode": "AF",
    "PhoneCode": 93,
    "CountryName": "Afghanistan"
  },
  {
    "CountryCode": "AX",
    "PhoneCode": "+358-18",
    "CountryName": "Aland Islands"
  },
  {
    "CountryCode": "AL",
    "PhoneCode": 355,
    "CountryName": "Albania"
  },
  {
    "CountryCode": "DZ",
    "PhoneCode": 213,
    "CountryName": "Algeria"
  },
  {
    "CountryCode": "AS",
    "PhoneCode": "+1-684",
    "CountryName": "American Samoa"
  },
  {
    "CountryCode": "AD",
    "PhoneCode": 376,
    "CountryName": "Andorra"
  },
  {
    "CountryCode": "AO",
    "PhoneCode": 244,
    "CountryName": "Angola"
  },
  {
    "CountryCode": "AI",
    "PhoneCode": "+1-264",
    "CountryName": "Anguilla"
  },
  {
    "CountryCode": "AG",
    "PhoneCode": "+1-268",
    "CountryName": "Antigua and Barbuda"
  },
  {
    "CountryCode": "AR",
    "PhoneCode": 54,
    "CountryName": "Argentina"
  },
  {
    "CountryCode": "AM",
    "PhoneCode": 374,
    "CountryName": "Armenia"
  },
  {
    "CountryCode": "AW",
    "PhoneCode": 297,
    "CountryName": "Aruba"
  },
  {
    "CountryCode": "AU",
    "PhoneCode": 61,
    "CountryName": "Australia"
  },
  {
    "CountryCode": "AT",
    "PhoneCode": 43,
    "CountryName": "Austria"
  },
  {
    "CountryCode": "AZ",
    "PhoneCode": 994,
    "CountryName": "Azerbaijan"
  },
  {
    "CountryCode": "BG",
    "PhoneCode": 359,
    "CountryName": "B\\nulgaria"
  },
  {
    "CountryCode": "BS",
    "PhoneCode": "+1-242",
    "CountryName": "Bahamas"
  },
  {
    "CountryCode": "BH",
    "PhoneCode": 973,
    "CountryName": "Bahrain"
  },
  {
    "CountryCode": "BD",
    "PhoneCode": 880,
    "CountryName": "Bangladesh"
  },
  {
    "CountryCode": "BB",
    "PhoneCode": "+1-246",
    "CountryName": "Barbados"
  },
  {
    "CountryCode": "BY",
    "PhoneCode": 375,
    "CountryName": "Belarus"
  },
  {
    "CountryCode": "BE",
    "PhoneCode": 32,
    "CountryName": "Belgium"
  },
  {
    "CountryCode": "BZ",
    "PhoneCode": 501,
    "CountryName": "Belize"
  },
  {
    "CountryCode": "BJ",
    "PhoneCode": 229,
    "CountryName": "Benin"
  },
  {
    "CountryCode": "BM",
    "PhoneCode": "+1-441",
    "CountryName": "Bermuda"
  },
  {
    "CountryCode": "BT",
    "PhoneCode": 975,
    "CountryName": "Bhutan"
  },
  {
    "CountryCode": "BO",
    "PhoneCode": 591,
    "CountryName": "Bolivia"
  },
  {
    "CountryCode": "BQ",
    "PhoneCode": 599,
    "CountryName": "Bonaire, Saint Eustatius and Saba"
  },
  {
    "CountryCode": "BA",
    "PhoneCode": 387,
    "CountryName": "Bosnia and Herzegovina"
  },
  {
    "CountryCode": "BW",
    "PhoneCode": 267,
    "CountryName": "Botswana"
  },
  {
    "CountryCode": "BR",
    "PhoneCode": 55,
    "CountryName": "Brazil"
  },
  {
    "CountryCode": "IO",
    "PhoneCode": 246,
    "CountryName": "British Indian Ocean Territory"
  },
  {
    "CountryCode": "VG",
    "PhoneCode": "+1-284",
    "CountryName": "British Virgin Islands"
  },
  {
    "CountryCode": "BN",
    "PhoneCode": 673,
    "CountryName": "Brunei"
  },
  {
    "CountryCode": "BF",
    "PhoneCode": 226,
    "CountryName": "Burkina Faso"
  },
  {
    "CountryCode": "BI",
    "PhoneCode": 257,
    "CountryName": "Burundi"
  },
  {
    "CountryCode": "KH",
    "PhoneCode": 855,
    "CountryName": "Cambodia"
  },
  {
    "CountryCode": "CM",
    "PhoneCode": 237,
    "CountryName": "Cameroon"
  },
  {
    "CountryCode": "CA",
    "PhoneCode": 1,
    "CountryName": "Canada"
  },
  {
    "CountryCode": "CV",
    "PhoneCode": 238,
    "CountryName": "Cape Verde"
  },
  {
    "CountryCode": "KY",
    "PhoneCode": "+1-345",
    "CountryName": "Cayman Islands"
  },
  {
    "CountryCode": "CF",
    "PhoneCode": 236,
    "CountryName": "Central African Republic"
  },
  {
    "CountryCode": "TD",
    "PhoneCode": 235,
    "CountryName": "Chad"
  },
  {
    "CountryCode": "CL",
    "PhoneCode": 56,
    "CountryName": "Chile"
  },
  {
    "CountryCode": "CN",
    "PhoneCode": 86,
    "CountryName": "China"
  },
  {
    "CountryCode": "CX",
    "PhoneCode": 61,
    "CountryName": "Christmas Island"
  },
  {
    "CountryCode": "CC",
    "PhoneCode": 61,
    "CountryName": "Cocos Islands"
  },
  {
    "CountryCode": "CO",
    "PhoneCode": 57,
    "CountryName": "Colombia"
  },
  {
    "CountryCode": "KM",
    "PhoneCode": 269,
    "CountryName": "Comoros"
  },
  {
    "CountryCode": "CK",
    "PhoneCode": 682,
    "CountryName": "Cook Islands"
  },
  {
    "CountryCode": "CR",
    "PhoneCode": 506,
    "CountryName": "Costa Rica"
  },
  {
    "CountryCode": "HR",
    "PhoneCode": 385,
    "CountryName": "Croatia"
  },
  {
    "CountryCode": "CU",
    "PhoneCode": 53,
    "CountryName": "Cuba"
  },
  {
    "CountryCode": "CW",
    "PhoneCode": 599,
    "CountryName": "Curacao"
  },
  {
    "CountryCode": "CY",
    "PhoneCode": 357,
    "CountryName": "Cyprus"
  },
  {
    "CountryCode": "CZ",
    "PhoneCode": 420,
    "CountryName": "Czech Republic"
  },
  {
    "CountryCode": "CD",
    "PhoneCode": 243,
    "CountryName": "Democratic Republic of the Congo"
  },
  {
    "CountryCode": "DK",
    "PhoneCode": 45,
    "CountryName": "Denmark"
  },
  {
    "CountryCode": "DJ",
    "PhoneCode": 253,
    "CountryName": "Djibouti"
  },
  {
    "CountryCode": "DM",
    "PhoneCode": "+1-767",
    "CountryName": "Dominica"
  },
  {
    "CountryCode": "DO",
    "PhoneCode": "+1-809",
    "CountryName": "Dominican Republic"
  },
  {
    "CountryCode": "TL",
    "PhoneCode": 670,
    "CountryName": "East Timor"
  },
  {
    "CountryCode": "EC",
    "PhoneCode": 593,
    "CountryName": "Ecuador"
  },
  {
    "CountryCode": "EG",
    "PhoneCode": 20,
    "CountryName": "Egypt"
  },
  {
    "CountryCode": "SV",
    "PhoneCode": 503,
    "CountryName": "El Salvador"
  },
  {
    "CountryCode": "GQ",
    "PhoneCode": 240,
    "CountryName": "Equatorial Guinea"
  },
  {
    "CountryCode": "ER",
    "PhoneCode": 291,
    "CountryName": "Eritrea"
  },
  {
    "CountryCode": "EE",
    "PhoneCode": 372,
    "CountryName": "Estonia"
  },
  {
    "CountryCode": "ET",
    "PhoneCode": 251,
    "CountryName": "Ethiopia"
  },
  {
    "CountryCode": "FK",
    "PhoneCode": 500,
    "CountryName": "Falkland Islands"
  },
  {
    "CountryCode": "FO",
    "PhoneCode": 298,
    "CountryName": "Faroe Islands"
  },
  {
    "CountryCode": "FJ",
    "PhoneCode": 679,
    "CountryName": "Fiji"
  },
  {
    "CountryCode": "FI",
    "PhoneCode": 358,
    "CountryName": "Finland"
  },
  {
    "CountryCode": "FR",
    "PhoneCode": 33,
    "CountryName": "France"
  },
  {
    "CountryCode": "GF",
    "PhoneCode": 594,
    "CountryName": "French Guiana"
  },
  {
    "CountryCode": "PF",
    "PhoneCode": 689,
    "CountryName": "French Polynesia"
  },
  {
    "CountryCode": "GA",
    "PhoneCode": 241,
    "CountryName": "Gabon"
  },
  {
    "CountryCode": "GM",
    "PhoneCode": 220,
    "CountryName": "Gambia"
  },
  {
    "CountryCode": "GE",
    "PhoneCode": 995,
    "CountryName": "Georgia"
  },
  {
    "CountryCode": "DE",
    "PhoneCode": 49,
    "CountryName": "Germany"
  },
  {
    "CountryCode": "GH",
    "PhoneCode": 233,
    "CountryName": "Ghana"
  },
  {
    "CountryCode": "GI",
    "PhoneCode": 350,
    "CountryName": "Gibraltar"
  },
  {
    "CountryCode": "GR",
    "PhoneCode": 30,
    "CountryName": "Greece"
  },
  {
    "CountryCode": "GL",
    "PhoneCode": 299,
    "CountryName": "Greenland"
  },
  {
    "CountryCode": "GD",
    "PhoneCode": "+1-473",
    "CountryName": "Grenada"
  },
  {
    "CountryCode": "GP",
    "PhoneCode": 590,
    "CountryName": "Guadeloupe"
  },
  {
    "CountryCode": "GU",
    "PhoneCode": "+1-671",
    "CountryName": "Guam"
  },
  {
    "CountryCode": "GT",
    "PhoneCode": 502,
    "CountryName": "Guatemala"
  },
  {
    "CountryCode": "GG",
    "PhoneCode": "+44-1481",
    "CountryName": "Guernsey"
  },
  {
    "CountryCode": "GN",
    "PhoneCode": 224,
    "CountryName": "Guinea"
  },
  {
    "CountryCode": "GW",
    "PhoneCode": 245,
    "CountryName": "Guinea-Bissau"
  },
  {
    "CountryCode": "GY",
    "PhoneCode": 592,
    "CountryName": "Guyana"
  },
  {
    "CountryCode": "HT",
    "PhoneCode": 509,
    "CountryName": "Haiti"
  },
  {
    "CountryCode": "HN",
    "PhoneCode": 504,
    "CountryName": "Honduras"
  },
  {
    "CountryCode": "HK",
    "PhoneCode": 852,
    "CountryName": "Hong Kong"
  },
  {
    "CountryCode": "HU",
    "PhoneCode": 36,
    "CountryName": "Hungary"
  },
  {
    "CountryCode": "IS",
    "PhoneCode": 354,
    "CountryName": "Iceland"
  },
  {
    "CountryCode": "IN",
    "PhoneCode": 91,
    "CountryName": "India"
  },
  {
    "CountryCode": "ID",
    "PhoneCode": 62,
    "CountryName": "Indonesia"
  },
  {
    "CountryCode": "IR",
    "PhoneCode": 98,
    "CountryName": "Iran"
  },
  {
    "CountryCode": "IQ",
    "PhoneCode": 964,
    "CountryName": "Iraq"
  },
  {
    "CountryCode": "IE",
    "PhoneCode": 353,
    "CountryName": "Ireland"
  },
  {
    "CountryCode": "IM",
    "PhoneCode": "+44-1624",
    "CountryName": "Isle of Man"
  },
  {
    "CountryCode": "IL",
    "PhoneCode": 972,
    "CountryName": "Israel"
  },
  {
    "CountryCode": "IT",
    "PhoneCode": 39,
    "CountryName": "Italy"
  },
  {
    "CountryCode": "CI",
    "PhoneCode": 225,
    "CountryName": "Ivory Coast"
  },
  {
    "CountryCode": "JM",
    "PhoneCode": "+1-876",
    "CountryName": "Jamaica"
  },
  {
    "CountryCode": "JP",
    "PhoneCode": 81,
    "CountryName": "Japan"
  },
  {
    "CountryCode": "JE",
    "PhoneCode": "+44-1534",
    "CountryName": "Jersey"
  },
  {
    "CountryCode": "JO",
    "PhoneCode": 962,
    "CountryName": "Jordan"
  },
  {
    "CountryCode": "KZ",
    "PhoneCode": 7,
    "CountryName": "Kazakhstan"
  },
  {
    "CountryCode": "KE",
    "PhoneCode": 254,
    "CountryName": "Kenya"
  },
  {
    "CountryCode": "KI",
    "PhoneCode": 686,
    "CountryName": "Kiribati"
  },
  {
    "CountryCode": "KW",
    "PhoneCode": 965,
    "CountryName": "Kuwait"
  },
  {
    "CountryCode": "KG",
    "PhoneCode": 996,
    "CountryName": "Kyrgyzstan"
  },
  {
    "CountryCode": "LA",
    "PhoneCode": 856,
    "CountryName": "Laos"
  },
  {
    "CountryCode": "LV",
    "PhoneCode": 371,
    "CountryName": "Latvia"
  },
  {
    "CountryCode": "LB",
    "PhoneCode": 961,
    "CountryName": "Lebanon"
  },
  {
    "CountryCode": "LS",
    "PhoneCode": 266,
    "CountryName": "Lesotho"
  },
  {
    "CountryCode": "LR",
    "PhoneCode": 231,
    "CountryName": "Liberia"
  },
  {
    "CountryCode": "LY",
    "PhoneCode": 218,
    "CountryName": "Libya"
  },
  {
    "CountryCode": "LI",
    "PhoneCode": 423,
    "CountryName": "Liechtenstein"
  },
  {
    "CountryCode": "LT",
    "PhoneCode": 370,
    "CountryName": "Lithuania"
  },
  {
    "CountryCode": "LU",
    "PhoneCode": 352,
    "CountryName": "Luxembourg"
  },
  {
    "CountryCode": "MO",
    "PhoneCode": 853,
    "CountryName": "Macao"
  },
  {
    "CountryCode": "MK",
    "PhoneCode": 389,
    "CountryName": "Macedonia"
  },
  {
    "CountryCode": "MG",
    "PhoneCode": 261,
    "CountryName": "Madagascar"
  },
  {
    "CountryCode": "MW",
    "PhoneCode": 265,
    "CountryName": "Malawi"
  },
  {
    "CountryCode": "MY",
    "PhoneCode": 60,
    "CountryName": "Malaysia"
  },
  {
    "CountryCode": "MV",
    "PhoneCode": 960,
    "CountryName": "Maldives"
  },
  {
    "CountryCode": "ML",
    "PhoneCode": 223,
    "CountryName": "Mali"
  },
  {
    "CountryCode": "MT",
    "PhoneCode": 356,
    "CountryName": "Malta"
  },
  {
    "CountryCode": "MH",
    "PhoneCode": 692,
    "CountryName": "Marshall Islands"
  },
  {
    "CountryCode": "MQ",
    "PhoneCode": 596,
    "CountryName": "Martinique"
  },
  {
    "CountryCode": "MR",
    "PhoneCode": 222,
    "CountryName": "Mauritania"
  },
  {
    "CountryCode": "MU",
    "PhoneCode": 230,
    "CountryName": "Mauritius"
  },
  {
    "CountryCode": "YT",
    "PhoneCode": 262,
    "CountryName": "Mayotte"
  },
  {
    "CountryCode": "MX",
    "PhoneCode": 52,
    "CountryName": "Mexico"
  },
  {
    "CountryCode": "FM",
    "PhoneCode": 691,
    "CountryName": "Micronesia"
  },
  {
    "CountryCode": "MD",
    "PhoneCode": 373,
    "CountryName": "Moldova"
  },
  {
    "CountryCode": "MC",
    "PhoneCode": 377,
    "CountryName": "Monaco"
  },
  {
    "CountryCode": "MN",
    "PhoneCode": 976,
    "CountryName": "Mongolia"
  },
  {
    "CountryCode": "ME",
    "PhoneCode": 382,
    "CountryName": "Montenegro"
  },
  {
    "CountryCode": "MS",
    "PhoneCode": "+1-664",
    "CountryName": "Montserrat"
  },
  {
    "CountryCode": "MA",
    "PhoneCode": 212,
    "CountryName": "Morocco"
  },
  {
    "CountryCode": "MZ",
    "PhoneCode": 258,
    "CountryName": "Mozambique"
  },
  {
    "CountryCode": "MM",
    "PhoneCode": 95,
    "CountryName": "Myanmar"
  },
  {
    "CountryCode": "NA",
    "PhoneCode": 264,
    "CountryName": "Namibia"
  },
  {
    "CountryCode": "NR",
    "PhoneCode": 674,
    "CountryName": "Nauru"
  },
  {
    "CountryCode": "NP",
    "PhoneCode": 977,
    "CountryName": "Nepal"
  },
  {
    "CountryCode": "NL",
    "PhoneCode": 31,
    "CountryName": "Netherlands"
  },
  {
    "CountryCode": "NC",
    "PhoneCode": 687,
    "CountryName": "New Caledonia"
  },
  {
    "CountryCode": "NZ",
    "PhoneCode": 64,
    "CountryName": "New Zealand"
  },
  {
    "CountryCode": "NI",
    "PhoneCode": 505,
    "CountryName": "Nicaragua"
  },
  {
    "CountryCode": "NE",
    "PhoneCode": 227,
    "CountryName": "Niger"
  },
  {
    "CountryCode": "NG",
    "PhoneCode": 234,
    "CountryName": "Nigeria"
  },
  {
    "CountryCode": "NU",
    "PhoneCode": 683,
    "CountryName": "Niue"
  },
  {
    "CountryCode": "NF",
    "PhoneCode": 672,
    "CountryName": "Norfolk Island"
  },
  {
    "CountryCode": "KP",
    "PhoneCode": 850,
    "CountryName": "North Korea"
  },
  {
    "CountryCode": "MP",
    "PhoneCode": "+1-670",
    "CountryName": "Northern Mariana Islands"
  },
  {
    "CountryCode": "NO",
    "PhoneCode": 47,
    "CountryName": "Norway"
  },
  {
    "CountryCode": "OM",
    "PhoneCode": 968,
    "CountryName": "Oman"
  },
  {
    "CountryCode": "PK",
    "PhoneCode": 92,
    "CountryName": "Pakistan"
  },
  {
    "CountryCode": "PW",
    "PhoneCode": 680,
    "CountryName": "Palau"
  },
  {
    "CountryCode": "PS",
    "PhoneCode": 970,
    "CountryName": "Palestinian Territory"
  },
  {
    "CountryCode": "PA",
    "PhoneCode": 507,
    "CountryName": "Panama"
  },
  {
    "CountryCode": "PG",
    "PhoneCode": 675,
    "CountryName": "Papua New Guinea"
  },
  {
    "CountryCode": "PY",
    "PhoneCode": 595,
    "CountryName": "Paraguay"
  },
  {
    "CountryCode": "PE",
    "PhoneCode": 51,
    "CountryName": "Peru"
  },
  {
    "CountryCode": "PH",
    "PhoneCode": 63,
    "CountryName": "Philippines"
  },
  {
    "CountryCode": "PN",
    "PhoneCode": 870,
    "CountryName": "Pitcairn"
  },
  {
    "CountryCode": "PL",
    "PhoneCode": 48,
    "CountryName": "Poland"
  },
  {
    "CountryCode": "PT",
    "PhoneCode": 351,
    "CountryName": "Portugal"
  },
  {
    "CountryCode": "PR",
    "PhoneCode": "+1-787",
    "CountryName": "Puerto Rico"
  },
  {
    "CountryCode": "QA",
    "PhoneCode": 974,
    "CountryName": "Qatar"
  },
  {
    "CountryCode": "CG",
    "PhoneCode": 242,
    "CountryName": "Republic of the Congo"
  },
  {
    "CountryCode": "RE",
    "PhoneCode": 262,
    "CountryName": "Reunion"
  },
  {
    "CountryCode": "RO",
    "PhoneCode": 40,
    "CountryName": "Romania"
  },
  {
    "CountryCode": "RU",
    "PhoneCode": 7,
    "CountryName": "Russia"
  },
  {
    "CountryCode": "RW",
    "PhoneCode": 250,
    "CountryName": "Rwanda"
  },
  {
    "CountryCode": "BL",
    "PhoneCode": 590,
    "CountryName": "Saint Barthelemy"
  },
  {
    "CountryCode": "SH",
    "PhoneCode": 290,
    "CountryName": "Saint Helena"
  },
  {
    "CountryCode": "KN",
    "PhoneCode": "+1-869",
    "CountryName": "Saint Kitts and Nevis"
  },
  {
    "CountryCode": "LC",
    "PhoneCode": "+1-758",
    "CountryName": "Saint Lucia"
  },
  {
    "CountryCode": "MF",
    "PhoneCode": 590,
    "CountryName": "Saint Martin"
  },
  {
    "CountryCode": "PM",
    "PhoneCode": 508,
    "CountryName": "Saint Pierre and Miquelon"
  },
  {
    "CountryCode": "VC",
    "PhoneCode": "+1-784",
    "CountryName": "Saint Vincent and the Grenadines"
  },
  {
    "CountryCode": "WS",
    "PhoneCode": 685,
    "CountryName": "Samoa"
  },
  {
    "CountryCode": "SM",
    "PhoneCode": 378,
    "CountryName": "San Marino"
  },
  {
    "CountryCode": "ST",
    "PhoneCode": 239,
    "CountryName": "Sao Tome and Principe"
  },
  {
    "CountryCode": "SA",
    "PhoneCode": 966,
    "CountryName": "Saudi Arabia"
  },
  {
    "CountryCode": "SN",
    "PhoneCode": 221,
    "CountryName": "Senegal"
  },
  {
    "CountryCode": "RS",
    "PhoneCode": 381,
    "CountryName": "Serbia"
  },
  {
    "CountryCode": "SC",
    "PhoneCode": 248,
    "CountryName": "Seychelles"
  },
  {
    "CountryCode": "SL",
    "PhoneCode": 232,
    "CountryName": "Sierra Leone"
  },
  {
    "CountryCode": "SG",
    "PhoneCode": 65,
    "CountryName": "Singapore"
  },
  {
    "CountryCode": "SX",
    "PhoneCode": 599,
    "CountryName": "Sint Maarten"
  },
  {
    "CountryCode": "SK",
    "PhoneCode": 421,
    "CountryName": "Slovakia"
  },
  {
    "CountryCode": "SI",
    "PhoneCode": 386,
    "CountryName": "Slovenia"
  },
  {
    "CountryCode": "SB",
    "PhoneCode": 677,
    "CountryName": "Solomon Islands"
  },
  {
    "CountryCode": "SO",
    "PhoneCode": 252,
    "CountryName": "Somalia"
  },
  {
    "CountryCode": "ZA",
    "PhoneCode": 27,
    "CountryName": "South Africa"
  },
  {
    "CountryCode": "KR",
    "PhoneCode": 82,
    "CountryName": "South Korea"
  },
  {
    "CountryCode": "SS",
    "PhoneCode": 211,
    "CountryName": "South Sudan"
  },
  {
    "CountryCode": "ES",
    "PhoneCode": 34,
    "CountryName": "Spain"
  },
  {
    "CountryCode": "LK",
    "PhoneCode": 94,
    "CountryName": "Sri Lanka"
  },
  {
    "CountryCode": "SD",
    "PhoneCode": 249,
    "CountryName": "Sudan"
  },
  {
    "CountryCode": "SR",
    "PhoneCode": 597,
    "CountryName": "Suriname"
  },
  {
    "CountryCode": "SJ",
    "PhoneCode": 47,
    "CountryName": "Svalbard and Jan Mayen"
  },
  {
    "CountryCode": "SZ",
    "PhoneCode": 268,
    "CountryName": "Swaziland"
  },
  {
    "CountryCode": "SE",
    "PhoneCode": 46,
    "CountryName": "Sweden"
  },
  {
    "CountryCode": "CH",
    "PhoneCode": 41,
    "CountryName": "Switzerland"
  },
  {
    "CountryCode": "SY",
    "PhoneCode": 963,
    "CountryName": "Syria"
  },
  {
    "CountryCode": "TW",
    "PhoneCode": 886,
    "CountryName": "Taiwan"
  },
  {
    "CountryCode": "TJ",
    "PhoneCode": 992,
    "CountryName": "Tajikistan"
  },
  {
    "CountryCode": "TZ",
    "PhoneCode": 255,
    "CountryName": "Tanzania"
  },
  {
    "CountryCode": "TH",
    "PhoneCode": 66,
    "CountryName": "Thailand"
  },
  {
    "CountryCode": "TG",
    "PhoneCode": 228,
    "CountryName": "Togo"
  },
  {
    "CountryCode": "TK",
    "PhoneCode": 690,
    "CountryName": "Tokelau"
  },
  {
    "CountryCode": "TO",
    "PhoneCode": 676,
    "CountryName": "Tonga"
  },
  {
    "CountryCode": "TT",
    "PhoneCode": "+1-868",
    "CountryName": "Trinidad and Tobago"
  },
  {
    "CountryCode": "TN",
    "PhoneCode": 216,
    "CountryName": "Tunisia"
  },
  {
    "CountryCode": "TR",
    "PhoneCode": 90,
    "CountryName": "Turkey"
  },
  {
    "CountryCode": "TM",
    "PhoneCode": 993,
    "CountryName": "Turkmenistan"
  },
  {
    "CountryCode": "TC",
    "PhoneCode": "+1-649",
    "CountryName": "Turks and Caicos Islands"
  },
  {
    "CountryCode": "TV",
    "PhoneCode": 688,
    "CountryName": "Tuvalu"
  },
  {
    "CountryCode": "VI",
    "PhoneCode": "+1-340",
    "CountryName": "U.S. Virgin Islands"
  },
  {
    "CountryCode": "UG",
    "PhoneCode": 256,
    "CountryName": "Uganda"
  },
  {
    "CountryCode": "UA",
    "PhoneCode": 380,
    "CountryName": "Ukraine"
  },
  {
    "CountryCode": "AE",
    "PhoneCode": 971,
    "CountryName": "United Arab Emirates"
  },
  {
    "CountryCode": "GB",
    "PhoneCode": 44,
    "CountryName": "United Kingdom"
  },
  {
    "CountryCode": "US",
    "PhoneCode": 1,
    "CountryName": "United States"
  },
  {
    "CountryCode": "UY",
    "PhoneCode": 598,
    "CountryName": "Uruguay"
  },
  {
    "CountryCode": "UZ",
    "PhoneCode": 998,
    "CountryName": "Uzbekistan"
  },
  {
    "CountryCode": "VU",
    "PhoneCode": 678,
    "CountryName": "Vanuatu"
  },
  {
    "CountryCode": "VA",
    "PhoneCode": 379,
    "CountryName": "Vatican"
  },
  {
    "CountryCode": "VE",
    "PhoneCode": 58,
    "CountryName": "Venezuela"
  },
  {
    "CountryCode": "VN",
    "PhoneCode": 84,
    "CountryName": "Vietnam"
  },
  {
    "CountryCode": "WF",
    "PhoneCode": 681,
    "CountryName": "Wallis and Futuna"
  },
  {
    "CountryCode": "EH",
    "PhoneCode": 212,
    "CountryName": "Western Sahara"
  },
  {
    "CountryCode": "YE",
    "PhoneCode": 967,
    "CountryName": "Yemen"
  },
  {
    "CountryCode": "ZM",
    "PhoneCode": 260,
    "CountryName": "Zambia"
  },
  {
    "CountryCode": "ZW",
    "PhoneCode": 263,
    "CountryName": "Zimbabwe"
  }
]

  export default CountryCodeList;