import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button, Collapse } from "reactstrap";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import ctAxisTitle from "chartist-plugin-axistitle";
import ChartistGraph from "react-chartist";
import "./userActivity.scss";
import Datepicker from "../common/datepicker";
var moment = require("moment");

class UserActivityComponent extends Component {
  constructor(props) {
    super(props);
    const loginStaticsOption = {
      maintainAspectRatio: false,
      axisY: {
        onlyInteger: true,
      },
      showArea: true,
      showPoint: false,
      fullWidth: true,
      height: 300,
      chartPadding: {
        right: 10,
        left: 20,
      },
      plugins: [
        ctAxisTitle({
          axisX: {
            axisTitle: "Time interval",
            axisClass: "ct-x-axis-title",
            offset: {
              x: 0,
              y: 50,
            },
            textAnchor: "middle",
          },
          axisY: {
            axisTitle: "Login count",
            axisClass: "ct-y-axis-title",
            offset: {
              x: 0,
              y: 10,
            },
            flipTitle: true,
          },
        }),
      ],
    };
    const resourceAccessOption = {
      maintainAspectRatio: false,
      axisY: {
        onlyInteger: true,
      },
      showArea: true,
      showPoint: false,
      fullWidth: true,
      height: 300,
      chartPadding: {
        right: 10,
        left: 20,
      },
      plugins: [
        ctAxisTitle({
          axisX: {
            axisTitle: "Time interval",
            axisClass: "ct-x-axis-title",
            offset: {
              x: 0,
              y: 50,
            },
            textAnchor: "middle",
          },
          axisY: {
            axisTitle: "Access count",
            axisClass: "ct-y-axis-title",
            offset: {
              x: 0,
              y: 10,
            },
            flipTitle: true,
          },
        }),
      ],
    };
    const pdfDownloadsOption = {
      maintainAspectRatio: false,
      axisY: {
        onlyInteger: true,
      },
      showArea: true,
      showPoint: false,
      fullWidth: true,
      height: 300,
      chartPadding: {
        right: 10,
        left: 20,
      },
      plugins: [
        ctAxisTitle({
          axisX: {
            axisTitle: "Time interval",
            axisClass: "ct-x-axis-title",
            offset: {
              x: 0,
              y: 50,
            },
            textAnchor: "middle",
          },
          axisY: {
            axisTitle: "Download count",
            axisClass: "ct-y-axis-title",
            offset: {
              x: 0,
              y: 10,
            },
            flipTitle: true,
          },
        }),
      ],
    };

    this.state = {
      loading: true,
      loginStaticsLoading: false,
      loginStaticsLoaded: false,
      loginStaticsData: {
        labels: [],
        series: [[]],
      },
      loginStaticsOption,
      resourceAccessLoading: false,
      resourceAccessLoaded: false,
      resourceAccessData: { labels: [], series: [[]] },
      resourceAccessOption,
      pdfDownloadsLoading: false,
      pdfDownloadsLoaded: false,
      pdfDownloadsData: { labels: [], series: [[]] },
      pdfDownloadsOption,
      filter: {
        fromDate: new Date(moment(moment().subtract(30, "days")).format()),
        toDate: new Date(),
        library: "",
        country: "",
        region: "",
        city: "",
        isOpen: true,
        loading: false,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      libraries: [],
      countries: [],
      regions: [],
      cities: [],
    };
  }

  getLibraries = () => {
    var url = new URL(`${API_BASE}libraries`),
      params = {};
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            libraries: res.data.docs.map((v) => ({
              value: v._id,
              label: v.UniversityName,
            })),
          });
        }
      });
  };
  getCountries = () => {
    var url = new URL(`${API_BASE}reports/countries`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ countries: res.map((v) => ({ value: v, label: v })) });
      });
  };

  getRegions = (country) => {
    if (country == "") {
      return this.setState({ regions: [] });
    }
    this.setState({ regions: [] });
    var url = new URL(`${API_BASE}reports/regions/${country}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ regions: res.map((v) => ({ value: v, label: v })) });
      });
  };
  getCities = (region) => {
    if (region == "") {
      return this.setState({ cities: [] });
    }
    this.setState({ cities: [] });
    var url = new URL(`${API_BASE}reports/cities/${region}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ cities: res.map((v) => ({ value: v, label: v })) });
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    if (JSON.parse(localStorage.getItem("user")).Role.Name == "Library Admin") {
      this.filterInputHandler(
        "library",
        JSON.parse(localStorage.getItem("user")).Library._id
      );
    } else {
      this.getLibraries();
    }
    this.getCountries();
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = Object.fromEntries(urlParams);
    for (var i in search) {
      const searchables = ["from_date", "to_date", "library_id[]"];
      if (searchables.includes(i)) {
        var filter = this.state.filter;
        switch (i) {
          case "from_date":
            filter.fromDate = new Date(search[i]);
            break;
          case "to_date":
            filter.toDate = new Date(search[i]);
            break;
          case "library_id[]":
            filter.library = search[i];
            break;
        }
        this.setState({ filter });
      }
    }
    if (Object.keys(search).length) {
      this.filterHandler();
    }
  }

  datePickerEventHandler = (event, picker) => {
    this.filterInputHandler("fromDate", new Date(picker.startDate));
    this.filterInputHandler("toDate", new Date(picker.endDate));
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    switch (key) {
      case "country":
        filter["region"] = "";
        filter["city"] = "";
        this.regionselect.state.value = null;
        this.cityselect.state.value = null;
        this.getRegions(val);
        break;
      case "region":
        filter["city"] = "";
        this.cityselect.state.value = null;
        this.getCities(val);
        break;

      default:
        break;
    }
    this.setState({ filter });
  };

  getDaysArray = (s, e) => {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };
  toggle24hr = (time, onoff) => {
    if (onoff == undefined) onoff = isNaN(time.replace(":", ""));
    var pm = time.toString().toLowerCase().indexOf("pm") > -1;
    time = time.toString().toLowerCase().replace(/[ap]m/, "").split(":");
    time[0] = Number(time[0]);
    if (onoff) {
      if (pm && time[0] != 12) time[0] += 12;
      else if (!pm && time[0] == 12) time[0] = "00";
      if (String(time[0]).length == 1) time[0] = "0" + time[0];
    } else {
      pm = time[0] >= 12;
      if (!time[0]) time[0] = 12;
      else if (pm && time[0] != 12) time[0] -= 12;
    }
    return onoff ? time[0] : time[0] + (pm ? "PM" : "AM");
  };

  constructUrlHandler = (endpoint) => {
    var url = new URL(`${API_BASE}reports/${endpoint}`),
      params = {};
    if (this.state.filter.fromDate) {
      params["from_date"] = moment(new Date(this.state.filter.fromDate)).format(
        "M/D/YYYY, h:m:s A"
      );
    }
    if (this.state.filter.toDate) {
      params["to_date"] = moment(new Date(this.state.filter.toDate)).format(
        "M/D/YYYY, h:m:s A"
      );
    }
    if (this.state.filter.library)
      params["library_id[]"] = this.state.filter.library;
    if (this.state.filter.country)
      params["country"] = this.state.filter.country;
    if (this.state.filter.region) params["region"] = this.state.filter.region;
    if (this.state.filter.city) params["city"] = this.state.filter.city;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.props.history.push({
      search: url.search,
    });
    return url;
  };

  graphHandler = (res) => {
    const timeDiff = this.state.filter.toDate - this.state.filter.fromDate;
    var graphData = {
      labels: [],
      series: [[]],
    };
    if (parseInt(timeDiff / 1000 / 60 / 60) < 24) {
      var start = new Date(this.state.filter.fromDate).getHours();
      var end = new Date(this.state.filter.toDate).getHours();
      var hours = [];
      while (start != end) {
        hours.push(start);
        if (start == 23) {
          start = 0;
        } else {
          start++;
        }
      }
      hours.push(end);
      var dates = [
        new Date(new Date(this.state.filter.toDate).toLocaleString())
          .toISOString()
          .slice(0, 10),
      ];
      if (hours.includes(0) && hours.indexOf(0) > 0) {
        dates = [
          new Date(
            moment(
              moment(
                new Date(new Date(this.state.filter.toDate).toLocaleString())
              ).subtract(1, "days")
            ).format()
          )
            .toISOString()
            .slice(0, 10),
          ...dates,
        ];
      }
      var dataHaving = {};
      for (var i in res.labels) {
        dataHaving[res.labels[i].split(" ")[1]] = [
          res.labels[i].split(" ")[0],
          res.series[0][i],
        ];
      }
      var datePointer = 0;
      for (var h of hours) {
        if (dataHaving[h] !== undefined) {
          graphData.labels.push(`${dataHaving[h][0]} ${this.toggle24hr(h, 0)}`);
          graphData.series[0].push(dataHaving[h][1]);
        } else {
          if (h == 0 && hours.indexOf(0) > 0) {
            datePointer = 1;
            graphData.labels.push(
              `${dates[datePointer]} ${this.toggle24hr(h, 0)}`
            );
            graphData.series[0].push(0);
          } else {
            graphData.labels.push(
              `${dates[datePointer]} ${this.toggle24hr(h, 0)}`
            );
            graphData.series[0].push(0);
          }
        }
      }
    } else if (parseInt(timeDiff / 1000 / 60 / 60 / 24 / 30) < 3) {
      var daylist = this.getDaysArray(
        new Date(this.state.filter.fromDate),
        new Date(this.state.filter.toDate)
      );
      const dates = daylist.map((v) => moment(v).format("YYYY-MM-DD"));
      var dataHaving = {};
      for (var i in res.labels) {
        dataHaving[res.labels[i]] = res.series[0][i];
      }
      for (var i in dates) {
        if (dataHaving[dates[i]] === undefined) {
          graphData.labels.push(dates[i]);
          graphData.series[0].push(0);
        } else {
          graphData.labels.push(dates[i]);
          graphData.series[0].push(dataHaving[dates[i]]);
        }
      }
    } else {
      var daylist = this.getDaysArray(
        new Date(this.state.filter.fromDate),
        new Date(this.state.filter.toDate)
      );
      const dates = daylist
        .map((v) => moment(v).format("YYYY-MM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      var dataHaving = {};
      for (var i in res.labels) {
        dataHaving[res.labels[i]] = res.series[0][i];
      }
      for (var i in dates) {
        if (dataHaving[dates[i]] === undefined) {
          graphData.labels.push(dates[i]);
          graphData.series[0].push(0);
        } else {
          graphData.labels.push(dates[i]);
          graphData.series[0].push(dataHaving[dates[i]]);
        }
      }
    }
    return graphData;
  };

  getResourceAccessByTime = () => {
    this.setState({
      resourceAccessLoading: true,
      resourceAccessLoaded: false,
    });
    this.filterInputHandler("loading", true);
    var url = this.constructUrlHandler("resource-access-bytime");
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        const graphData = this.graphHandler(res);
        this.setState({
          resourceAccessLoading: false,
          resourceAccessLoaded: true,
          resourceAccessData: graphData,
        });
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        this.setState({
          resourceAccessLoading: false,
          resourceAccessLoaded: false,
        });
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  getPdfDownloadsByTime = () => {
    this.filterInputHandler("loading", true);
    this.setState({
      pdfDownloadsLoading: true,
      pdfDownloadsLoaded: false,
    });
    var url = this.constructUrlHandler("pdf-downloads-bytime");
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        const graphData = this.graphHandler(res);
        this.setState({
          pdfDownloadsLoading: false,
          pdfDownloadsLoaded: true,
          pdfDownloadsData: graphData,
        });
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        this.setState({
          pdfDownloadsLoading: true,
          pdfDownloadsLoaded: false,
        });
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  getLoginStaticsByTime = () => {
    this.filterInputHandler("loading", true);
    this.setState({
      loginStaticsLoading: true,
      loginStaticsLoaded: false,
    });
    var url = this.constructUrlHandler("user-login-logs-bytime");
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        const graphData = this.graphHandler(res);
        this.setState({
          loginStaticsLoading: false,
          loginStaticsLoaded: true,
          loginStaticsData: graphData,
        });
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        this.setState({
          loginStaticsLoading: true,
          loginStaticsLoaded: false,
        });
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  filterHandler = () => {
    this.getLoginStaticsByTime();
    this.getResourceAccessByTime();
    this.getPdfDownloadsByTime();
  };

  downloadCSV(name) {
    var header = [],
      data = [];
    switch (name) {
      case "login-statistics":
        header = ["Time interval", "Login count"];
        data = [
          this.state.loginStaticsData.labels,
          this.state.loginStaticsData.series[0],
        ];
        break;
      case "resource-access":
        header = ["Time interval", "Access count"];
        data = [
          this.state.resourceAccessData.labels,
          this.state.resourceAccessData.series[0],
        ];
        break;
      case "pdf-downloads":
        header = ["Time interval", "Download count"];
        data = [
          this.state.pdfDownloadsData.labels,
          this.state.pdfDownloadsData.series[0],
        ];
        break;

      default:
        return;
    }
    window.open(
      `${API_BASE}reports/export/${name}?data=${JSON.stringify({
        header,
        data,
      })}`
    );
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="User Activity" parent="false" />
        <div className="container-fluid">
          {this.state.alert}
          <button
            className="btn btn-pill btn-info btn-air-info btn-xs"
            type="button"
            onClick={() => {
              var filter = this.state.filter;
              filter.isOpen = !filter.isOpen;
              this.setState({ filter });
            }}
          >
            {this.state.filter.isOpen ? (
              <span>
                Hide filter <i className="fa fa-minus"></i>
              </span>
            ) : (
              <span>
                Show filter <i className="fa fa-plus"></i>
              </span>
            )}
          </button>
          <div className="clearfix mt-2"></div>
          <Collapse isOpen={this.state.filter.isOpen}>
            <div className="card p-3">
              <div className="row">
                <div
                  className={
                    this.state.loggedInUser.Role.IsAdmin ||
                    this.state.loggedInUser.Role.Name == "Organization Admin"
                      ? "col-md-4"
                      : "col-md-12"
                  }
                >
                  <Datepicker
                    maxDate={moment()}
                    startDate={this.state.filter.fromDate}
                    endDate={this.state.filter.toDate}
                    onEvent={this.datePickerEventHandler}
                  >
                    <i className="fa fa-calendar "></i> Date range
                  </Datepicker>
                </div>
                {(this.state.loggedInUser.Role.IsAdmin ||
                  this.state.loggedInUser.Role.Name ==
                    "Organization Admin") && (
                  <div className="col-md-8">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-building"></i>
                        </span>
                      </div>
                      <Select
                        isClearable
                        className="resource-access-1st-react-select-container"
                        options={this.state.libraries}
                        placeholder="All library"
                        value={this.state.libraries.filter(
                          (option) => option.value == this.state.filter.library
                        )}
                        onChange={(value) =>
                          this.filterInputHandler(
                            "library",
                            value ? value.value : null
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-globe"></i>
                      </span>
                    </div>
                    <Select
                      isClearable
                      className="resource-access-2nd-react-select-container"
                      options={this.state.countries}
                      placeholder="All countries"
                      onChange={(value) =>
                        this.filterInputHandler(
                          "country",
                          value ? value.value : null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-globe"></i>
                      </span>
                    </div>
                    <Select
                      isClearable
                      instanceId="regionselect"
                      ref={(c) => (this.regionselect = c)}
                      className="resource-access-2nd-react-select-container"
                      options={this.state.regions}
                      placeholder="All states"
                      onChange={(value) =>
                        this.filterInputHandler(
                          "region",
                          value ? value.value : null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-globe"></i>
                      </span>
                    </div>
                    <Select
                      isClearable
                      instanceId="cityselect"
                      ref={(c) => (this.cityselect = c)}
                      className="resource-access-2nd-react-select-container"
                      options={this.state.cities}
                      placeholder="All cities"
                      onChange={(value) =>
                        this.filterInputHandler(
                          "city",
                          value ? value.value : null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <Button
                    className="btn-block"
                    color="secondary"
                    disabled={this.state.filter.loading}
                    onClick={this.filterHandler}
                  >
                    {this.state.filter.loading ? (
                      <i className="fa fa-cog fa-spin"></i>
                    ) : (
                      <i className="fa fa-filter"></i>
                    )}{" "}
                    FILTER
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          <div className="clearfix"></div>
          <div className="row">
            <div className="col-md-12">
              <div className="small-chart-widget chart-widgets-small">
                <div className="card">
                  <div className="card-header">
                    <h5>Login statistics</h5>
                    {this.state.resourceAccessLoaded && (
                      <i
                        className="fa fa-download"
                        style={styles.downloadIcon}
                        title="Download CSV"
                        onClick={() => this.downloadCSV("login-statistics")}
                      ></i>
                    )}
                  </div>
                  <div className="card-body bg-primary">
                    {this.state.loginStaticsLoading && (
                      <div
                        className="loader-box"
                        style={{ flex: 1, justifyContent: "center" }}
                      >
                        <div className="loader">
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    )}
                    {this.state.loginStaticsLoaded && (
                      <div className="chart-container">
                        <div className="live-products">
                          <ChartistGraph
                            data={this.state.loginStaticsData}
                            options={{
                              ...this.state.loginStaticsOption,
                              axisX: {
                                offset: 60,
                                labelInterpolationFnc: (value, index) => {
                                  return index %
                                    (Math.round(
                                      this.state.loginStaticsData.labels
                                        .length / 22
                                    ) <
                                    this.state.loginStaticsData.labels.length /
                                      22
                                      ? Math.round(
                                          this.state.loginStaticsData.labels
                                            .length / 22
                                        ) + 1
                                      : Math.round(
                                          this.state.loginStaticsData.labels
                                            .length / 22
                                        )) ===
                                    0
                                    ? value
                                    : null;
                                },
                              },
                            }}
                            type={"Bar"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="small-chart-widget chart-widgets-small">
                <div className="card">
                  <div className="card-header">
                    <h5>Resource access</h5>
                    {this.state.resourceAccessLoaded && (
                      <i
                        className="fa fa-download"
                        style={styles.downloadIcon}
                        title="Download CSV"
                        onClick={() => this.downloadCSV("resource-access")}
                      ></i>
                    )}
                  </div>
                  <div className="card-body bg-primary">
                    {this.state.resourceAccessLoading && (
                      <div
                        className="loader-box"
                        style={{ flex: 1, justifyContent: "center" }}
                      >
                        <div className="loader">
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    )}
                    {this.state.resourceAccessLoaded && (
                      <div className="chart-container">
                        <div className="live-products">
                          <ChartistGraph
                            data={this.state.resourceAccessData}
                            options={{
                              ...this.state.resourceAccessOption,
                              axisX: {
                                offset: 60,
                                labelInterpolationFnc: (value, index) => {
                                  return index %
                                    (Math.round(
                                      this.state.resourceAccessData.labels
                                        .length / 22
                                    ) <
                                    this.state.resourceAccessData.labels
                                      .length /
                                      22
                                      ? Math.round(
                                          this.state.resourceAccessData.labels
                                            .length / 22
                                        ) + 1
                                      : Math.round(
                                          this.state.resourceAccessData.labels
                                            .length / 22
                                        )) ===
                                    0
                                    ? value
                                    : null;
                                },
                              },
                            }}
                            type={"Bar"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="small-chart-widget chart-widgets-small">
                <div className="card">
                  <div className="card-header">
                    <h5>PDF downloads</h5>
                    {this.state.resourceAccessLoaded && (
                      <i
                        className="fa fa-download"
                        style={styles.downloadIcon}
                        title="Download CSV"
                        onClick={() => this.downloadCSV("pdf-downloads")}
                      ></i>
                    )}
                  </div>
                  <div className="card-body bg-primary">
                    {this.state.pdfDownloadsLoading && (
                      <div
                        className="loader-box"
                        style={{ flex: 1, justifyContent: "center" }}
                      >
                        <div className="loader">
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    )}
                    {this.state.pdfDownloadsLoaded && (
                      <div className="chart-container">
                        <div className="live-products">
                          <ChartistGraph
                            data={this.state.pdfDownloadsData}
                            options={{
                              ...this.state.pdfDownloadsOption,
                              axisX: {
                                offset: 60,
                                labelInterpolationFnc: (value, index) => {
                                  return index %
                                    (Math.round(
                                      this.state.pdfDownloadsData.labels
                                        .length / 22
                                    ) <
                                    this.state.pdfDownloadsData.labels.length /
                                      22
                                      ? Math.round(
                                          this.state.pdfDownloadsData.labels
                                            .length / 22
                                        ) + 1
                                      : Math.round(
                                          this.state.pdfDownloadsData.labels
                                            .length / 22
                                        )) ===
                                    0
                                    ? value
                                    : null;
                                },
                              },
                            }}
                            type={"Bar"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  downloadIcon: {
    float: "right",
    marginTop: -17,
    fontSize: 16,
    color: "#4466f2",
    cursor: "pointer",
  },
};

export default UserActivityComponent;
