import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState({});
  const [defaultRole, setDefaultRole] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedLibrary, setSelectedLibrary] = useState(null);
  const [libraries, setLibraries] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [validationError, setValidationError] = useState({});
  const getLibraries = () => {
    var url = new URL(`${API_BASE}libraries?pagination=off`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.docs.map((v) => {
            return {
              value: v._id,
              label: `${v.UniversityName} ${
                v.Organization
                  ? v.Organization.Name
                    ? `(${v.Organization.Name})`
                    : ``
                  : ``
              }`,
            };
          });
          setLibraries(data);
        } else {
        }
      });
  };
  const getOrganizations = () => {
    var url = new URL(`${API_BASE}organizations`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setOrganizations(data);
        } else {
        }
      });
  };
  const getRoles = () => {
    var url = new URL(`${API_BASE}roles`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = {};
          for (var role in res.data) {
            switch (JSON.parse(localStorage.getItem("user")).Role.Name) {
              case "Organization Admin":
                if (res.data[role].Name == "Library Admin")
                  data[res.data[role]._id] = res.data[role];
                break;

              default:
                data[res.data[role]._id] = res.data[role];
                if (res.data[role].Name == "Master Admin")
                  setDefaultRole(res.data[role]._id);
                setRole("Master Admin");
                break;
            }
          }
          setRoles(data);
        } else {
        }
      });
  };
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    if (
      JSON.parse(localStorage.getItem("user")).Role.Name == "Organization Admin"
    ) {
      setRole("Library Admin");
    }
    getRoles();
    getLibraries();
    getOrganizations();
    return () => {};
  }, []);
  const onSubmit = (data) => {
    if (data !== "") {
      switch (role) {
        case "Organization Admin":
          if (!selectedOrganization)
            return setValidationError({ Organization: true });
          data = { ...data, Organization: selectedOrganization };
          setValidationError({ Organization: false });
          break;
        case "Library Admin":
          if (!selectedLibrary) return setValidationError({ Library: true });
          data = { ...data, Library: selectedLibrary };
          setValidationError({ Library: false });
          break;

        default:
          break;
      }
      setLoading(true);
      fetch(`${API_BASE}users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.validation) {
            console.log(Object.values(res.errors).length);
            if (Object.values(res.errors).length > 0) {
              toast.error(Object.values(res.errors)[0].message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else if (!res.success) {
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/users`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getBreadcrumbTitle = () => {
    if (loggedInUser) {
      switch (loggedInUser.Role.Name) {
        case "Organization Admin":
          return "Library Admin";

        default:
          return "User";
      }
    }
    return "";
  };
  return (
    <div>
      <Breadcrumb
        parent={`${getBreadcrumbTitle()}s`}
        parentLink={`${process.env.PUBLIC_URL}/users`}
        title={`Add ${getBreadcrumbTitle()}`}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <label htmlFor="validationCustom01">User Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="UserName"
                      placeholder="User Name"
                      ref={register({ required: true })}
                    />
                    <span>{errors.UserName && "User Name is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Display Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Display Name"
                      name="DisplayName"
                      ref={register({ required: true })}
                    />
                    <span>
                      {errors.DisplayName && "Display Name is required"}
                    </span>
                  </div>

                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="Email"
                      ref={register({ required: true })}
                    />
                    <span>{errors.Email && "Email is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      name="Password"
                      ref={register({ required: true })}
                    />
                    <span>{errors.Password && "Password is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Role
                    </label>
                    {Object.values(roles).length > 0 && (
                      <select
                        className="form-control"
                        onChange={(e) => setRole(roles[e.target.value].Name)}
                        name="Role"
                        ref={register({ required: true })}
                        defaultValue={defaultRole}
                      >
                        {Object.values(roles).map((v, k) => (
                          <option key={v._id} value={v._id}>
                            {v.Name}
                          </option>
                        ))}
                      </select>
                    )}
                    <span>{errors.Role && "Role is required"}</span>
                  </div>
                  {role == "Library Admin" && (
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="recipient-name"
                      >
                        Library
                      </label>
                      <Select
                        options={libraries}
                        onChange={(selectedLibrary) =>
                          setSelectedLibrary(selectedLibrary.value)
                        }
                      />
                      <span style={{ color: "red" }}>
                        {validationError.Library && "PLease select a library"}
                      </span>
                    </div>
                  )}
                  {role == "Organization Admin" && (
                    <div className="form-group">
                      <label
                        className="col-form-label"
                        htmlFor="recipient-name"
                      >
                        Organization
                      </label>
                      <Select
                        options={organizations}
                        onChange={(selectedOrganization) =>
                          setSelectedOrganization(selectedOrganization.value)
                        }
                      />
                      <span style={{ color: "red" }}>
                        {validationError.Organization &&
                          "PLease select an organization"}
                      </span>
                    </div>
                  )}
                  <div style={{ float: "right" }}>
                    <Button
                      color="primary"
                      style={{ marginLeft: "10px", marginTop: 10 }}
                    >
                      {loading ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
