import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Select from "react-select";
import { API_BASE } from "../../../constant/api";
import InputTag from "./inputTag";
import CKEditors from "react-ckeditor-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class CustomEmailComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      selectedGroups: [],
      indvEmails: [],
      subject: "",
      body: null,
      loading: false,
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
    };
  }

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.getGroups();
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getLibrary(this.props.match.params.libid);
  }

  getGroups = () => {
    var url = new URL(
      `${API_BASE}groups?library_id=${this.props.match.params.libid}`
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            groups: res.data.map((v) => ({
              value: v._id,
              label: v.Name,
            })),
          });
        } else {
        }
      });
  };

  indvEmailTagHandler = (arr) => {
    this.setState({ indvEmails: arr });
  };

  bodyChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({ body: newContent });
  };

  sendEmail = () => {
    if (
      this.state.selectedGroups.length == 0 &&
      this.state.indvEmails.length == 0
    ) {
      alert("Please select atleast one receiver");
      return;
    }
    if (!this.state.subject) {
      alert("Please fill the subject field");
      return;
    }
    if (!this.state.body) {
      alert("Please make some body for sending email");
      return;
    }
    var url = new URL(`${API_BASE}custom-emails/send`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        groups: this.state.selectedGroups.map((v) => v.value),
        indvEmails: this.state.indvEmails,
        subject: this.state.subject,
        body: this.state.body,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        if (res.success) {
          toast.success("Email sent successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            selectedGroups: [],
            indvEmails: [],
            subject: "",
            body: null,
            loading: false,
          });
        } else {
          toast.error("Someting went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
      ];
    } else {
      return [];
    }
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb
          title={`Custom Email`}
          specialParent={this.getParentBreadcrumb()}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label>To Groups</label>
                    <Select
                      isMulti
                      isClearable
                      value={this.state.selectedGroups}
                      options={this.state.groups}
                      onChange={(value) =>
                        this.setState({
                          selectedGroups: value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>To Individuals</label>
                    <InputTag
                      tags={this.state.indvEmails}
                      change={this.indvEmailTagHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      value={this.state.subject}
                      className="form-control"
                      type="text"
                      placeholder="Subject"
                      onChange={(e) =>
                        this.setState({ subject: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Body</label>
                    <CKEditors
                      activeclassName="p10"
                      content={this.state.body}
                      events={{
                        change: this.bodyChange.bind(this),
                      }}
                    />
                  </div>
                  <button
                    disabled={this.state.loading}
                    className="btn btn-primary btn-block btn-mail text-center mb-0 mt-5"
                    type="button"
                    onClick={this.sendEmail}
                  >
                    {this.state.loading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      <i className="fa fa-paper-plane mr-2">&nbsp;SEND</i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
