import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE } from "../../../constant/api";
import moment from "moment";

const LibraryLicense = () => {
  const history = useHistory();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [library, setLibrary] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(JSON.parse(localStorage.getItem("user")).Library._id);
    return () => {};
  }, []);

  const getLibrary = (id) => {
    setLoading(true);
    fetch(`${API_BASE}libraries/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setLibrary(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const keyFormatter = (key) => {
    const parts = key.split("-");
    return parts[0] + "-****-****-" + parts[parts.length - 1];
  };

  if (!library) {
    return (
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <i
              className="fa fa-trophy"
              style={{ fontSize: 25, color: "#ff9f40" }}
            ></i>
          </div>
          <ul
            className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"
            style={{ width: "180px", marginTop: "-30px" }}
          >
            <li style={{ textAlign: "center" }}>
              <span>No active license</span>
            </li>
          </ul>
        </li>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <i
            className="fa fa-trophy"
            style={{ fontSize: 25, color: "#ff9f40" }}
          ></i>
        </div>
        <ul
          className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover"
          style={{ width: "285px", marginTop: "-30px" }}
        >
          {!library.License && (
            <li style={{ textAlign: "center" }}>
              <span style={{ color: "#ff5370" }}>No active license</span>
            </li>
          )}
          {library.License && (
            <Fragment>
              <li>
                <b>Key: </b> <code>{keyFormatter(library.License.Key)}</code>
              </li>
              <li>
                <b>Start: </b>{" "}
                <code>
                  {moment(library.License.Start).format("D MMM YYYY")}
                </code>
              </li>
              <li>
                <b>End: </b>{" "}
                <code>{moment(library.License.End).format("D MMM YYYY")}</code>
              </li>
              {/* <li>
                <b>Status: </b>{" "}
                {library.License.IsActive && <span className="badge badge-pill badge-success">Active</span>}
                {!library.License.IsActive && <span className="badge badge-pill badge-danger">Inactive</span>}
              </li> */}
            </Fragment>
          )}
        </ul>
      </li>
    </Fragment>
  );
};

export default LibraryLicense;
