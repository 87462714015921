import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const AddMasterResource = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      if (data.ResourceCategories == "select") {
        delete data["ResourceCategories"];
      }
      setLoading(true);
      fetch(`${API_BASE}masterResources`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setError(res.msg);
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/master-resources`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <div>
      <Breadcrumb
        parent="Master Resource"
        parentLink={`${process.env.PUBLIC_URL}/master-resources`}
        title="Add Master Resource"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        placeholder="Name"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Name && "Name is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Code</label>
                      <input
                        className="form-control"
                        name="Code"
                        type="text"
                        placeholder="Code"
                        ref={register({ required: true })}
                      />
                      <span>{errors.Code && "Code is required"}</span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Description</label>
                      <textarea
                        className="form-control"
                        name="Description"
                        placeholder="Description"
                        ref={register({ required: true })}
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Description && "Description is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Meta</label>
                      <textarea
                        className="form-control"
                        name="Meta"
                        placeholder="Meta"
                        ref={register({ required: true })}
                        rows="10"
                      ></textarea>
                      <span style={{ color: "red" }}>
                        {errors.Meta && "Meta is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Create"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMasterResource;
