import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const AddRole = () => {
    return (
        // <Fragment>
        <div>
            <Breadcrumb parent="Role" title="Add Role" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <h5>Sample Card</h5><span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span>
                            </div> */}
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="recipient-name">Name:</label>
                                        <input className="form-control" type="text" placeholder="Name" defaultValue="Library Admin" />
                                    </div>
                                    <div className="form-group">
                                        <h5>Is Admin</h5>
                                    </div>
                                    <div className="col">
                                        <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml" style={{"display": "inline-block"}}>
                                            <div className="radio radio-primary" style={{"float": "left","display": "inline"}}>
                                                <input id="radioinline1" type="radio" name="radio3" value="option1" />
                                                <label className="mb-0" for="radioinline1">Yes</label>
                                            </div>
                                            <div className="radio radio-primary" style={{"float": "left","display": "inline", "marginLeft":"10px"}}>
                                                <input id="radioinline2" type="radio" name="radio3" value="option1" checked />
                                                <label className="mb-0" for="radioinline2">No</label>
                                            </div>
                                        </div>   
                                    </div>                                 
                                    <div style={{float: "right"}}>
                                        <Button color="primary"  style={{marginLeft: "10px"}}>Create</Button>
                                        {/* <Button color="secondary">Cancel</Button> */}
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </Fragment>
    );
};

export default AddRole;