import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import useForm from "react-hook-form";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

const AddGroup = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [resources, setResources] = useState([
    {
      value: "*",
      label: "Select All Resources",
    },
  ]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [library, setLibrary] = useState({
    UniversityName: "Fetching...",
  });
  const [loggedInUser, setLoggedInUser] = useState({
    Role: {
      IsAdmin: false,
      Name: "",
    },
  });
  const getResources = () => {
    var url = new URL(
      `${API_BASE}resources?pagination=off&library_id=${match.params.libid}`
    );
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.docs.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setResources([...resources, ...data]);
        } else {
        }
      });
  };
  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLibrary(res.data);
        } else {
          setLibrary(404);
        }
      });
  };
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem("user")));
    getLibrary(match.params.libid);
    getResources();
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      setLoading(true);
      if (selectedResources.includes("*")) {
        data = { ...data, AllResources: true };
      } else {
        data = { ...data, Resources: selectedResources };
      }
      data = { ...data, Library: match.params.libid };
      fetch(`${API_BASE}groups`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setLoading(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(
              `${process.env.PUBLIC_URL}/library/${match.params.libid}/groups`
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const getParentBreadcrumb = () => {
    if (
      loggedInUser.Role.Name == "Master Admin" ||
      loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${match.params.libid}`,
        },
        {
          label: "User Groups",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/groups`,
        },
      ];
    } else {
      return [
        {
          label: "User Groups",
          link: `${process.env.PUBLIC_URL}/library/${match.params.libid}/groups`,
        },
      ];
    }
  };

  if (library == 404) {
    return (
      <Fragment>
        <Breadcrumb
          title="Add User Group"
          parentLink={`${process.env.PUBLIC_URL}/libraries`}
          parent={
            loggedInUser.Role.Name == "Master Admin" ||
            loggedInUser.Role.Name == "Organization Admin"
              ? "Libraries"
              : "false"
          }
        />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>Library not found!</p>
            <hr />
            <p className="mb-0">
              {(loggedInUser.Role.Name == "Master Admin" ||
                loggedInUser.Role.Name == "Organization Admin") && (
                <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                  Back to Libraries
                </Link>
              )}
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumb
        specialParent={getParentBreadcrumb()}
        title="Add User Group"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="Name"
                      ref={register({ required: true })}
                    />
                    <span>{errors.Name && "Name is required"}</span>
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Display Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Display Name"
                      name="DisplayName"
                      ref={register({ required: true })}
                    />
                    <span>
                      {errors.DisplayName && "Display Name is required"}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Resources
                    </label>
                    <Select
                      isMulti
                      options={resources}
                      onChange={(value) =>
                        setSelectedResources(
                          value ? value.map((v) => v.value) : []
                        )
                      }
                    />
                  </div>
                  <div style={{ float: "right" }}>
                    <Button color="primary" style={{ marginLeft: "10px" }}>
                      {loading ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddGroup;
