import React, { Component } from "react";
import logo from "../assets/images/endless-logo.png";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE } from "../constant/api";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMsg: null,
      loading: false,
    };
  }

  loginAuth = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    fetch(`${API_BASE}auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserName: this.state.username,
        Password: this.state.password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        if (!res.success) {
          this.setState({ errorMsg: res.msg });
        } else {
          this.loginHandler(res);
        }
      });
  };

  loginHandler = (data) => {
    toast.success(data.msg, {
      position: toast.POSITION.TOP_CENTER,
    });
    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
  };

  render() {
    return (
      <div>
        <div className="page-wrapper">
          <div className="container-fluid p-0">
            {/* <!-- login page start--> */}
            <div className="authentication-main" style={{
              position: 'relative',
              width: '1250px',
              height: '128px',
              opacity: '1000',
              zindex: '2'
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="auth-innerright">
                    <div className="authentication-box">
                      <div className="text-center">
                        <img src={logo} alt="" style={{ width: '95%' }} />
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="text-center">
                            <h4>LOGIN</h4>
                            <h6>Enter your Username and Password </h6>
                          </div>
                          {this.state.errorMsg && (
                            <div
                              className="dark alert alert-danger fade show"
                              role="alert"
                            >
                              {this.state.errorMsg}
                            </div>
                          )}
                          <form
                            className="theme-form"
                            onSubmit={this.loginAuth.bind(this)}
                          >
                            <div className="form-group">
                              <label className="col-form-label pt-0">
                                Your Username
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                onChange={(event) =>
                                  this.setState({ username: event.target.value })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">Password</label>
                              <input
                                className="form-control"
                                type="password"
                                required
                                onChange={(event) =>
                                  this.setState({
                                    password: event.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled={this.state.loading}
                              >
                                {this.state.loading ? (
                                  <i
                                    style={{ fontSize: 18 }}
                                    className="fa fa-cog fa-spin"
                                  ></i>
                                ) : (
                                  "Login"
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- login page end--> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
