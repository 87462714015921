import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button } from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddLicense = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [libraries, setLibraries] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isActive, setIsActive] = useState("1");
  const [validationError, setValidationError] = useState({});
  const [licType, setSelectedOption] = useState("1");
  const getLibraries = () => {
    var url = new URL(`${API_BASE}libraries?pagination=off`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.docs.map((v) => {
            return {
              value: v._id,
              label: v.UniversityName,
            };
          });
          setLibraries([...data]);
        } else {
        }
      });
  };
  useEffect(() => {
    getLibraries();
    return () => { };
  }, []);

  function onChangeValue(event) {
    setSelectedOption(event.target.value);
  }

  const licenceCreateHandler = () => {
    var err = {};
    var valid = true;
    setValidationError(err);
    if (!startDate) {
      err = { ...err, Start: "Start date is required" };
      valid = false;
    }
    if (!endDate) {
      err = { ...err, End: "End date is required" };
      valid = false;
    }
    if (!selectedLibrary) {
      err = { ...err, Library: "Library is required" };
      valid = false;
    }
    setValidationError(err);
    if (valid) {
      var data = {
        Start: startDate,
        End: endDate,
        Library: selectedLibrary,
        IsActive: isActive == "1" ? true : false,
        IsRegular: licType == "1" ? true : false
      };
      setLoading(true);
      fetch(`${API_BASE}license`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setLoading(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/licenses`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="License"
        parentLink={`${process.env.PUBLIC_URL}/licenses`}
        title="Add License"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form className="needs-validation">
                  <div className="form-row row">
                    <div className="col-md-6">
                      <label>Start Date</label>
                      <DatePicker
                        selected={startDate}
                        className="form-control digits"
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        placeholderText="Select start date"
                        dateFormat="dd/MM/yyyy"
                      />
                      {validationError.Start && (
                        <span style={{ color: "red" }}>
                          {validationError.Start}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label>End Date</label>
                      <DatePicker
                        selected={endDate}
                        className="form-control digits"
                        onChange={(date) => setEndDate(date)}
                        minDate={startDate}
                        placeholderText="Select end date"
                        dateFormat="dd/MM/yyyy"
                      />
                      {validationError.End && (
                        <span style={{ color: "red" }}>
                          {validationError.End}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Library
                    </label>
                    <Select
                      options={libraries}
                      onChange={(value) => setSelectedLibrary(value.value)}
                    />
                    {validationError.Library && (
                      <span style={{ color: "red" }}>
                        {validationError.Library}
                      </span>
                    )}
                  </div>
                  <div className="form-row">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Status
                    </label>
                    <select
                      className="form-control"
                      onChange={(e) => setIsActive(e.target.value)}
                      defaultValue={isActive}
                    >
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                  <br />
                  <div onChange={onChangeValue}>
                    <input type="radio" value="1" name="licType" checked={licType === "1"} /> Regular &nbsp;&nbsp;
                    <input type="radio" value="2" name="licType" checked={licType === "2"} /> Trial
                  </div>
                  <br />
                  <div style={{ float: "right" }}>
                    <Button
                      color="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={licenceCreateHandler}
                    >
                      {loading ? (
                        <i
                          style={{ fontSize: 18 }}
                          className="fa fa-cog fa-spin"
                        ></i>
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddLicense;
