import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
    };
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const {
      pageSize,
      myClass,
      pagination,
      viewHandler,
      editHandler,
      deleteHandler,
      updatePasswordHandler,
      isAdmin,
      myData,
    } = this.props;
    const columnNames = ["UserName", "Email", "Role"];
    const columns = [];
    for (var key in myData[0]) {
      if (columnNames.includes(key)) {
        let header = this.Capitalize(key.toString());
        let accessor = key;
        if (key == "Role") accessor = "Role.Name";
        columns[columnNames.indexOf(key)] = {
          Header: (
            <div style={{ textAlign: "left" }}>
              <b>{header}</b>
            </div>
          ),
          accessor: accessor,
          Cell: null,
          style: {
            textAlign: "left",
          },
        };
      }
    }

    columns.push({
      Header: <b>Action</b>,
      id: "delete",
      // eslint-disable-next-line
      accessor: (str) => "delete",
      Cell: (row) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => viewHandler(row.original)}
            title="View"
          >
            <i
              className="fa fa-eye"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "#4466f2",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => editHandler(row.original)}
            title="Edit"
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            ></i>
          </span>
          {/* {isAdmin && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => updatePasswordHandler(row.original)}
              title="Update Password"
            >
              <i
                className="fa fa-key"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#1ea6ec",
                }}
              ></i>
            </span>
          )} */}

          <span
            onClick={() => deleteHandler(row.original)}
            style={{ cursor: "pointer" }}
            title="Delete"
          >
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 16,
                padding: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
        </div>
      ),
      // eslint-disable-next-line
      style: {
        textAlign: "center",
      },
      sortable: false,
    });

    return (
      <Fragment>
        <ReactTable
          data={myData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Datatable;
