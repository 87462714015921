import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../libraries/datatable";
import { Button } from "reactstrap";
import { API_BASE } from "../../constant/api";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LibrariesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      libraries: [],
      pagination: {},
      loggedInUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
      filter: {
        name: "",
        id: "",
        loading: false,
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getLibraries = (page = null, limit = 1000) => {
    var url = new URL(`${API_BASE}libraries`),
      params = {};
    const urlParams = new URLSearchParams(this.props.location.search);
    const search = Object.fromEntries(urlParams);
    for (var i in search) {
      const searchables = ["id", "name", "created_by", "code"];
      if (searchables.includes(i)) {
        var filter = this.state.filter;
        filter[i] = search[i];
        params[i] = search[i];
        this.setState({ filter });
      }
    }
    if (page) params["page"] = page;
    if (limit) params["limit"] = limit;
    params["pagination"] = "off";
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {

            res.data.docs.forEach((lib) => {
              if(lib.id === lib.Organization.OrgLibraryId){
                lib.UniversityName = lib.UniversityName + " - (Consortia)";
              }
            })
            this.setState({
              loading: false,
              libraries: res.data.docs,
              pagination: {
                hasPrevPage: res.data.hasPrevPage,
                hasNextPage: res.data.hasNextPage,
                page: res.data.page,
                prevPage: res.data.prevPage,
                nextPage: res.data.nextPage,
              },
            });
         
        }
      });
  };

  componentDidMount() {
    this.getLibraries();
  }

  closeModalAdd = () => {
    this.setState((prevState, props) => ({
      show: !prevState.show,
    }));
  };
  editHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/edit/${library._id}`
    );
  };

  resourceHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/resources`
    );
  };
  authenticationHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/auths`
    );
  };
  resourceCategoryHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/resource-categories`
    );
  };

  groupHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/groups`
    );
  };

  userHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/users`
    );
  };

  filesHandler = (library, isUserManual) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/files/${library._id}/${isUserManual}`
    );
  };

  viewHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/view/${library._id}/${this.props.location.search}`
    );
  };

  emailHandler = (library) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${library._id}/custom-emails`
    );
  };

  deleteHandler = (library) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteLibrary(library._id)}
        >
          You will not be able to recover this Library!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteLibrary = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getLibraries();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  filterInputHandler = (key, val) => {
    var filter = { ...this.state.filter };
    filter[key] = val;
    this.setState({ filter });
  };

  filterHandler = () => {
    var url = new URL(`${API_BASE}libraries`),
      params = {};
    params["pagination"] = "off";
    if (this.state.filter.name) params["name"] = this.state.filter.name;
    if (this.state.filter.id) params["id"] = this.state.filter.id;
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    this.props.history.push({
      search: url.search
        .replace("pagination=off&", "")
        .replace("pagination=off", ""),
    });
    this.filterInputHandler("loading", true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.filterInputHandler("loading", false);
        if (res.success) {
          this.setState({
            libraries: res.data.docs,
            pagination: {
              hasPrevPage: res.data.hasPrevPage,
              hasNextPage: res.data.hasNextPage,
              page: res.data.page,
              prevPage: res.data.prevPage,
              nextPage: res.data.nextPage,
            },
          });
        }
      })
      .catch((err) => {
        this.filterInputHandler("loading", false);
        toast.error("No response from server!", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb title="Libraries" parent="false" />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb title="Libraries" parent="false" />

        <div className="container-fluid">
          {this.state.alert}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <Button
                    style={{ float: "right", marginTop: -90 }}
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        `${process.env.PUBLIC_URL}/library/create`
                      )
                    }
                  >
                    <i className="fa fa-plus"></i> ADD
                  </Button>
                  <div className="clearfix"></div>
                  <div className="row pl-5 pr-5">
                    {/* <div className="col-md-5">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by Database ID"
                          value={this.state.filter.id}
                          onChange={(e) =>
                            this.filterInputHandler("id", e.target.value)
                          }
                        />
                      </div>
                    </div> */}
                    <div className="col-md-8">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search by Library Name"
                          value={this.state.filter.name}
                          onChange={(e) =>
                            this.filterInputHandler("name", e.target.value)
                          }
                          onKeyPress={(event) => {
                            var code = event.keyCode || event.which;
                            if (code === 13) {
                              this.filterHandler();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Button
                        className="btn-block"
                        color="secondary"
                        disabled={this.state.filter.loading}
                        onClick={this.filterHandler}
                      >
                        {this.state.filter.loading ? (
                          <i className="fa fa-cog fa-spin"></i>
                        ) : (
                          <i className="fa fa-filter"></i>
                        )}{" "}
                        FILTER
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <p>
                    <strong>
                      Total records: {this.state.libraries.length}
                    </strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.libraries}
                    pageSize={25}
                    pagination={true}
                    myClass="-striped -highlight"
                    viewHandler={this.viewHandler}
                    filesHandler={this.filesHandler}
                    editHandler={this.editHandler}
                    emailHandler={this.emailHandler}
                    deleteHandler={this.deleteHandler}
                    resourceHandler={this.resourceHandler}
                    authenticationHandler={this.authenticationHandler}
                    resourceCategoryHandler={this.resourceCategoryHandler}
                    userHandler={this.userHandler}
                    groupHandler={this.groupHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LibrariesComponent;
