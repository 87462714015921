import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_BASE } from "../../../constant/api";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

class AuthComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      auths: [],
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getAuths = (page = null, limit = 1000) => {
    var url = new URL(
      `${API_BASE}libraryAuth/${this.props.match.params.libid}`
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            auths: res.data ? res.data : [],
          });
        }
      });
  };

  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };

  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getAuths();
    this.getLibrary(this.props.match.params.libid);
  }

  editHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/auth/edit/${user._id}`
    );
  };

  viewHandler = (user) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/auth/view/${user._id}`
    );
  };

  deleteHandler = (user) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteAuth(user._id)}
        >
          You will not be able to recover this auth!
        </SweetAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteAuth = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}libraryAuth/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getAuths();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
      ];
    } else {
      return [];
    }
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Authentication`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                    "Organization Admin") && (
                  <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                    Back to Libraries
                  </Link>
                )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Authentication`}
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title={`Authentication`}
          specialParent={this.getParentBreadcrumb()}
        />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Button
                style={{ float: "right", marginTop: -60 }}
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/auth/create`
                  )
                }
              >
                <i className="fa fa-plus"></i> ADD
              </Button>
              <div className="card">
                <div className="card-body datatable-react">
                <p>
                    <strong>
                      Total records: {this.state.auths.length}
                    </strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.auths}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AuthComponent;
