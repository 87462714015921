import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./datatable.scss";

export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
    };
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const {
      pageSize,
      myClass,
      multiSelectOption,
      pagination,
      viewHandler,
      filesHandler,
      editHandler,
      emailHandler,
      deleteHandler,
      resourceHandler,
      authenticationHandler,
      resourceCategoryHandler,
      userHandler,
      groupHandler,
      myData,
    } = this.props;

    const columnNames = ["UniversityName", "Name"];
    const columns = [];
    for (var key in myData[0]) {
      if (columnNames.includes(key)) {
        let header = this.Capitalize(key.toString());
        if (key == "UniversityName") header = "Library Name";
        if (key == "Name") header = "Librarian";

        columns[columnNames.indexOf(key)] = {
          Header: (
            <div style={{ textAlign: "left" }}>
              <b>{header}</b>
            </div>
          ),
          accessor: key,
          Cell: null,
          style: {
            textAlign: "left",
          },
        };
      }
    }

    columns.push({
      Header: <b>Action</b>,
      id: "delete",
      accessor: (str) => "delete",
      Cell: (row) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => viewHandler(row.original)}
            title="View"
          >
            <i
              className="fa fa-eye"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#4466f2",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => groupHandler(row.original)}
            title="User Groups"
          >
            <i
              className="fa fa-users"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => userHandler(row.original)}
            title="Users"
          >
            <i
              className="fa fa-user-circle-o"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#1ea6ec",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => resourceCategoryHandler(row.original)}
            title="Resource Category"
          >
            <i
              className="fa fa-sitemap"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#4466f2",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => resourceHandler(row.original)}
            title="Resources"
          >
            <i
              className="fa fa-book"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#007bff",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => authenticationHandler(row.original)}
            title="Authentication"
          >
            <i
              className="fa fa-lock"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#ff9f40",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => emailHandler(row.original)}
            title="Custom emails"
          >
            <i
              className="fa fa-envelope"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#1189C6",
              }}
            ></i>
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => editHandler(row.original)}
            title="Edit"
          >
            <i
              className="fa fa-pencil"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "rgb(40, 167, 69)",
              }}
            ></i>
          </span>
          <span
            onClick={() => deleteHandler(row.original)}
            style={{ cursor: "pointer" }}
            title="Delete"
          >
            <i
              className="fa fa-trash"
              style={{
                width: 30,
                fontSize: 16,
                padding: 11,
                color: "#e4566e",
              }}
            ></i>
          </span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: false,
    });

    return (
      <Fragment>
        <ReactTable
          data={myData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

export default Datatable;
