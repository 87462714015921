import React, { Fragment, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { Button } from "reactstrap";
import "./users.scss";
var moment = require("moment");

class ViewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      errMsg: null,
      loggedInUser: null,
    };
  }

  getUser = (id) => {
    var url = new URL(`${API_BASE}users/${id}`);
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({
            loading: false,
            user: res.data,
          });
        } else {
          this.setState({ loading: false, errMsg: res.msg });
        }
      })
      .catch((err) => {
        toast.error(
          "No response from server! Check your internet connection and Try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  componentDidMount() {
    this.getUser(this.props.match.params.id);
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
  }

  getBreadcrumbTitle = () => {
    if (this.state.loggedInUser) {
      switch (this.state.loggedInUser.Role.Name) {
        case "Organization Admin":
          return "Library Admin";

        default:
          return "User";
      }
    }
    return "";
  };

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title={`View ${this.getBreadcrumbTitle()}`}
            parentLink={`${process.env.PUBLIC_URL}/users/${this.props.location.search}`}
            parent={`${this.getBreadcrumbTitle()}s`}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    if (this.state.errMsg) {
      return (
        <Fragment>
          <Breadcrumb
            title={`View ${this.getBreadcrumbTitle()}`}
            parentLink={`${process.env.PUBLIC_URL}/users/${this.props.location.search}`}
            parent={`${this.getBreadcrumbTitle()}s`}
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>{this.state.errMsg}</p>
              <hr />
              <p className="mb-0">
                <Link to={`${process.env.PUBLIC_URL}/users`}>
                  Back to Users
                </Link>
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Breadcrumb
          title={`View ${this.getBreadcrumbTitle()}`}
          parentLink={`${process.env.PUBLIC_URL}/users/${this.props.location.search}`}
          parent={`${this.getBreadcrumbTitle()}s`}
        />
        <Button
          style={{ float: "right", marginTop: -60, marginRight: 16 }}
          color="primary"
          onClick={() =>
            this.props.history.push(
              `${process.env.PUBLIC_URL}/user/edit/${this.props.match.params.id}`
            )
          }
        >
          <i className="fa fa-pencil"></i> EDIT
        </Button>
        <div className="container-fluid">
          <div className="card p-4">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-box blog-details">
                    <div className="blog-details">
                      <h4>{this.state.user.DisplayName}</h4>
                    </div>
                  </div>
                  <section className="comment-box" style={{ paddingTop: 20 }}>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>#ID</h5>
                      </div>
                      <div className="col-md-4">
                        <p><code>{this.state.user._id}</code></p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>User Name</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.user.UserName}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Email</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.user.Email}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-2">
                        <h5>Role</h5>
                      </div>
                      <div className="col-md-4">
                        <p>{this.state.user.Role.Name}</p>
                      </div>
                    </div>
                    <hr />
                    {this.state.user.Role.Name == "Organization Admin" && (
                      <Fragment>
                        <div className="row">
                          <div className="col-md-2">
                            <h5>Organization</h5>
                          </div>
                          <div className="col-md-4">
                            <p>
                              {this.state.user.Organization
                                ? this.state.user.Organization.Name
                                  ? this.state.user.Organization.Name
                                  : ""
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    )}

                    {this.state.user.Role.Name == "Library Admin" && (
                      <Fragment>
                        <div className="row">
                          <div className="col-md-2">
                            <h5>Library</h5>
                          </div>
                          <div className="col-md-4">
                            <p>
                              {this.state.user.Library
                                ? this.state.user.Library.UniversityName
                                  ? this.state.user.Library.UniversityName
                                  : ""
                                : ""}
                              {this.state.user.Library
                                ? this.state.user.Library.Organization
                                  ? this.state.user.Library.Organization.Name
                                    ? `(${this.state.user.Library.Organization.Name})`
                                    : ""
                                  : ""
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    )}

                    <div className="blog-box blog-details">
                      <div className="blog-details">
                        <ul className="blog-social">
                          {this.state.user.CreatedOn && (
                            <Fragment>
                              <li
                                className="digits"
                                style={{
                                  border: "none",
                                  color: "#007bff",
                                  paddingRight: 0,
                                  cursor: "pointer",
                                }}
                                id="createdOn"
                              >
                                <i className="fa fa-clock-o"></i>
                                {moment(this.state.user.CreatedOn).format(
                                  "D MMM YYYY"
                                )}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="createdOn"
                              >
                                Created On
                              </UncontrolledTooltip>
                            </Fragment>
                          )}

                          {this.state.user.CreatedBy && (
                            <Fragment>
                              <li
                                id="createdBy"
                                className="digits"
                                style={{
                                  marginRight: 40,
                                  color: "#007bff",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="icofont icofont-user"></i>
                                {this.state.user.CreatedBy.UserName}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="createdBy"
                              >
                                Created By
                              </UncontrolledTooltip>
                            </Fragment>
                          )}

                          {this.state.user.UpdatedOn && (
                            <Fragment>
                              <li
                                id="updatedOn"
                                className="digits"
                                style={{
                                  borderLeft: "1px solid #777777",
                                  color: "#ff9f40",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-clock-o"></i>
                                {moment(this.state.user.UpdatedOn).format(
                                  "D MMM YYYY"
                                )}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedOn"
                              >
                                Updated On
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                          {this.state.user.UpdatedBy && (
                            <Fragment>
                              <li
                                id="updatedBy"
                                className="digits"
                                style={{ color: "#ff9f40", cursor: "pointer" }}
                              >
                                <i className="icofont icofont-user"></i>
                                {this.state.user.UpdatedBy.UserName}
                              </li>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="updatedBy"
                              >
                                Updated By
                              </UncontrolledTooltip>
                            </Fragment>
                          )}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <section className="comment-box">
            <Link className="mb-5" to={`${process.env.PUBLIC_URL}/users/${this.props.location.search}`}>
              <i className="fa fa-arrow-circle-left"></i> Back to {`${this.getBreadcrumbTitle()}s`}
            </Link>
          </section>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  dropdown: {
    float: "right",
  },
  pagination: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ViewUser;
