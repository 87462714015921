import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { FILE_BASE } from "../../constant/api";

const LibraryFiles = ({ match }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const [library, setLibrary] = useState([]);
  const [UserManual, setUserManual] = useState(null);
  const [MainLogo, setMainLogo] = useState(null);
  const [FooterLogo, setFooterLogo] = useState(null);
  const [Favicon, setFavicon] = useState(null);
  const [Banner, setBanner] = useState(null);

  const getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    setLoading(true);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setLibrary(res.data);
        } else {
          setLoading(false);
          setErrMsg(res.msg);
        }
      });
  };

  useEffect(() => {
    getLibrary(match.params.id);
    return () => {};
  }, []);

  const onSubmit = (data) => {
    if (data !== "") {
      var formData = new FormData();
      if (UserManual)
        formData.append("UserManual", UserManual, UserManual.name);
      if (MainLogo) formData.append("MainLogo", MainLogo, MainLogo.name);
      if (FooterLogo)
        formData.append("FooterLogo", FooterLogo, FooterLogo.name);
      if (Favicon) formData.append("Favicon", Favicon, Favicon.name);
      if (Banner) formData.append("Banner", Banner, Banner.name);
      setUploading(true);
      fetch(`${API_BASE}libraries/upload-files/${match.params.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          setUploading(false);
          if (!res.success) {
            setUploading(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            getLibrary(match.params.id);
          }
        })
        .catch((err) => {
          setUploading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  if (loading) {
    return (
      <Fragment>
        <Breadcrumb parent="Libraries" title="Edit Library" />
        <div className="container-fluid">
          <div
            className="loader-box"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <div className="loader">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (errMsg) {
    return (
      <Fragment>
        <Breadcrumb parent="Libraries" title="Edit Library" />
        <div className="container-fluid">
          <Alert color="danger">
            <h4 className="alert-heading">Oops! Something went wrong.</h4>
            <p>{errMsg}</p>
            <hr />
            <p className="mb-0">
              <Link to="/libraries">Back to Libraries</Link>
            </p>
          </Alert>
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Breadcrumb parent="Libraries" title="Edit Library" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                  encType="multipart/form-data"
                >
                  {match.params.isUserManual == "true" && (
                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <h5 style={styles.header}>User Manual</h5>
                        <div className="row">
                          <div className="col-md-8">
                            {library.UserManual ? (
                              <div>
                                <iframe
                                  src={`${FILE_BASE}${library.UserManual}`}
                                ></iframe>
                                <a
                                  href={`${FILE_BASE}${library.UserManual}`}
                                  target="_blank"
                                >
                                  <code>Open in new tab</code>
                                </a>
                              </div>
                            ) : (
                              <code>User Manual not uploded</code>
                            )}
                          </div>
                          <div className="col-md-4">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                type="file"
                                ref={register({})}
                                name="UserManual"
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) =>
                                  setUserManual(e.target.files[0])
                                }
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="validatedCustomFile"
                              >
                                Choose file...
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {match.params.isUserManual == "false" && (
                    <Fragment>
                      <div className="form-row mb-5">
                        <div className="col-md-12 mb-3">
                          <h5 style={styles.header}>Main Logo</h5>
                          <div className="row">
                            <div className="col-md-8">
                              {library.MainLogo ? (
                                <div>
                                  <img
                                    src={`${FILE_BASE}${library.MainLogo}`}
                                  />
                                  <a
                                    href={`${FILE_BASE}${library.MainLogo}`}
                                    target="_blank"
                                  >
                                    <code>Open in new tab</code>
                                  </a>
                                </div>
                              ) : (
                                <code>Main Logo not uploded</code>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  type="file"
                                  ref={register({})}
                                  name="MainLogo"
                                  accept="image/jpeg,image/png"
                                  onChange={(e) =>
                                    setMainLogo(e.target.files[0])
                                  }
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="validatedCustomFile"
                                >
                                  Choose file...
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mb-5">
                        <div className="col-md-12 mb-3">
                          <h5 style={styles.header}>Footer Logo</h5>
                          <div className="row">
                            <div className="col-md-8">
                              {library.FooterLogo ? (
                                <div>
                                  <img
                                    src={`${FILE_BASE}${library.FooterLogo}`}
                                  />
                                  <a
                                    href={`${FILE_BASE}${library.FooterLogo}`}
                                    target="_blank"
                                  >
                                    <code>Open in new tab</code>
                                  </a>
                                </div>
                              ) : (
                                <code>Footer Logo not uploded</code>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  type="file"
                                  ref={register({})}
                                  name="FooterLogo"
                                  accept="image/jpeg,image/png"
                                  onChange={(e) =>
                                    setFooterLogo(e.target.files[0])
                                  }
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="validatedCustomFile"
                                >
                                  Choose file...
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mb-5">
                        <div className="col-md-12 mb-3">
                          <h5 style={styles.header}>Favicon</h5>
                          <div className="row">
                            <div className="col-md-8">
                              {library.Favicon ? (
                                <div>
                                  <img src={`${FILE_BASE}${library.Favicon}`} />
                                  <a
                                    href={`${FILE_BASE}${library.Favicon}`}
                                    target="_blank"
                                  >
                                    <code>Open in new tab</code>
                                  </a>
                                </div>
                              ) : (
                                <code>Favicon not uploded</code>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  type="file"
                                  ref={register({})}
                                  name="UserManual"
                                  accept="image/jpeg,image/png,image/x-icon,image/vnd.microsoft.icon"
                                  onChange={(e) =>
                                    setFavicon(e.target.files[0])
                                  }
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="validatedCustomFile"
                                >
                                  Choose file...
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row mb-5">
                        <div className="col-md-12 mb-3">
                          <h5 style={styles.header}>Banner</h5>
                          <div className="row">
                            <div className="col-md-8">
                              {library.Banner ? (
                                library.Banner.length > 0 ? (
                                  <div className="row">
                                    {library.Banner.map((v, i) => {
                                      return (
                                        <div className="col-md-6" key={i}>
                                          <img
                                            src={`${FILE_BASE}${v}`}
                                            style={{ width: "100%" }}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <code>Banner not uploded</code>
                                )
                              ) : (
                                <code>Banner not uploded</code>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="custom-file">
                                <input
                                  className="custom-file-input"
                                  type="file"
                                  ref={register({})}
                                  name="Banner"
                                  accept="image/jpeg,image/png"
                                  onChange={(e) => setBanner(e.target.files[0])}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="validatedCustomFile"
                                >
                                  Choose file...
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}

                  {error && (
                    <div class="dark alert alert-danger fade show" role="alert">
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={uploading}
                    type="submit"
                  >
                    {uploading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </form>
                <section className="comment-box">
                  <Link className="mb-5" to="/libraries">
                    <i className="fa fa-arrow-circle-left"></i> Back to
                    Libraries
                  </Link>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  header: {
    fontSize: 18,
    marginBottom: 15,
    textTransform: "uppercase",
    fontWeight: 600,
    color: "#4466f2",
  },
};

export default LibraryFiles;
