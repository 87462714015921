import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Datatable from "./datatable";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { API_BASE } from "../../../constant/api";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

class ResourceCategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      library: {
        UniversityName: "Fetching...",
      },
      loggedInUser: {
        Role: {
          IsAdmin: false,
          Name: "",
        },
      },
      resourceCategories: [],
      sweetalert: {
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
      },
    };
  }

  getResourceCategories = () => {
    var url = new URL(
      `${API_BASE}resourceCategories?library_id=${this.props.match.params.libid}`
    );
    this.setState({ loading: true });
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          this.setState({
            loading: false,
            resourceCategories: res.data,
          });
        }
      })
      .catch((err) => {
        toast.error("No response from server! Try again.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  getLibrary = (id) => {
    var url = new URL(`${API_BASE}libraries/${id}`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          this.setState({ library: res.data });
        } else {
          this.setState({ library: 404 });
        }
      });
  };
  componentDidMount() {
    this.setState({ loggedInUser: JSON.parse(localStorage.getItem("user")) });
    this.getResourceCategories();
    this.getLibrary(this.props.match.params.libid);
  }

  editHandler = (resourceCategory) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource-category/edit/${resourceCategory._id}`
    );
  };

  viewHandler = (resourceCategory) => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource-category/view/${resourceCategory._id}`
    );
  };

  deleteHandler = (resourceCategory) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Continue"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onCancel={this.hideAlert}
          onConfirm={() => this.deleteResourceCategory(resourceCategory._id)}
        >
          You will not be able to recover this Resource Category!
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  deleteResourceCategory = (id) => {
    this.setState({
      alert: null,
    });
    var url = new URL(`${API_BASE}resourceCategories/${id}`);
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getResourceCategories();
        } else {
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  getParentBreadcrumb = () => {
    if (
      this.state.loggedInUser.Role.Name == "Master Admin" ||
      this.state.loggedInUser.Role.Name == "Organization Admin"
    ) {
      return [
        {
          label: this.state.library.UniversityName,
          link: `${process.env.PUBLIC_URL}/library/view/${this.props.match.params.libid}`,
        },
      ];
    } else {
      return [];
    }
  };

  render() {
    if (this.state.library == 404) {
      return (
        <Fragment>
          <Breadcrumb
            title={`Resource Category`}
            parentLink={`${process.env.PUBLIC_URL}/libraries`}
            parent={
              this.state.loggedInUser.Role.Name == "Master Admin" ||
              this.state.loggedInUser.Role.Name == "Organization Admin"
                ? "Libraries"
                : "false"
            }
          />
          <div className="container-fluid">
            <Alert color="danger">
              <h4 className="alert-heading">Oops! Something went wrong.</h4>
              <p>Library not found!</p>
              <hr />
              <p className="mb-0">
                {(this.state.loggedInUser.Role.Name == "Master Admin" ||
                  this.state.loggedInUser.Role.Name ==
                    "Organization Admin") && (
                  <Link to={`${process.env.PUBLIC_URL}/libraries`}>
                    Back to Libraries
                  </Link>
                )}
              </p>
            </Alert>
          </div>
        </Fragment>
      );
    }
    if (this.state.loading) {
      return (
        <Fragment>
          <Breadcrumb
            title="Resource Category"
            specialParent={this.getParentBreadcrumb()}
          />
          <div className="container-fluid">
            <div
              className="loader-box"
              style={{ flex: 1, justifyContent: "center" }}
            >
              <div className="loader">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Breadcrumb
          title="Resource Category"
          specialParent={this.getParentBreadcrumb()}
        />
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Button
                style={{ float: "right", marginTop: -60 }}
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/library/${this.props.match.params.libid}/resource-category/create`
                  )
                }
              >
                <i className="fa fa-plus"></i> ADD
              </Button>
              <div className="card">
                <div className="card-body datatable-react">
                  <p>
                    <strong>
                      Total records: {this.state.resourceCategories.length}
                    </strong>
                  </p>
                  <Datatable
                    multiSelectOption={false}
                    myData={this.state.resourceCategories}
                    pageSize={25}
                    pagination={true}
                    class="-striped -highlight"
                    viewHandler={this.viewHandler}
                    editHandler={this.editHandler}
                    deleteHandler={this.deleteHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ResourceCategoryComponent;
