import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { API_BASE } from "../../constant/api";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import CKEditors from "react-ckeditor-component";

const AddLibrary = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disclaimer, setDisclaimer] = useState(null);
  const history = useHistory();
  const [UserManual, setUserManual] = useState(null);
  const [MainLogo, setMainLogo] = useState(null);
  const [FooterLogo, setFooterLogo] = useState(null);
  const [Favicon, setFavicon] = useState(null);
  const [Banner, setBanner] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);


  const getOrganizations = () => {
    var url = new URL(`${API_BASE}organizations`);
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          var data = res.data.map((v) => {
            return {
              value: v._id,
              label: v.Name,
            };
          });
          setOrganizations(data);
        } else {
        }
      });
  };
  useEffect(() => {
    if(JSON.parse(localStorage.getItem('user')).Role && JSON.parse(localStorage.getItem('user')).Role.Name == "Organization Admin"){
      setLoggedInUser(JSON.parse(localStorage.getItem('user')))
      setOrganizations([{
        value: JSON.parse(localStorage.getItem('user')).Organization._id,
        label: JSON.parse(localStorage.getItem('user')).Organization.Name
      }])
    }else {
      getOrganizations();
    }
    return () => {};
  }, []);
  const onSubmit = (data) => {
    if (data !== "") {
      data = { ...data, Disclaimer: disclaimer };
      setLoading(true);
      var formData = new FormData();
      for (var d in data) {
        formData.append(d, data[d]);
      }
      // if (UserManual)
      //   formData.append("UserManual", UserManual, UserManual.name);
      // if (MainLogo) formData.append("MainLogo", MainLogo, MainLogo.name);
      // if (FooterLogo)
      //   formData.append("FooterLogo", FooterLogo, FooterLogo.name);
      // if (Favicon) formData.append("Favicon", Favicon, Favicon.name);
      // if (Banner) formData.append("Banner", Banner, Banner.name);
      fetch(`${API_BASE}libraries`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (!res.success) {
            setLoading(false);
            toast.error(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
            });
            history.push(`${process.env.PUBLIC_URL}/libraries`);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("No response from server! Try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  const disclaimerChange = (evt) => {
    var newContent = evt.editor.getData();
    setDisclaimer(newContent);
  };

  return (
    <div>
      <Breadcrumb
        parent="Library"
        parentLink={`${process.env.PUBLIC_URL}/libraries`}
        title="Add Library"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Library Name</label>
                      <input
                        className="form-control"
                        name="UniversityName"
                        type="text"
                        placeholder="Library Name"
                        ref={register({ required: true })}
                      />
                      <span>
                        {errors.UniversityName && "University Name is required"}
                      </span>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Library URL</label>
                      <input
                        className="form-control"
                        name="RootURL"
                        type="text"
                        placeholder="Library URL"
                        ref={register({ required: false })}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Organization</label>
                      {organizations && (
                        <select
                          className="form-control"
                          name="Organization"
                          ref={register({ required: true })}
                          defaultValue={loggedInUser? loggedInUser.Organization._id : ""}
                          readOnly={loggedInUser? loggedInUser.Organization ? true : false : false}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {organizations.map((v, k) => (
                            <option key={k} value={v.value}>
                              {v.label}
                            </option>
                          ))}
                        </select>
                      )}
                      <span style={{color: 'red'}}>
                        {errors.Organization && "Organization is required"}
                      </span>
                    </div>
                  </div>
                  {/* <div className="form-row mb-5">
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-4">
                          <div>
                            <label htmlFor="validationCustom01">
                              User Manual
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              ref={register({})}
                              name="UserManual"
                              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onChange={(e) => setUserManual(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Disclaimer</label>
                      <CKEditors
                        activeclassName="p10"
                        content={disclaimer}
                        events={{
                          change: disclaimerChange.bind(this),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <h6 className="font-weight-bold">Contact Information</h6>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Contact Name</label>
                      <input
                        className="form-control"
                        name="Name"
                        type="text"
                        placeholder="Name"
                        ref={register({})}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Email</label>
                      <input
                        className="form-control"
                        name="Email"
                        type="email"
                        placeholder="Email"
                        ref={register({})}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Phone</label>
                      <input
                        className="form-control"
                        name="Phone"
                        type="text"
                        placeholder="Phone"
                        ref={register({})}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Address</label>
                      <textarea
                        className="form-control"
                        name="Address"
                        placeholder="Address"
                        ref={register({})}
                      ></textarea>
                    </div>
                  </div> */}

                  {/* <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <h6 className="font-weight-bold">Layout Option</h6>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Color</label>
                      <input
                        className="form-control"
                        name="Color"
                        type="text"
                        placeholder="Color"
                        ref={register({})}
                      />
                    </div>
                  </div> */}
                  {/* <div className="form-row mb-5">
                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <div className="col-md-4">
                          <div>
                            <label htmlFor="validationCustom01">Banner</label>
                            <input
                              className="form-control"
                              type="file"
                              ref={register({})}
                              name="Banner"
                              accept="image/jpeg,image/png"
                              onChange={(e) => setBanner(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="container">
                    <div className="form-row mb-5">
                      <div className="col-md-12">
                        <h6 style={styles.header}>Logos</h6>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <label htmlFor="validationCustom01">
                                Main Logo
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                ref={register({})}
                                name="MainLogo"
                                accept="image/jpeg,image/png"
                                onChange={(e) => setMainLogo(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <label htmlFor="validationCustom01">
                                Footer Logo
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                ref={register({})}
                                name="FooterLogo"
                                accept="image/jpeg,image/png"
                                onChange={(e) =>
                                  setFooterLogo(e.target.files[0])
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mb-5">
                      <div className="col-md-12 mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div>
                              <label htmlFor="validationCustom01">
                                Favicon
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                ref={register({})}
                                name="UserManual"
                                accept="image/jpeg,image/png,image/x-icon,image/vnd.microsoft.icon"
                                onChange={(e) => setFavicon(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {error && (
                    <div
                      className="dark alert alert-danger fade show"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <button
                    className="btn btn-primary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? (
                      <i
                        style={{ fontSize: 18 }}
                        className="fa fa-cog fa-spin"
                      ></i>
                    ) : (
                      "Create"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  header: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: 600,
  },
};

export default AddLibrary;
