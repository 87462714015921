import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

class Datepicker extends Component {
  render() {
    return (
      <DateRangePicker
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        maxDate={this.props.maxDate}
        minDate={this.props.minDate}
        onEvent={this.props.onEvent}
        // showDropdowns
      >
        <button className="btn btn-outline-primary btn-block" type="button">
          {this.props.children}
        </button>
      </DateRangePicker>
    );
  }
}

export default Datepicker;
